/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import {
	getSectionHeader,
	getHaveQuestions,
	getSectionMemo,
	getErrorDetail,
} from '../../redux/selector/questions';
import {
	getMockQuestionSet,
} from '../../redux/actions';
import SectionHeader from '../../cruxComponents/SectionHeader';
import FooterNotes from '../Footer/FooterNotes';
import ActionButtons from './ActionButtons';
import Questions from './Questions';
import ReviewQuestions from './ReviewQuestions';
import FooterBanner from '../UIElements/FooterBanner';
import HaveQuestions from '../HaveQuestions';
import { ListContext } from '../List/ListContext';

function QuestionLibrary(props) {
	const {
		sectionData,
		sectionTitle,
		sectionTitleHidden,
		sectionSubTitle,
		screenViewport,
		pageId,
		errors,
		layout,
		processing,
		reviseButtonLabel,
		sectionIndex,
		footerBanner,
		primaryRoute,
		theme,
		haveQuestions,
		pageTitle,
		lastSuccessResponse,
		setMockQuestionsSet,
		reviewActiveIds,
		groupQId,
		actionMessage,
	} = props;
	const _useState = useState([]);
	const setEditFlag = _useState[1]
	const [mode, setMode] = useState('');
	const { listState } = useContext(ListContext)

	/**
	 * - Completed Route - cardWidth/sectionHeaderWidth custom width config ids
	*/
	const pageTypes = ['FinalDecisionRefer', 'WelcomeBreadcrumbNY', 'BH_NYCustThankyouScreen'];
  	const regex = new RegExp(`(${pageTypes.join('|')})`, 'i');
	const isPageType = regex.test(groupQId);

  	useEffect(() => {
		const updateEditFlag = Array(sectionData.length);
		// Make sure the last section is expanded by default
		updateEditFlag[sectionData.length - 1] = true
		/* Collapsed Card Param for Lower Resolution Screens */
		setEditFlag(updateEditFlag);
		/* Edit Functionality is unset in case of Nested Lists on component reload */
		/* Uncommenting as previous state is persisted even on after a reflexive call
		   causing list in mode on the first addition */
		setMode('');
	}, [sectionData]);

	const collapseCard = (index)=>{
		// make sures current section does not collapse
		if(screenViewport === 'mobile' && index < sectionData.length-1) {
			const updateEditFlag = Array(sectionData.length);
		/* Collapsed Card Param for Lower Resolution Screens */
			setEditFlag(updateEditFlag)
		}
	}
    const hasErrorActionMessage = actionMessage && actionMessage.type === 'error';
	return (
		<>
		    <Helmet>
				<title>
					{pageTitle}
				</title>
			</Helmet>
			<SectionHeader
				pageId={ pageId }
				layout={ layout }
				name="section-header"
				title={ sectionTitle }
				isTitleHidden={ sectionTitleHidden }
				subTitle={ sectionSubTitle }
				reviseButtonLabel={ reviseButtonLabel }
				sectionData={ sectionData }
				sectionIndex={ sectionIndex }
				isPageType = { isPageType }
				headingId="active-section-header"
				subHeadingId="active-section-subtitle"
			/>
			{sectionData.map((section, index) => {
				if (pageId.indexOf('review') > -1) {
					return (
						<ReviewQuestions
							sectionData={ section }
							errors={ errors }
							index={ `${index}` }
							key={ section.id }
							sectionLength={ sectionData.length }
							processing={ false }
							reviewActiveIds={ reviewActiveIds }
							setReviewQuestionSet={ () => {
								if (!lastSuccessResponse.data) return;
								const _reviewActiveIds = [...reviewActiveIds];
								const questionId = section.questionList[0]?.question_id;
								const idx = _reviewActiveIds.indexOf (questionId);
								if ( idx === -1)
									_reviewActiveIds.push(questionId);
								else
									_reviewActiveIds.splice(idx, 1);
								setMockQuestionsSet(lastSuccessResponse, { isNetworkResponse : false, reviewActiveIds : _reviewActiveIds });
							} }
						/>
					)
				}
				return (
					<Questions
						key={ section.id }
						sectionData={ section }
						errors={ errors }
						index={ index }
						sectionLength={ sectionData.length }
						onContinue={ collapseCard }
						processing={ processing }
						changeMode={ (newMode) => setMode(newMode) }
						isPageType = { isPageType }
					/>
				);
			})}
			<ActionButtons { ...props } mode={ mode } onContinue = { collapseCard } listState={ listState } hasErrorActionMessage={hasErrorActionMessage} />
			{footerBanner && <FooterBanner footerBanner={ footerBanner } theme={ theme } primaryRoute={ primaryRoute } />}
			<FooterNotes layout={ layout } />
			{(layout!=='double' || screenViewport === 'mobile') && <HaveQuestions layout={ layout } haveQuestions={ haveQuestions } />}
		</>
	);
}

const mapStateToProps = (state, props) => {
	const sectionMem = getSectionMemo(state);
	const sectionData = sectionMem(props);
	const breadcrumbs = get(state, 'questions.breadcrumbs.breadcrumbList');
	const hasBreadcrumbs = breadcrumbs && breadcrumbs.filter(crumb => crumb.show).length > 0;
	const haveQuestions = getHaveQuestions(sectionData, hasBreadcrumbs);
	const { sectionTitle, sectionSubTitle, pageTitle, sectionTitleHidden } = getSectionHeader(
		sectionData,
	);
	let errors = getErrorDetail(
		sectionData,
	);

	const currentBreadcrumbTitle = get(state, 'questions.breadcrumbs.currentBreadcrumb.title', '')

	const { actionMessage } = state.questions;
	if (actionMessage && actionMessage.message) {
		errors = {
			hasError: true,
			errorMessage: actionMessage.message,
		}
		if (actionMessage.type === 'success') errors = { hasError: false }
	}
	const reviseButtonLabel = get(state,'questions.reviseButtonLabel',null);
	const footerBanner = get(state,'questions.footerBanner',null);
	const activeSection = sectionData && sectionData.length ? sectionData[0] : {};
	const primaryRoute = get(activeSection, 'otherParams.route');
	const lastSuccessResponse = get(state, 'questions.lastSuccessResponse');
	const reviewActiveIds = get(state, 'questions.reviewActiveIds', []);
	const groupQId = state.questions?.pageType;

	return {
		sectionData,
		sectionTitle,
		sectionTitleHidden,
		sectionSubTitle,
		haveQuestions,
		screenViewport: state.meta.screenViewport,
		errors,
		reviseButtonLabel,
		footerBanner,
		primaryRoute,
		pageTitle,
		currentBreadcrumbTitle,
		lastSuccessResponse,
		reviewActiveIds,
		groupQId,
		activeSubsectionId: state?.questions?.activeSubsectionId,
		actionMessage: state.questions.actionMessage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMockQuestionsSet  : (data, params) => dispatch ( getMockQuestionSet ( data, params )),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(QuestionLibrary),
);
