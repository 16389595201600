/* eslint-disable import/prefer-default-export */
import React from 'react';

export const RotatedArrows = ({ isLightTheme = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.9734 14.1188C19.0406 17.6484 15.825 20.25 12 20.25C9.60938 20.25 7.45781 19.2328 5.95312 17.6109L7.875 15.6891C8.11406 15.45 8.25 15.1219 8.25 14.7844C8.25 14.0766 7.67812 13.5047 6.97031 13.5047H2.25C1.8375 13.5047 1.5 13.8422 1.5 14.2547V18.9703C1.5 19.6781 2.07187 20.25 2.77969 20.25C3.11719 20.25 3.44531 20.1141 3.68437 19.875L4.88906 18.6703C6.67031 20.5641 9.19688 21.75 12 21.75C16.5656 21.75 20.4 18.6094 21.4594 14.3719C21.5719 13.9172 21.2156 13.5 20.7469 13.5C20.3813 13.5 20.0672 13.7625 19.9734 14.1188ZM18.0469 6.38906L16.125 8.31094C15.8859 8.55 15.75 8.87813 15.75 9.21563C15.75 9.92344 16.3219 10.4953 17.0297 10.4953H21.75C22.1625 10.4953 22.5 10.1578 22.5 9.74531V5.02969C22.5 4.32188 21.9281 3.75 21.2203 3.75C20.8828 3.75 20.5547 3.88594 20.3156 4.125L19.1109 5.32969C17.3297 3.43594 14.8031 2.25 12 2.25C7.43437 2.25 3.6 5.39062 2.54063 9.62812C2.42813 10.0828 2.78438 10.5 3.25313 10.5C3.61875 10.5 3.93281 10.2375 4.02656 9.88125C4.95938 6.35156 8.175 3.75 12 3.75C14.3906 3.75 16.5422 4.76719 18.0516 6.38906H18.0469ZM21 9H17.5594L21 5.55937V9ZM3 15H6.44062L3 18.4406V15Z"
        fill={ isLightTheme ? 'white' : 'black' }
      />
    </svg>
  );
};
