import get from 'lodash/get'
import { isArray } from 'lodash';

export const isListQuestion = (questionList, isReview) => {
  if (isReview) {
    return false;
  }

  const question1 = questionList[1]; // 1st index will have display type

  if (question1 && question1.display_type === 'list' && question1.submission_type !== 'all') {
    return true;
  }
  return false;
}

export const getListDisplayMode = (questionList) => {
  const listQuestion = questionList.filter(question => question.display_type === 'list')[0];
  if (listQuestion) {
    return listQuestion.child_questions_completed_flag;
  }
  return false;
}

export const hasListItems = (questionList) => {
  const listQuestion = questionList[1];
  // check is list is array or not
  const list = get(listQuestion,'questions',[])
  if(isArray(list) && list.length) {
    return true;
  }
  return false
}

export const getAddButtonText = (questionList) => {
  const listQuestion = questionList.filter(question => question.display_type === 'list')[0];
  if (listQuestion) {
    return listQuestion.add_button_text
  }
  return 'Add';
}

export const getQuestionStatus = (questionList) => {
  const listQuestions = questionList.filter(question => question.display_type === 'list');
  let listError = false;
  listQuestions.forEach((listQuestion) => {
    if (listError) return ;
    if(listQuestion.is_hidden) return;
  /* Ideally front end should rely on question_status (valid, or invalid)
     but question_status is not changed based on list input.
     @Nishith has reported bug NAT-1340 to backend team

     Added extra condition for List Add View (when child_questions_completed_flag = false)
     */
     if ('child_questions_completed_flag' in listQuestion) {
      listError = !!listQuestion.question_status_message || ( !listQuestion.is_hidden && !listQuestion.child_questions_completed_flag )
    }
    listError = listError || !!listQuestion.question_status_message || ( !listQuestion)
  })
  return listError;
}

export const isListHidden = (questionList) => {
  const listQ = questionList.filter(question => question.display_type === 'list')[0];
  return listQ?.is_hidden
}

export function listDependantQHelper(questionObj, curBreadcrumbSections) {
  let shouldDisplayQ = true;
  
  const dependantQArray = get(questionObj, 'validations.dependant_question_answer_array', false);

  if(dependantQArray?.length){
    dependantQArray.forEach((eachDepQ) => {
      for(let i = 0; i < curBreadcrumbSections.length ; i += 1){
        const eachSectionAnswersAry = curBreadcrumbSections[i].answersArray
        const matchedAnsweredQ = eachSectionAnswersAry.filter((eachAns) => eachDepQ.question_id === eachAns.question_id)[0];
        if(matchedAnsweredQ){
          if(matchedAnsweredQ.answer !== eachDepQ.answer) {
            shouldDisplayQ = false
            break;
          }
        }  
      }
    })
  }

  return shouldDisplayQ
}

export const getListIndexRequiredSchema = (listItem, curBreadcrumbSections) => {

  const requiredListIds = listItem
  .filter((q) => (
      (((!q?.is_hidden && q?.question_type !== 'group') && get(q, 'validations.required.value', false)) || 
      q?.validations?.relative) && 
      listDependantQHelper(q, curBreadcrumbSections)),
    )
  .map((q) => q.question_id);
  return requiredListIds
}



