import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import at from 'lodash/at';
import get from 'lodash/get';
import { validateInnerHtml } from '../../utils';
import { ButtonBlue } from '../../Components/UIElements';
import { getNextQuestionSet } from '../../redux/actions';
import HaveQuestions from '../../Components/HaveQuestions';
import './landingPage.css';
import { getValidators, getCSSVariables } from '../../cruxComponents/utils';

const ItemContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TableHeading = styled.div`
  font-size: 24px;
  font: ${({ font }) => font?.[0]?.['leftPanelQuestions-heading'] || font?.[1]?.['leftPanelQuestions-heading']};
`;

const TableItem = styled.div`
	margin: 20px 0;
	font-size: 16px;
  padding:${({ text })=>text ? '0 10px' : '0' };
  font: ${({ font }) => font?.[0]?.['leftPanelQuestions-item'] || font?.[1]?.['leftPanelQuestions-item']};
`;

const SolidLine = styled.hr`
  ${({ showLine }) => !showLine && 'display: none;'}
	border-top: '1px solid rgba(216, 216, 216, 1)';
`;

const DashedLine = styled.div`
  ${({ showLine }) => !showLine && 'display: none;'}
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(179, 179, 179, 1) 40%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: top;
	background-size: 15px 1px;
	background-repeat: repeat-x;
`;

const LeftQuestionWrapper = styled.div`
 display: flex;
 flex-direction: column;
 ${({ position }) => position === 'top' && 'flex-direction: column-reverse;'}
`;

const TableWrapper = styled.div`
  margin-top: 330px;
  margin-bottom: 60px;
  width: ${({ width }) => width};
  margin-left: auto;
  margin-right: 30px;
  @media only screen and (max-width: 767px) {
    position: relative;
    top: ${( { top } ) => top?.[0]?.['leftPanelQuestions-tableWrapper'] || top?.[1]?.['leftPanelQuestions-tableWrapper']};
    margin: auto;
    background: white;
    padding: 30px 20px;
    width: 90%;
  }
`;

const QuestionItem = (props) => {
  const {
    question_id: questionId,
    question_text: questionText,
    response,
    display_type: displayType,
    is_edit_icon_visible: isEditable,
  } = get(props, 'question', {});
  const { makeAPICall, question, theme } = props;
  let res = response;
  const validators = getValidators(question)
  const { currency } = validators
  if( res && currency && currency.preserveFormattingInCb ) {
      res = new Intl.NumberFormat(currency.format).format(res)
  }

  // CSS Variables
  const cssVariables =  getCSSVariables(theme);
  const font = at(cssVariables, ['pageOverride.font', 'global.font']);
  const editClickHandler = () => {
    /** The formData passed in  should be fetched from backend
      will change the logic later */
    const { planPageId, uid } = props
    const editUrl = '/questions';
    const formData = {
      uid,
     q_id: planPageId,
    }
    const params = {
      sectionId:'quoteEdit',
    }
    makeAPICall(editUrl, formData, params);
  };

  const questionResponse = typeof response === 'object' ? response.label : response;

  return (
    <>
      {displayType === 'questions_group' ? (
        <ItemContentWrapper>
          <TableHeading font={ font }>{questionText}</TableHeading>
          {isEditable && (
            <ButtonBlue
              label="Edit"
              btnType="left-panel-link"
              btnClickHandler={ () => editClickHandler() }
            />
          )}
        </ItemContentWrapper>
      ) : (
        <ItemContentWrapper>
         {questionText && <TableItem
            font={ font }
            htmlFor={ questionId }
            dangerouslySetInnerHTML={ { __html: validateInnerHtml(questionText) } }
          />}
            <TableItem
              font={ font }
              text={ questionText }
              htmlFor={ questionId }
              dangerouslySetInnerHTML={ { __html: validateInnerHtml(questionResponse) } }
            />
        </ItemContentWrapper>
      )}
    </>
  );
};

const LeftPanelQuestions = (props) => {
  const { leftData, makeAPICall, pageSeqNumber, uid, planPageId, theme, screenViewport } = props;
  const {
		pageId,
		config: {
			theme: {
				pageOverrides,
			},
		},
  } = theme;
  let leftSectionStyles= pageOverrides[pageId] && pageOverrides[pageId].leftSectionStyles
  if(screenViewport === 'mobile'){
    leftSectionStyles = { ...leftSectionStyles, marginTop:0 }
  }

  // CSS Variables
  const cssVariables =  getCSSVariables(theme);
  const top = at(cssVariables, ['pageOverride.top', 'global.top']);
  const padding = at(cssVariables, ['pageOverride.padding', 'global.padding']);
  const tablePadding = padding?.[0]?.['leftPanelQuestions-tableDiv'] || padding?.[1]?.['leftPanelQuestions-tableDiv'];

  let width = '65%';
  let haveQuestions;
  if(leftData.length > 0) {
    width = get(leftData[0], 'properties.width', '65%');
    haveQuestions = get(leftData, '[0].have_questions');
  }
  return (
    <div>
      <TableWrapper id='left-wrapper' top={ top } width = { width }>
        <div style={ { background: '#ffffff', padding: tablePadding, ...leftSectionStyles } }>
          {leftData.map((question, index) => {
            const { is_hidden: isHidden, properties } = question;
            const position = get(properties, 'display_line.position', 'bottom');
            const lineType = get(properties, 'display_line.type', '');
            if(isHidden) {
              return null
            } return (
              <LeftQuestionWrapper position = { position }>
                <QuestionItem
                  uid={ uid }
                  pageSeqNumber={ pageSeqNumber }
                  question={ question }
                  index={ index }
                  makeAPICall={ makeAPICall }
                  theme={ theme }
                />
                <div>
                  <SolidLine showLine={ lineType === 'straight' }/>
                  <DashedLine showLine={ lineType === 'dotted' }/>
                </div>
              </LeftQuestionWrapper>
            );
          })}
        </div>
        { ( haveQuestions && screenViewport !== 'mobile') && (
          <HaveQuestions layout='double' haveQuestions={ haveQuestions } />
        ) }
      </TableWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { uid, meta } = state;
  const { screenViewport } = meta;
  const activeSubsectionId = get(state.questions, 'activeSubsectionId', '');
  const sectionIndex = get(state.questions, 'sectionIndex', '')
  const section = state.questions.sections[activeSubsectionId];
  const questionObj = get(section[sectionIndex], 'questionObj' )
	const planPageId = get(questionObj,'plan_page_id', '');
  const pageSeqNumber = get(
		state,
		'questions.currentPageSeqNumber',
		-1,
	);

	return {
		uid,
    planPageId,
		pageSeqNumber,
    screenViewport,
	};
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeAPICall: (url, formData, params) => {
      dispatch(
        getNextQuestionSet(url, formData, params),
      );
    },
  };
};

export default withTheme(connect(
	mapStateToProps,
	mapDispatchToProps,
)(LeftPanelQuestions));
