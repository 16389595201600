/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line no-unused-vars
const widthMap = {
  S: '100px',
  M: '260px',
  L: '360px',
  XL: '400px',
};

const Wrapper = styled.div`
  margin-bottom: ${({ removeMargin, marginCollapse }) => (removeMargin ? '0' : (marginCollapse ? '-35px' : '20px'))};
  ${({ displayType }) => displayType === 'radio' && `margin-bottom: 0px;`}
	max-width: 100%;
	${( { themeConfiguration }) => themeConfiguration && `
	color: ${themeConfiguration.color};
	`}
	${({ optionsLength, displayType }) => displayType === 'radio_button' && optionsLength <= 2 &&
		`
			// div.radio-group-wrapper{
			// 	flex-wrap: nowrap;
			// }

			div.radio-group-wrapper > label{
				${
					`
						& > span:first-of-type {
							display: flex;
							flex: none;
							justify-content: center;
						}
					`
				}
			}

			@media (max-width: 390px) {
				div.radio-group-wrapper{
					flex-wrap: wrap;
				}
			}
		`
	}
	${({ optionsLength, displayType }) => displayType === 'radio' && optionsLength > 2 &&
	    `
		    div.radio-group-wrapper{
			    flex-direction: column;
		    }
	    `
    }

	${({ isTextOverflow }) => isTextOverflow && `
		> div + div {
			> * + *:not(:last-child) {
				 margin-top: 2.7rem;
				 margin-bottom: 0;
			}
		}
	`}
`;

const SelectWrapper = ({ width, themeConfiguration, children, optionsLength, displayType, marginCollapse, isTextOverflow }) => {
	return <Wrapper name='select-wrapper' width={ width } themeConfiguration={ themeConfiguration } optionsLength={ optionsLength } displayType= { displayType } marginCollapse={ marginCollapse } isTextOverflow={ isTextOverflow }>{children}</Wrapper>;
};

export const TooltipWrapper = styled.div`
	display: ${({ hideTooltipWrapper }) => hideTooltipWrapper ? 'none' : 'flex'};
	align-items: center;
	${({ hideTooltipWrapper }) => !hideTooltipWrapper && `
		& div {
			margin-right: 10px;
		}
	` }
`;

export default SelectWrapper;
