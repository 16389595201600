import {
	APP_CONFIG, SET_UID, SET_CJEVENT_ID,
} from '../types';

const appConfig = (config) => ({
	type: APP_CONFIG,
	payload: config,
})

const setUid = (uid) => ({
	type: SET_UID,
	uid,
});

const setCJEventId = (cjEventId) => ({
	type: SET_CJEVENT_ID,
	payload: cjEventId,
});

export { appConfig, setUid, setCJEventId }




