/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import styled, { withTheme } from 'styled-components';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ReadingIndicatorPlugin from './ReadingIndicatorPlugin';

const ModalBodyWrapper = styled.div`
    top: 8vw + 64px;
    left:0;
    right:0;
    width: 50%;
    height: 100%;
    position: fixed;
    margin-left:auto;
    margin-right:auto;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    z-index: 9999;
`

const ModalBodyContainer = styled.div`
    top: 0;
    left:0;
    right:0;
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left:auto;
    margin-right:auto;
    background-color: rgb(48,49,49);
    z-index: 9998;
`

const PDFModalToolBar = styled.div`
    color: #fff;
    height: 64px;
    padding: 1rem;
    display: flex;
    z-index: 10000;
    position: relative;
    /* flex-direction: column; */
    /* align-items: center; */
    background-color: rgb(33,33,33);
    > span > img {
        cursor: pointer;
    }
    > img, span > img {
        width: 25px;
        height: 25px;
    }
    > * + * {
        margin: 3px 11px;
    }
`


const PDFModal = ({ fileUrl, showPDFModal, setShowPDFModal }) => {
    const readingIndicatorPluginInstance = ReadingIndicatorPlugin();
    const { ReadingIndicator } = readingIndicatorPluginInstance;
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    // const tempCE = document.querySelector('div.rpv-toolbar__right')

    useEffect(() => {
        if(document.getElementById('ModalBodyContainer')) document.getElementById('ModalBodyContainer').focus()
    })

    const transform = (slot) => ({
        ...slot,
        Download : () => <></>,
        DownloadMenuItem : () => <></>,
        GoToFirstPageMenuItem : () => <></>,
        GoToLastPageMenuItem : () => <></>,
        GoToNextPageMenuItem : () => <></>,
        GoToPreviousPageMenuItem : () => <></>,
        Open : () => <></>,
        OpenMenuItem : () => <></>,
        Print : () => <></>,
        PrintMenuItem : () => <></>,
        RotateBackwardMenuItem : () => <></>,
        RotateForwardMenuItem : () => <></>,
        ShowPropertiesMenuItem : () => <></>,
        SwitchScrollModeMenuItem : () => <></>,
        SwitchSelectionModeMenuItem : () => <></>,
        SwitchThemeMenuItem : () => <></>,
        ZoomInMenuItem : () => <></>,
        ZoomOutMenuItem : () => <></>,
        MoreActionsPopover : () => <></>,
    });

    const renderToolbar = React.useCallback(
        (Toolbar = () => React.ReactElement) => (
            <>
                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                <div
                    style={ {
                        bottom: '-0.25rem',
                        position: 'absolute',
                        top: '35px',
                        left: 0,
                        // Take the full width of toolbar
                        width: '100%',
                    } }
                >
                    <ReadingIndicator />
                </div>
            </>
        ),
        [],
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar,
    });

    const modalBody = () => (
        <ModalBodyContainer id='ModalBodyContainer' tabIndex={ 0 } onKeyDown={ (e) => { if(e.key === 'Escape') setShowPDFModal(!showPDFModal) } }>
            <PDFModalToolBar>
                <img src="https://drive-thirdparty.googleusercontent.com/32/type/application/pdf" alt="pddfimage" />
                <div style={ { marginRight: 'auto' } }>Illustration PDF</div>
                <span onClick={ () => setShowPDFModal(!showPDFModal) }><img src="/images/svg/xmark.svg" alt="xmark" /></span>
            </PDFModalToolBar>
            <ModalBodyWrapper id='modalbody'>
                <div
                    style={ {
                        flexGrow: 1,
                        overflow: 'auto',
                    } }
                >
                    <Worker workerUrl={ pdfjsWorker }>
                        <Viewer
                            fileUrl={ fileUrl }
                            plugins={ [defaultLayoutPluginInstance, readingIndicatorPluginInstance] }
                            httpHeaders={ {
                                'AllowedHeaders': ['*'],
                                'AllowedMethods': ['*'],
                                'AllowedOrigins': ['*'],
                            } }
                        />
                    </Worker>
                </div>
            </ModalBodyWrapper>
        </ModalBodyContainer>
    );

    return (
        <>
            {showPDFModal && ReactDOM.createPortal(modalBody(), document.body)}
        </>
    );
};

const mapStateToProps = (state, props) => {
    const { questions } = state;
    const { activeSubsectionId, sections } = questions;
    let currentSection = [];
    if (sections) {
        const { sectionIndex } = props;
        if (activeSubsectionId && sectionIndex!==undefined) {
          currentSection = sections[activeSubsectionId][sections.length - 1];
        }
    }
    return {
        currentSection,
    }
  }

export default withTheme(connect(mapStateToProps, null)(PDFModal));