/* eslint-disable import/no-cycle */
import React from 'react';
import get from 'lodash/get';
import at  from 'lodash/at';
import styled, { withTheme } from 'styled-components';
import { Button, Tooltip } from 'crux';
import QuestionRenderer from '../Components/QuestionRenderer';
import HELPER from '../redux/reducers/helper';
import { getCSSVariables } from '../cruxComponents/utils';

const ButtonContainer = styled.div`
  margin-top: -10px;
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const updateParentIndex = (listQ, index) => {
  const immediateParent = listQ[0].question_id
  const flatList = [];
  HELPER.flattenQuestions(listQ, flatList);
  return flatList.map(q => {
    return {
      ...q,
      parentId: `${get(q, 'properties.parent_list_id', '')}_${index}`,
      immediateParent,
    }
  });

}

function displayRemoveLink (list, index) {
  return index  < list.length;
}

function NListQuestions({
  list,
  removeButtonText,
  removeTooltip,
  removeItem,
  isReview,
  theme,
}) {
  const cssVariables =  getCSSVariables(theme);
  const {
    config: {
      theme: {
        global: { removeButton },
      },
    },
  } = theme;
  const themeConfig = removeButton && removeButton.themeConfiguration
  const font = at(cssVariables, ['pageOverride.font', 'global.font']);
  if (themeConfig) {
    themeConfig.font= font?.[0]?.['button-remove'] || font?.[1]?.['button-remove']
  }

  return (
    <>
    {
      Object.keys(list).map(key => {
        return list[key].map((listQ, index) => {
          return (
            <div data-name='n-list-display'>
              <QuestionRenderer rebuild={ false } questionList={ updateParentIndex(listQ, index) } />
              {
                displayRemoveLink(list[key], index) && !isReview  &&
                <ButtonContainer>
                  <Button
                    color='green'
                    label={ removeButtonText }
                    disabled={ false }
                    type='text'
                    handleButtonClick={ () => {
                      removeItem(key, index)
                    } }
                    themeConfiguration= { themeConfig }
                  />
                  <Tooltip tooltipHTML={ removeTooltip } />
                </ButtonContainer>
              }
            </div>
          )
        })
      })
    }
  </>
  )
}

export default withTheme(NListQuestions);
