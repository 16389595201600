/**
 * @description Define CSS Variables to be used for various screen sizes like
 * for mobiles and desktop view (later we can add support for tablets here also).
 * 
 * Convention to be used while defining variables -
 * <component_name>-<element_id>
 * where -
 * component_id - States the component name for which the variable is defined for (required)
 * element_id - States the element for which the CSS is defined ex. label, placeholder etc.
 * Eg. - tooltipModal-footerNote
 * 
 * Note -
 * By default global based CSS values will be applied, if there's any requirement 
 * specific to particular page, we need to use the concept of pageOverrides there.
 * PageOverrides specific changes must be done based on pageId value.
 * PageOverrides will be given higher prefrence order than the global values.
 * 
 * Note - Here hyphen (-) is used as a separator element.
 */
 const fontFamily = {
    small: 'Roboto',
    medium: 'Roboto-Medium',
    bold: 'Roboto-Bold',
}

const cssVariables = {
    desktop: {
        font: {
            /**
             * The font property is a shorthand property for:
                font-style
                font-variant
                font-weight
                font-size/line-height
                font-family
                Note: The font-size and font-family values are required. 
                If one of the other values is missing, their default value are used.
             */
            global: {
                'tooltipModal-footerNote': `12px ${fontFamily.small}`,
                'haveQuestions':`600 18px ${fontFamily.medium}`,
                'leftPanelQuestions-heading':`24px ${fontFamily.medium}`,
                'leftPanelQuestions-item':`16px ${fontFamily.small}`,
                'groupQuestionText':`40px ${fontFamily.bold}`,
                'singleSelect-defaultRadio':'14px AllStateSans',
                'singleSelect-classic':`14px ${fontFamily.medium}`,
                'singleSelect-card':`600 16px ${fontFamily.medium}`,
                'singleSelect-oval':`600 16px ${fontFamily.medium}`,
                'singleSelect-selectWrapper':`600 14px ${fontFamily.medium}`,
                'singleSelect-label':`600 14px ${fontFamily.bold}`,
                'multiSelect-label':`600 14px ${fontFamily.medium}`,
                'multiSelect':`14px ${fontFamily.small}`,
                'input-label':`400 15px ${fontFamily.small}`,
                'input-placeholder':`16px ${fontFamily.small}`,
                'datePicker':`400 15px ${fontFamily.small}`,
                'reviewlabel-text':`15px/25px ${fontFamily.small}`,
                'reviewlabel-des':`15px/25px ${fontFamily.small}`,
                'table-heading':`36px ${fontFamily.small}`,
                'table-p':`15px ${fontFamily.small}`,
                'button-next':`500 17px ${fontFamily.bold}`,
                'button-prev':`500 17px ${fontFamily.bold}`,
                'button-action':`600 16px ${fontFamily.medium}`,
                'button-list-edit':`500 14px ${fontFamily.bold}`,
                'button-list-delete':`500 14px ${fontFamily.bold}`,
                'button-list-add':`500 16px ${fontFamily.bold}`,
                'button-list-item-add':`600 16px ${fontFamily.medium}`,
                'button-list-close':`600 16px ${fontFamily.medium}`,
                'button-remove':`600 16px ${fontFamily.medium}`,
                'button-logout':`600 16px ${fontFamily.medium}`,
                'button-modal':`600 16px ${fontFamily.medium}`,
                'button-update':`500 17px ${fontFamily.medium}`,
                'selectOption-label':`600 14px/26px ${fontFamily.bold}`,
                'selectOption-placeholder':`400 15px ${fontFamily.small}`,

            },
            health: {
                'textElement-labelText':`600 14px ${fontFamily.medium}`,
                'input-labelText':`600 14px ${fontFamily.medium}`,
            },
            questions: {
                'textElement-labelText':`600 14px ${fontFamily.bold}`,
                'input-labelText':`600 14px ${fontFamily.medium}`,
            },
            riders: {
                'input-slider-description':`16px ${fontFamily.small}`,
                'input-slider-text':`600 18px ${fontFamily.medium}`,
                'textElement-labelText':`600 18px ${fontFamily.medium}`,
                'singleSelect-description':`16px ${fontFamily.small}`,
            },
            plan: {
                'textElement-p':`16px ${fontFamily.small}`,
                'table-header-content':`600 24px ${fontFamily.medium}`,
            },
            review: {
                'reviewlabel-group': `17px ${fontFamily.bold}`,
            },
        },
        margin: {
            global: {
                'reflexiveCard-level1Wrapper': '0% -5.5% 5.5% -5.5%',
                'listQuestions-listWrapper': '0% -5.5% 5.5% -5.5%',
                'listQuestionCard-wrapper': '0% -5.5% 5.5% -5.5%',
            },
            plan: {
                'reflexiveCard-level1Wrapper': '0% 0% 0% 0%',
            },
        },
        padding: {
            /**
             * The padding property is a shorthand property for:
                padding-top
                padding-right
                padding-bottom
                padding-left
                Note: Padding creates extra space within an element, 
                while margin creates extra space around an element.
             */
            global: {
                'card': 'clamp(24px, 5%, 80px) clamp(16px, 3%, 100px) clamp(24px, 5%, 40px) clamp(16px, 3%, 100px)',
                'listQuestionCard-wrapper': '20px',
                'reflexiveCard-level1Wrapper': '20px',
                'leftPanelQuestions-tableDiv': 'clamp(5px, 5%, 20px)',
                'haveQuestions-haveQuestionsWrapper': '0px 0px 0px 0px',
                'reviewBreadcrumb-wrapper': 'clamp(24px, 5%, 10px) clamp(24px, 5%, 100px) clamp(24px, 5%, 10px) clamp(54px, 5%, 100px);',
            },
            riders: {
                'haveQuestions-haveQuestionsWrapper': '50px 0px 0px 0px',
            },
            plan: {
                'card': '0px 0px 10px 0px',
            },
            quotereview: {
                'card': 'clamp(24px, 5%, 60px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
            },
            review: {
                'card': 'clamp(24px, 5%, 20px) clamp(16px, 5%, 100px) clamp(24px, 5%, 20px) clamp(16px, 5%, 100px)',
            },
        },
        top: {
            /**
             * The top property affects the vertical position of a positioned element. 
             * This property has no effect on non-positioned elements.
             */
            global: {

            },
        },
        height: {
            /**
             * The height property sets the height of an element.
             * Note: The min-height and max-height properties override the height property.
             */
            global: {
                'content-main': '465px',
            },
        },
        width: {
            /**
             * The width property sets the width of an element.
                The width of an element does not include padding, borders, or margins!
                Note: The min-width and max-width properties override the width property.
             */
            global: {

            },
            riders: {
                'footerNotes-footerWrapper': '90%',
            },
            questions: {
                'footerNotes-footerWrapper': '90%',
            },
            payment: {
                'footerNotes-footerWrapper': '90%',
            },
        },
        maxWidth: {
            /**
             * The max-width property defines the maximum width of an element.
                If the content is larger than the maximum width, it will automatically change the height of the element.
                If the content is smaller than the maximum width, the max-width property has no effect.
                Note: This prevents the value of the width property from becoming larger than max-width. 
                The value of the max-width property overrides the width property.
             */
            global: {
                'footerNotes-footerWrapper': '80%',
                'haveQuestions-haveQuestionsWrapper': '80%',
            },
            riders: {
                'footerNotes-footerWrapper': '780px',
            },
            questions: {
                'footerNotes-footerWrapper': '780px',
            },
            payment: {
                'footerNotes-footerWrapper': '780px',
            },
        },
    },
    tablet: {
        font: {
            /**
             * The font property is a shorthand property for:
                font-style
                font-variant
                font-weight
                font-size/line-height
                font-family
                Note: The font-size and font-family values are required. 
                If one of the other values is missing, their default value are used.
             */
            global: {
                'tooltipModal-footerNote': `12px ${fontFamily.small}`,
                'haveQuestions':`600 16px/24px ${fontFamily.medium}`,
                'leftPanelQuestions-heading':`18px ${fontFamily.medium}`,
                'leftPanelQuestions-item':`16px ${fontFamily.small}`,
                'groupQuestionText':`24px ${fontFamily.bold}`,
                'singleSelect-defaultRadio':'14px AllStateSans',
                'singleSelect-classic':`14px ${fontFamily.medium}`,
                'singleSelect-card':`600 16px ${fontFamily.medium}`,
                'singleSelect-oval':`600 14px ${fontFamily.medium}`,
                'singleSelect-selectWrapper':`600 14px ${fontFamily.medium}`,
                'singleSelect-label':`600 14px ${fontFamily.bold}`,
                'multiSelect-label':`600 14px ${fontFamily.medium}`,
                'multiSelect':`14px ${fontFamily.small}`,
                'input-label':`400 15px ${fontFamily.small}`,
                'input-placeholder':`16px ${fontFamily.small}`,
                'datePicker':`400 15px ${fontFamily.small}`,
                'reviewlabel-text':`15px/25px ${fontFamily.small}`,
                'reviewlabel-des':`15px/25px ${fontFamily.small}`,
                'table-heading':`36px ${fontFamily.small}`,
                'table-p':`15px ${fontFamily.small}`,
                'button-next':`500 17px ${fontFamily.bold}`,
                'button-prev':`500 17px ${fontFamily.bold}`,
                'button-action':`600 16px/20px ${fontFamily.medium}`,
                'button-list-edit':`500 14px/20px ${fontFamily.bold}`,
                'button-list-delete':`500 14px/20px ${fontFamily.bold}`,
                'button-list-add':`500 16px/20px ${fontFamily.bold}`,
                'button-list-item-add':`600 16px/20px ${fontFamily.medium}`,
                'button-list-close':`600 16px/20px ${fontFamily.medium}`,
                'button-remove':`600 16px/20px ${fontFamily.medium}`,
                'button-logout':`600 16px/20px ${fontFamily.medium}`,
                'button-modal':`600 16px/20px ${fontFamily.medium}`,
                'button-update':`500 17px ${fontFamily.medium}`,
                'selectOption-label':`500 14px/20px ${fontFamily.medium}`,
                'selectOption-placeholder':`400 15px ${fontFamily.small}`,

            },
            health: {
                'textElement-labelText':`600 14px ${fontFamily.medium}`,
                'input-labelText':`600 14px ${fontFamily.medium}`,
            },
            questions: {
                'textElement-labelText':`600 14px ${fontFamily.bold}`,
                'input-labelText':`600 14px ${fontFamily.medium}`,
            },
            riders: {
                'input-slider-description':`16px ${fontFamily.small}`,
                'input-slider-text':`600 18px ${fontFamily.medium}`,
                'textElement-labelText':`600 18px ${fontFamily.medium}`,
                'singleSelect-description':`16px ${fontFamily.small}`,
            },
            plan: {
                'textElement-p':`16px ${fontFamily.small}`,
                'table-header-content':`600 24px ${fontFamily.medium}`,
            },
            review: {
                'reviewlabel-group': `17px ${fontFamily.bold}`,
            },
        },
        margin: {
            global: {
                'reflexiveCard-level1Wrapper': '0% -5.5% 5.5% -5.5%',
                'listQuestions-listWrapper': '0% -5.5% 5.5% -5.5%',
                'listQuestionCard-wrapper': '0% -5.5% 8% -5.5%',
            },
            plan: {
                'reflexiveCard-level1Wrapper': '0% 0% 0% 0%',
            },
        },
        padding: {
            /**
             * The padding property is a shorthand property for:
                padding-top
                padding-right
                padding-bottom
                padding-left
                Note: Padding creates extra space within an element, 
                while margin creates extra space around an element.
             */
            global: {
                'card': 'clamp(24px, 5%, 80px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
                'listQuestionCard-wrapper': '20px',
                'reflexiveCard-level1Wrapper': '20px',
                'leftPanelQuestions-tableDiv': 'clamp(5px, 5%, 20px)',
                'haveQuestions-haveQuestionsWrapper': '0px 0px 0px 0px',
                'reviewBreadcrumb-wrapper': 'clamp(24px, 5%, 10px) clamp(24px, 5%, 100px) clamp(24px, 5%, 10px) clamp(54px, 5%, 100px);',
            },
            riders: {
                'haveQuestions-haveQuestionsWrapper': '50px 0px 0px 0px',
            },
            plan: {
                'card': '0px 0px 10px 0px',
            },
            quotereview: {
                'card': 'clamp(24px, 5%, 60px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
            },
            review: {
                'card': 'clamp(24px, 5%, 60px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
            },
        },
        top: {
            /**
             * The top property affects the vertical position of a positioned element. 
             * This property has no effect on non-positioned elements.
             */
            global: {

            },
        },
        height: {
            /**
             * The height property sets the height of an element.
             * Note: The min-height and max-height properties override the height property.
             */
            global: {
                'content-main': '465px',
            },
        },
        width: {
            /**
             * The width property sets the width of an element.
                The width of an element does not include padding, borders, or margins!
                Note: The min-width and max-width properties override the width property.
             */
            global: {

            },
            riders: {
                'footerNotes-footerWrapper': '90%',
            },
            questions: {
                'footerNotes-footerWrapper': '90%',
            },
            payment: {
                'footerNotes-footerWrapper': '90%',
            },
        },
        maxWidth: {
            /**
             * The max-width property defines the maximum width of an element.
                If the content is larger than the maximum width, it will automatically change the height of the element.
                If the content is smaller than the maximum width, the max-width property has no effect.
                Note: This prevents the value of the width property from becoming larger than max-width. 
                The value of the max-width property overrides the width property.
             */
            global: {
                'footerNotes-footerWrapper': '80%',
                'haveQuestions-haveQuestionsWrapper': '80%',
            },
            riders: {
                'footerNotes-footerWrapper': '780px',
            },
            questions: {
                'footerNotes-footerWrapper': '780px',
            },
            payment: {
                'footerNotes-footerWrapper': '780px',
            },
        },
    },
    mobile: {
        font: {
            /**
             * The font property is a shorthand property for:
                font-style
                font-variant
                font-weight
                font-size/line-height
                font-family
                Note: The font-size and font-family values are required. 
                If one of the other values is missing, their default value are used.
             */
            global: {
                'tooltipModal-footerNote': '16px',
                'haveQuestions': `15px ${fontFamily.medium}`,
                'leftPanelQuestions-heading':`20px ${fontFamily.medium}`,
                'leftPanelQuestions-item':`16px ${fontFamily.small}`,
                'groupQuestionText':`22px ${fontFamily.bold}`,
                'singleSelect-defaultRadio':`14px ${fontFamily.small}`,
                'singleSelect-classic':`15px ${fontFamily.medium}`,
                'singleSelect-card':`600 16px ${fontFamily.medium}`,
                'singleSelect-oval':`600 12px ${fontFamily.medium}`,
                'singleSelect-selectWrapper':`600 14px ${fontFamily.medium}`,
                'singleSelect-label':`15px ${fontFamily.medium}`,
                'multiSelect-label':`15px ${fontFamily.medium}`,
                'multiSelect':`15px ${fontFamily.small}`,
                'input-label':`400 15px ${fontFamily.small}`,
                'input-placeholder':`16px ${fontFamily.small}`,
                'datePicker':`400 15px ${fontFamily.small}`,
                'reviewlabel-text':`500 15px/25px ${fontFamily.medium}`,
                'reviewlabel-des':`400 15px/25px ${fontFamily.small}`,
                'table-heading':`26px ${fontFamily.small}`,
                'table-p':`11px ${fontFamily.small}`,
                'button-next':`500 17px ${fontFamily.bold}`,
                'button-prev':`500 17px ${fontFamily.bold}`,
                'button-action':`600 16px ${fontFamily.medium}`,
                'button-list-edit':`500 14px ${fontFamily.bold}`,
                'button-list-delete':`500 14px ${fontFamily.bold}`,
                'button-list-add':`500 16px ${fontFamily.bold}`,
                'button-list-item-add':`600 16px ${fontFamily.medium}`,
                'button-list-close':`600 16px ${fontFamily.medium}`,
                'button-remove':`600 16px ${fontFamily.medium}`,
                'button-logout':`600 16px ${fontFamily.medium}`,
                'button-modal':`600 16px ${fontFamily.medium}`,
                'button-update':`500 17px ${fontFamily.medium}`,
                'selectOption-label':`500 14px/20px ${fontFamily.medium}`,
                'selectOption-placeholder':`400 15px ${fontFamily.small}`,
            },
            health: {
                'textElement-labelText':`600 14px ${fontFamily.medium}`,
                'input-labelText':`600 14px ${fontFamily.medium}`,
            },
            questions: {
                'textElement-labelText':`600 15px ${fontFamily.bold}`,
                'input-labelText':`600 15px ${fontFamily.medium}`,
            },
            riders: {
                'input-slider-description':`16px ${fontFamily.small}`,
                'input-slider-text':`18px ${fontFamily.medium}`,
                'textElement-labelText':`18px ${fontFamily.medium}`,
                'singleSelect-description':`16px ${fontFamily.small}`,
            },
            plan: {
                'textElement-p':`16px ${fontFamily.small}`,
                'table-header-content':`600 4vw ${fontFamily.medium}`,
            },
            review: {
                'reviewlabel-group': `17px ${fontFamily.bold}`,
            },
        },
        margin: {
            global: {
                'reflexiveCard-level1Wrapper': '0% -4.5% 4.5% -4.5%',
                'listQuestions-listWrapper': '0% -4.5% 8% -4.5%',
                'listQuestionCard-wrapper': '0% -5.5% 8% -5.5%',
            },
            plan: {
                'reflexiveCard-level1Wrapper': '0% 0% 0% 0%',
            },
        },
        padding: {
            /**
             * The padding property is a shorthand property for:
                padding-top
                padding-right
                padding-bottom
                padding-left
                Note: Padding creates extra space within an element, 
                while margin creates extra space around an element.
             */
            global: {
                'card': 'clamp(24px, 5%, 80px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(25px, 5%, 100px)',
                'listQuestionCard-wrapper': '8px',
                'reflexiveCard-level1Wrapper': '8px',
                'haveQuestions-haveQuestionsWrapper': '0px 0px',
                'leftPanelQuestions-tableDiv': 'clamp(5px, 5%, 20%)',
                'reviewBreadcrumb-wrapper': 'clamp(24px, 5%, 10px) clamp(24px, 5%, 100px) clamp(24px, 5%, 10px) clamp(24px, 5%, 100px);',
            },
            plan: {
                'card': '0px 0px 10px 0px',
            },
            quotereview: {
                'card': 'clamp(24px, 5%, 60px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
            },
            review: {
                'card': 'clamp(24px, 5%, 60px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)',
            },
        },
        top: {
            /**
             * The top property affects the vertical position of a positioned element. 
             * This property has no effect on non-positioned elements.
             */
            global: {

            },
            riders: {
                'leftPanelQuestions-tableWrapper': '45px',
            },
            payment: {
                'leftPanelQuestions-tableWrapper': '200px',
            },
        },
        height: {
            /**
             * The height property sets the height of an element.
             * Note: The min-height and max-height properties override the height property.
             */
            global: {
                'content-main': '465px',
            },
            questions: {
                'content-main': '918px',
            },
        },
        width: {
            /**
             * The width property sets the width of an element.
                The width of an element does not include padding, borders, or margins!
                Note: The min-width and max-width properties override the width property.
             */
            global: {

            },
            riders: {
                'footerNotes-footerWrapper': '100%',
            },
            questions: {
                'footerNotes-footerWrapper': '100%',
            },
            payment: {
                'footerNotes-footerWrapper': '100%',
            },
        },
        maxWidth: {
            /**
             * The max-width property defines the maximum width of an element.
                If the content is larger than the maximum width, it will automatically change the height of the element.
                If the content is smaller than the maximum width, the max-width property has no effect.
                Note: This prevents the value of the width property from becoming larger than max-width. 
                The value of the max-width property overrides the width property.
             */
            global: {
                'footerNotes-footerWrapper': '100%',
                'haveQuestions-haveQuestionsWrapper': '100%',
            },
        },
    },
}

module.exports = cssVariables;
