import get from 'lodash/get';
import Bowser from 'bowser';
import { 
  SET_SCREEN_VIEWPORT, 
  SET_LIST_RELATION, 
  SET_IP_ADDRESS,
  SET_VISITOR_ID,
  IS_INTERNET_CONNECTED,
} from '../actions/meta';
import LocalStorageServices from '../../util/localStorageServices';

const browser = Bowser.getParser(window.navigator.userAgent);
const os = browser.getOSName();
const platform = browser.getPlatformType();


/**
 * Defines breakpoints based on the screen width
 * desktop - above 1024px
 * tablet - between 768px to 1024px
 * mobile - less than 768px
*/
const getScreenViewport = () => {
  if (window.innerWidth < 768) {
    return 'mobile'
  } if (window.innerWidth > 767 && window.innerWidth < 1025) {
    return 'tablet'
  } if (window.innerWidth > 1024) {
    return 'desktop'
  }
  return ''
}

const initialState = {
  visitorId: LocalStorageServices.getVisitorId(),
  userAgent: browser.getBrowser(),
  screenViewport: getScreenViewport(),
  listRelation: {},
  onLine: navigator.onLine,
  ipAddress: '',
  os,
  platform,
  isSmallScreen: window.innerWidth < 768,
}

const getRelation = (question) => {
  const pId = get(question, 'properties.parent_list_id', '');
  const qId = question.question_id.split('_$ureify_')[1];
  return {
    [qId]: pId,
  }
}

function updateListRelation(listRelation, payload) {
  const { question, activeSubsectionId } = payload;
  if (listRelation[activeSubsectionId]) {
   return {
     ...listRelation,
     [activeSubsectionId]: {
       ...listRelation[activeSubsectionId],
       ...getRelation(question),
     },
   }
  }
  return {
    ...listRelation,
    [activeSubsectionId]: getRelation(question),
  }
}

function meta(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SCREEN_VIEWPORT:
      return {
        ...state,
        screenViewport: payload,
      };
    case SET_LIST_RELATION:
      return {
        ...state,
        listRelation: updateListRelation(state.listRelation, action.payload),
      };
    case SET_IP_ADDRESS:
      return {
        ...state,
        ipAddress: payload,
      };
    case SET_VISITOR_ID:
      return {
        ...state,
        visitorId: payload,
      };
    case IS_INTERNET_CONNECTED:
      return {
        ...state,
        onLine: payload,
      };
    default:
      return state;
  }
}

export default meta;
