/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

function useReflexiveWrapResizeHandler(reflexiveWrapId) {
    let listWrapperElement; let listWrapperPEl;

    const handleResize = () => {
        listWrapperElement = document.querySelector(`div[id*='${reflexiveWrapId}']`);

        listWrapperPEl = listWrapperElement?.parentElement
        if(listWrapperPEl.hasAttribute('data-name')) listWrapperPEl = listWrapperElement?.parentElement?.parentElement

        if(listWrapperPEl){
            const paddingL = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-left');
            const paddingR = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-right');
            listWrapperElement.style.marginLeft = `-${paddingL}`;
            listWrapperElement.style.marginRight = `-${paddingR}`;
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize, false);
        return () => window.removeEventListener('resize', handleResize, false);
    }, [listWrapperPEl?.clientWidth])
}

export default useReflexiveWrapResizeHandler;