/* eslint-disable react/no-danger */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { getLabelProps, shouldShowPageInfo } from './utils';
import { validateInnerHtml } from '../utils';
import ReviseButton from '../Components/UIElements/ReviseButton';

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  ${({ pageId }) => pageId === 'completed' && `
    justify-content: center;
    text-align: center;
  `}
  position: relative;
  margin-top: ${({ layout, pageId }) => (layout === 'single' && !pageId === 'review') ? '0' : '140px'};
  font-size: ${({ headerFontSize }) => (headerFontSize && `${headerFontSize}px`) || '28px'};
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => ( lineHeight && `${lineHeight}px`) || '36px'};
  height: ${({ height }) => `${height}px`};
  /* font-weight doesn't work in Chrome but works in Firefox. In order to look consistent across browsers so removing at other places.'*/
  // font-weight: 600;
  font-family: 'Roboto-Bold';

  @media (min-width: 1281px) {
    position: relative;
    font-size: ${({ headerFontSize }) => (headerFontSize && `${headerFontSize}px`) || '60px'};
    color: ${({ color }) => color};
    line-height: ${({ lineHeight }) => ( lineHeight && `${lineHeight}px`) || '75px'};
    height: ${({ height }) => `${height}px`};
    // font-weight: 600;
  }
  @media (min-width: 1028px) and (max-width: 1280px) {
    position: relative;
    font-size: ${({ headerFontSize }) => ( headerFontSize && `${headerFontSize - 8}px`) || ''};
    color: ${({ color }) => color};
    line-height: ${({ lineHeight }) => ( lineHeight && `${lineHeight}px`) || '36px'};
    height: ${({ height }) => `${height}px`};
    // font-weight: 600;
  }
  @media (min-width: 767px) and (max-width: 1027px) {
    position: relative;
    font-size: ${({ headerFontSize }) => ( headerFontSize && `${headerFontSize - 12}px`) || '28px'};
    color: ${({ color }) => color};
    line-height: ${({ lineHeight }) => ( lineHeight && `${lineHeight}px`) || '36px'};
    height: ${({ height }) => `${height}px`};
    // font-weight: 600;
  }

  @media (min-width: 640px) and (max-width: 767px) {
    font-size: 28px;
    line-height: 40px;
    margin-top: 30px;
  }

  @media (max-width: 640px) {
    font-size: 28px;
    line-height: 40px;
    margin-top: 30px;
  }
`;

const Header = styled.div`
  margin-top: 100px;
  margin-bottom: 30px;
  max-width: ${({ layout })=> layout==='single' ? '80%' : '780px'};
  width: ${({ layout }) => layout === 'double' && '90%'};
  ${({ width  }) => width && `width: ${width}`};
	margin-left: ${({ marginLeft })=> marginLeft || 'auto' };
  margin-right: auto;
  ${({ maxWidth }) => maxWidth  &&`
  max-width: ${maxWidth};
  `}
  @media (max-width: 767px) {
    max-width:100%;
    margin-left:0;
    margin-top: 10px;
  }
  ${({ top, layout, pageId }) =>
     (layout === 'double' && pageId !== 'questions') &&
     `
      @media (max-width: 767px){
        position: relative;
        top: ${top}};
      }
  `};
  }
`;

const SubHeading = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ subSectionHeaderColor }) => subSectionHeaderColor || 'rgba(255, 255, 255, 1)' };
  line-height: 26px;
  max-width: 680px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const FakeHeader = styled.div`
	margin-top: 140px;
`;

const QuoteHeader = styled.div`
    margin: ${({ margin }) => margin ||'20px auto'};
    max-width: ${({ maxWidth }) => maxWidth || '780px'};
`;

const QuoteHeading = styled(Heading)`margin-top: 0`;

function SectionHeader({ title, subTitle, layout, theme, pageId ,reviseButtonLabel ,sectionData ,sectionIndex, headingId, subHeadingId, isPageType, isTitleHidden }) {
  const {
    screenViewport,
    config: {
    theme: {
      global: {
        showPageInfo,
        text: {
          sectionHeaderColor,
        },
      },
      components,
      pageOverrides,
    },
    customHeader: { isPresent: isCustomHeaderPresent } = {},
  } } = theme;
  const  maxWidth  = pageOverrides[pageId] && pageOverrides[pageId].maxWidth;
  let width = pageOverrides[pageId] && pageOverrides[pageId].sectionHeaderWidth;
  if(pageOverrides[pageId]?.newBusinessTransaction && isPageType) {
    width = get(pageOverrides[pageId], 'newBusinessTransaction.sectionHeaderWidth');
  }

  const  marginLeft = get(pageOverrides, `${pageId}.card.margin.left`);

  const  headerFontSize= pageOverrides[pageId] && pageOverrides[pageId].headerFontSize;
  const  subSectionHeaderColor= pageOverrides[pageId] && pageOverrides[pageId].subSectionHeaderColor;
  const  headerLineHeight= pageOverrides[pageId] && pageOverrides[pageId].headerFontSize;
  const  headerHeight= pageOverrides[pageId] && pageOverrides[pageId].headerHeight;


  const pageOverrideShowPageInfo = shouldShowPageInfo(pageId, pageOverrides);
  const quoteHeaderProps=getLabelProps('quoteHeader',components,pageId,pageOverrides);

  const leftWrapperHeight = get(document.getElementById('left-wrapper'), 'clientHeight', '0');

  if ((screenViewport === 'mobile') && layout === 'double' && pageId === 'questions') {
    return null
  }

  if (!pageOverrideShowPageInfo && layout !== 'auto' && !showPageInfo) {
    return <FakeHeader />
  }

  if (isCustomHeaderPresent) {
    return (
      <QuoteHeader { ...quoteHeaderProps }>
      {reviseButtonLabel && <ReviseButton reviseButtonLabel={ reviseButtonLabel } sectionData={ sectionData } sectionIndex={ sectionIndex }/>}
        <QuoteHeading color={ sectionHeaderColor } layout={ layout } name="header" id={ headingId }>
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(title) } } />
        </QuoteHeading>
        <SubHeading name="header" id={ subHeadingId }>
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(subTitle) } } />
        </SubHeading>
      </QuoteHeader>
    );
  }

  if (layout !== 'auto') {
    return (
      <Header top={ `-${leftWrapperHeight+25}px` } layout={ layout } pageId = { pageId } maxWidth = { maxWidth } width={ width } marginLeft={ marginLeft }>
        {!isTitleHidden &&
          <Heading color={ sectionHeaderColor } layout={ layout } id={ headingId } name="header" pageId = { pageId } role="heading" aria-level="1" headerFontSize={ headerFontSize } lineHeight={ headerLineHeight } height={ headerHeight }>
            <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(title) } } />
          </Heading>
        }
        <SubHeading name="header" id={ subHeadingId } subSectionHeaderColor={ subSectionHeaderColor }>
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(subTitle) } } />
        </SubHeading>
       </Header>
    );
  }
  return null;
}

SectionHeader.defaultProps = {
  headingId: 'heading',
  subHeadingId: 'sub-heading',
}

SectionHeader.propTypes = {
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  headingId: PropTypes.string,
  subHeadingId: PropTypes.string,
}

export default withTheme(SectionHeader);
