/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import at from 'lodash/at';
import styled, { withTheme } from 'styled-components';
import { Alert, Card } from 'crux';
import Section from './Section';
import { getCSSVariables } from '../../cruxComponents/utils';

// Section cards without color linings
const cardsWithoutLinings = ['save_quote', 'HaveQuestionsGroup'];

const ReviewQuestionsStyles = styled.div`
  .costDisclosureHead > h4,
	.costDisclosureFooter > h4 {
		color: rgb(51, 51, 51);
		line-height: 25px;
		font-size: 17px;
		font-family: Roboto;
	}
	.costDisclosureBody > ul {
		font-size: 17px;
    line-height: 25px;
    font-family: Roboto;
	}
`

const AlertWrapper = styled.div`
  div[type='error'] {
    width: 780px;
    margin-right: auto;
    margin-left: auto;
    max-width: 80%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
`
const ReviewQuestions = ({
  errors,
  sectionData,
  sectionLength,
  index,
  processing,
  theme,
  reviewActiveIds,
  setReviewQuestionSet,
}) => {
  const {
    pageId,
    pageLayout,
    config: {
      theme: {
        pageOverrides,
        components: {
          card: { themeConfiguration },
        },
        colorLining,
      },
    },
  } = theme;

  const { errorMessage, errorTitle, hasError } = errors;

  const { id, questionList, review } = sectionData;

  // CSS Variables
  const cssVariables = getCSSVariables(theme);
  const padding = at(cssVariables, ['pageOverride.padding.card', 'global.padding.card']);
  const accordian = questionList.length === 1 && questionList[0].accordian; // Applicable to review page.

  let otherProps = {};
  let imgUrl = '';
  let imgAlt = '';
  let backgroundColor;
  if (questionList.length === 1 && questionList[0].isBreadcrumbCard) {
    otherProps = {
      padding: '0',
    };
  }
  let questionText = '';
  if (questionList.length === 1) {
    imgUrl = questionList[0].active_img_url;
    imgAlt = questionList[0].title;
    questionText = questionList[0].question_text;
  }
  let leftBorderColor;
  let leftBorderWidth;
  if(questionList.length > 0){
    backgroundColor = get(questionList[0], 'properties.background-color', '#ffffff');
    leftBorderColor = get(questionList[0], 'style.left_border.color_code', '');
    leftBorderWidth = get(questionList[0], 'style.left_border.width', '');
  }

  if(colorLining && !cardsWithoutLinings.includes(questionList[0].question_id)){
    leftBorderColor=leftBorderColor || colorLining[pageId].color;
    leftBorderWidth=leftBorderWidth || colorLining[pageId].width;
  }
  let themeConfig = pageOverrides[pageId].imageMargin? { ...themeConfiguration,marginRight:pageOverrides[pageId].imageMargin } : themeConfiguration || {};
  themeConfig = pageOverrides[pageId].imageHeight? { ...themeConfig,imageHeight:pageOverrides[pageId].imageHeight } : themeConfig;
  themeConfig = pageOverrides[pageId].imageWidth? { ...themeConfig,imageWidth:pageOverrides[pageId].imageWidth } : themeConfig;

  if (pageOverrides[pageId]?.cardWidth)
    themeConfig.width = pageOverrides[pageId].cardWidth;

  const breadCrumbClick = (event) => {
      if (event.key === undefined || event.key === 'Enter')
        setReviewQuestionSet();
  };

  if (accordian) {
    otherProps.onClick = breadCrumbClick;
    otherProps.isOpened = reviewActiveIds.includes(questionList[0].question_id);
    themeConfig.height = pageOverrides[pageId].accordianHeight;
    themeConfig.border = '1px solid #595959';
  }

	return (
    <ReviewQuestionsStyles>
			<Card
				leftBorderWidth={ leftBorderWidth }
				leftBorderColor={ leftBorderColor }
				background={ backgroundColor } 
				imgUrl= { imgUrl }
				imgAlt={ imgAlt }
				key={ id }
				id={ `review-question-${id}` }
				layout={ pageLayout }
				{ ...otherProps } 
				hideWrapper={ !questionText }
				padding={ imgUrl ? '0' : ( padding[0] || padding[1] ) }
				themeConfiguration={ themeConfig } >
				<Section
					questionList={ questionList }
					key={ id }
					sectionIndex={ index }
					pageId={ pageId }
					reviewProps={ review }
					processing={ processing }
				/>
			</Card>
      <AlertWrapper>
        {hasError && (sectionLength-1 === +index) && (
            <Alert
              message={ errorMessage }
              type='error'
              title={ errorTitle }
            />)
        }
      </AlertWrapper>
    </ReviewQuestionsStyles>
	);
};

ReviewQuestions.propTypes = {
  sectionData: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ReviewQuestions);
