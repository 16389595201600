/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import get from 'lodash/get';
import 'crux/dist/index.css'
import { DatePicker as DateSelector, DateInput } from 'crux';
import { at, isNumber } from 'lodash';
import { updateAnswersArray, updateQuestionAnswerTimeStamp } from '../redux/actions';
import { getLabelProps, getValidators, getWidth, getTooltipProps, getCSSVariables, checkMonthYearFormat, validateQuestionInput, getTimeStampData } from './utils';
import { DateSelectWrapper } from './utils/styles';
import { getUTCTimestamp } from '../utils';
import { SET_LIST_MODE } from '../redux/types';

const initialState = {
	// adding to make sure api call is done only when date is edited
	isEdited: false,
}

const datePatternMap = {
	'MM/DD/YYYY': 'MM{/}`DD{/}`YYYY',
	'MM/YYYY': 'MM{/}`YYYY',
}

const DatePicker = ({
	question,
	optionalText,
	theme,
	handleChange,
	handleBlur,
	isEditMode,
	screenViewport,
	lastFocusedElementId,
	currentSectionQuestionsList,
	currentSectionAnswerList,
	listParentId,
	updateTimeStamp,
	meta,
	changeMode,
	isListMode,
	updateListMode,
}) => {
    const [state, setState] = useState(initialState)
	const errorObjRef = useRef({});
	const [isDateCleared, setIsDateCleared] = useState(false)
	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme, errorMessageColor },
			},
		},
	} = theme;

	const labelProps = getLabelProps(
		'input',
		components,
		pageId,
		pageOverrides,
	);
	const {
		question_id: questionId,
		question_text: questionText,
		hint_title: tooltipTitle,
    	hint_text: tooltipBody,
		hint_html: tooltipHTML,
		response,
		tooltip,
		question_status: questionStatus,
		reflexive_index: reflexiveIndex,
		is_hidden: isHidden,
		transaction_log: transactionLog,
		last_submitted_at: lastSubmittedAt,
		child_questions: childQuestions,
		is_mras_question: isMrasQ,
	} = question;

	const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);
	const reflexiveQFlag = get(question, 'reflexive_question_flag', false);
	const childQs = get(question, 'child_questions', false);
	const inputRefTwo = useRef();
	const blurActionRef = useRef(false);
	const timeStampRef = useRef(null);
	const [isBlur, setIsBlur] = useState(false);

	const handleUpdateTimeStamp = () => {
		updateTimeStamp(getTimeStampData(timeStampRef, transactionLog, meta))
	}

	// Tab Navigation
	useEffect(() => {
    const shouldFocus = lastFocusedElementId === questionId;
	Array.from(document.querySelectorAll('div[id*=\'date-picker-wrapper\'] > div')).forEach((element) => {
		if(element)
			element?.setAttribute('data-clarity-mask', 'True');
	})
    if (
      (state.inputRef || (inputRefTwo && inputRefTwo.current)) &&
      shouldFocus
    ) {
      if (state.inputRef) {
        if (typeof state.inputRef.focus === 'function') {
          state.inputRef.focus();
        }
      }
     else if (inputRefTwo && inputRefTwo.current ) {
        if (typeof inputRefTwo.current.focus === 'function') {
          inputRefTwo.current.focus();
        }
      }
	}
  }, []);

	useEffect(() => {
		if ((isEditMode || question.response) && (errorObjRef.curent && Object.keys(errorObjRef.curent).length === 0)) {
			handleChange(question.response, false, false, true);
			updateTimeStamp({ transactionLog, lastSubmittedAt }, true);
		}
		if(isListMode && !listParentId){
			changeMode('')
			updateListMode(false)
		}
	}, []);

	useEffect(() => {
		if (blurActionRef.current || isBlur) {
			handleUpdateTimeStamp();
		}
	}, [isBlur, blurActionRef.current]);

	const handleFocus = () => {
		blurActionRef.current = false;
		setIsBlur(false);
		timeStampRef.current.timeStart = getUTCTimestamp();
	}

	const customHandleOnBlur = () => {
		blurActionRef.current = true;
		setIsBlur(true);
	}

	const validators = getValidators(question, currentSectionAnswerList);
	const size = get(question, 'properties.size.value', '');
	if (validators.autoFormat && !validators.autoFormat.value)
	{
		validators.autoFormat.value = 'MM/DD/YYYY';
	}

	// Determines props for Calendar Popper (visible/hidden)
	const hasCalendarPopper = components.datepicker.showPopper
	const showPopperProps = (hasCalendarPopper) ?
		{} : { showCalendarPopper: false, showCalendarIcon: false } ;
	let calendarIconColor;
	if(hasCalendarPopper)
		calendarIconColor = get(components, 'datepicker.calendarIconColor', '');

	// Checks if guide is enabled or not
	const hasGuide = get(components, 'datepicker.guide', false);

	// overrides floatingLabel to false in case of guide feature enabled
	const cssVariables =  getCSSVariables(theme);
  	const _font = at(cssVariables, ['pageOverride.font', 'global.font']);
	const floatingLabel = hasGuide ? false : labelProps.floatingLabel;
	const themeConfig = get(labelProps, 'themeConfiguration', {});
	// eslint-disable-next-line dot-notation
	const font = _font?.[0]?.['datePicker'] || _font?.[1]?.['datePicker'];

	const dateSelectWrapperProps = {
		questionText,
		reflexiveIndex,
		listParentId,
	}

	const validateInput = (value, questionIdToBeValidated = questionId, inputErValidation, isMrasQ) => {
		validateQuestionInput(value,questionIdToBeValidated,currentSectionQuestionsList, validators, inputErValidation, isMrasQ);
	};


	const formatValue = get(question.validations, 'format.value', validators.responseFormat);
	const isMonthYearFormat = checkMonthYearFormat(formatValue)
	const dateSelectorProps = {
		isKeyStrokeValidation: true,
		iMaskInputPattern: datePatternMap[formatValue],
		placeholderChar: '\u2000',
		dateformatRegex: formatValue === 'MM/YYYY' ? /^(0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/ : /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/,
		isIMaskedRangeEdit: false,
		keyStrokeValidationErrorMessage: 'Please enter a valid input',
		updateOnBlur: true,
		customIMaskBlocks: {
			MM: {
				placeholderChar: '\u2000',
				enum: Array.from({ length: 12 }, (_, i) => i + 1).map(num => String(num).padStart(2, '0')),
			},
			DD: {
				placeholderChar: '\u2000',
				enum: Array.from({ length: 31 }, (_, i) => i + 1).map(num => String(num).padStart(2, '0')),
			},
		},
	}

	const commonProps = {
		labelText: questionText,
		id: questionId,
		response,
		color: colorScheme,
		floatingLabel,
		width: getWidth('datepicker', pageId, pageOverrides),
		guide: hasCalendarPopper ? hasGuide : null,
		yearPlaceholder: !hasCalendarPopper ? 'YYYY' : null,
		monthPlaceholder: !hasCalendarPopper ? 'MM' : null,
		dayPlaceholder: !hasCalendarPopper ? 'DD' : null,
		questionStatus,
		...labelProps,
		...tooltipProps,
		...validators,
	}
	const isAsteriskConfig = get(
		theme,
		'config.theme.components.inputAsteriskRequired',
		);
	let isAsterisk;
	if(isAsteriskConfig && question.validations.required && question.validations.required.value === true){
	isAsterisk = true;
	}
	if(isHidden) return null;
	return ( 
		hasCalendarPopper ? 
		<DateSelectWrapper ref={ timeStampRef } id={ `date-picker-wrapper-${questionId}` } onBlur={ customHandleOnBlur } { ...dateSelectWrapperProps }>
			<DateSelector
				inputRef={ (el) => {
					setState({
						...state,
						inputRef: el,
					});
					inputRefTwo.current = el
				} }
				labelText={ `${questionText} ${optionalText}` }
				id={ questionId }
				onChange={ (value, error, isPopperClick) => {
					let _isPopperClick = false
					if(isMonthYearFormat && !isPopperClick) _isPopperClick = true
					if(!isMonthYearFormat && isPopperClick) _isPopperClick = true
          if (
						typeof isPopperClick === 'undefined' &&
						!reflexiveQFlag &&
						!childQs
					  )
						handleUpdateTimeStamp();
					handleChange(value, error, true, true, undefined, errorObjRef, _isPopperClick)
				} }
				onValueChangeRaw={ (value) => {
					if(value === '')
						setIsDateCleared(true)
					else
						setIsDateCleared(false)
					setState({
						...state,
						isEdited: true,
					})
					const format= get(question,'validations.format.value','MM/DD/YYYY');
					if(value.length === format.length) handleChange(value, false, true, true,value)
				} }
				onFocus={ handleFocus }
				guide={ hasGuide }
				response={ response }
				color={ colorScheme }
				onBlur={ (value, error) => {
					handleBlur(value, state.isEdited, error, isDateCleared)
					if(isMrasQ) validateInput(value, undefined, error?.error, isMrasQ);
				} }
				{ ...showPopperProps }
				{ ...labelProps }
				{ ...validators }
				{ ...tooltipProps }
				width={ (screenViewport === 'mobile') ? 'M' : getWidth('datepicker', pageId, pageOverrides, size) }
				floatingLabel={ floatingLabel }
				forceTriggerValidation={ !childQuestions && !!response }
				autoComplete = { false }
				placeholder = ""
				themeConfiguration={ { ...themeConfig,font,calendarIconColor } }
				errorMessageColor = { errorMessageColor }
				isAsterisk={ isAsterisk }
				{ ...dateSelectorProps }
			/>
		</DateSelectWrapper>
		 : 
		<div
			onFocus={ handleFocus }
			ref={ timeStampRef }
			onBlur={ (e) => {
				handleUpdateTimeStamp();
				handleBlur(e, true, {}, true);
			} }>
			<DateInput
				{ ...commonProps }
				onChange={ (value, error) => {
					handleChange(value, error, true);
				} }
				errorMessageColor={ errorMessageColor }
			/>
		</div>
	)
};

DatePicker.defaultProps = {
	sectionIndex: -1,
};
DatePicker.propTypes = {
	/* eslint-disable react/forbid-prop-types */
	question: PropTypes.object.isRequired,
	/* eslint-disable react/no-unused-prop-types */
	sectionIndex: PropTypes.number,
	handleChange: PropTypes.func.isRequired,
};
const mapStateToProps = (state, props) =>{
	const { meta, questions } = state;
	const { screenViewport } = meta;
	const { activeSubsectionId, sections, lastFocusedElementId } = questions;
	let currentSectionAnswerList = [];
	let currentSectionQuestionsList = [];
	if (sections) {
		let currentSection = [];
		const { sectionIndex } = props;
		if (activeSubsectionId && isNumber(sectionIndex) && sectionIndex >= 0 ) {
			currentSection = sections[activeSubsectionId][sectionIndex];
		}
		if (currentSection && currentSection.answersArray) {
			currentSectionAnswerList = currentSection.answersArray;
		}

		currentSectionQuestionsList = currentSection?.questionList || [];
	}
    return{
		screenViewport,
		lastFocusedElementId,
		currentSectionAnswerList,
		meta: state.meta,
		isListMode: state?.questions?.listMode,
		currentSectionQuestionsList,
	}
}
const mapDispatchToProps = (dispatch, props) => {
	// question_status='absent' means the question is optional and for date input
	// if the date is null we don't consider it as error if DOB is an optional question
	// and there is no error in the date entered. This case is seen in the beneficiary
	// section of Nationwide where DOB is optional and date can be null
	const questionId = get(props, 'question.question_id', '');
	const questionStatus = get(props, 'question.question_status', '');
	const transactionLog = get(props, 'question.transaction_log', []);
	let error;
	const components = get(props, 'theme.config.theme.components', {});
	const hasCalPopper = components?.datepicker?.showPopper;
	const questionType = get(props, 'question.question_type', '');

	return {
		handleChange: (
			response,
			hasError,
			hasEdit = false,
			preventReflexiveCall,
			rawValue = '',
			errorObjRef,
			isPopperClick,
		) => {
			const questionResponse = get(props, 'question.response', '');
			const childQuestions = get(props, 'question.child_questions', false);
			error = hasError.error;
			if (questionStatus === 'absent' && !(response instanceof Date) && hasError.error) {
				error = false
			}
			if(hasError && questionResponse)
				errorObjRef.current = hasError

			const questionId = get(props, 'question.question_id', '');
			const auditRequired = get(props, 'question.audit_required', false);
			const format= get(props,'question.validations.format.value','MM/DD/YYYY');
			let preventReflexiveCallModified= (rawValue?.length===format.length && hasEdit)?false:preventReflexiveCall;
			// this is to avoid unnecessary reflexive calls on same value on rawInput where as while using popper, if user chooses to leave default date value after year/month selection,
			// then api call should be triggered on calendar click away
            if(questionResponse === response && !hasCalPopper) preventReflexiveCallModified = true;

			if(isPopperClick && childQuestions) preventReflexiveCallModified=false
			dispatch(
				updateAnswersArray({
					questionId,
					response,
					hasEdit,
					sectionIndex: props.sectionIndex,
					preventReflexiveCall: hasCalPopper ? preventReflexiveCallModified : true,
					propQuestion: props.question,
					isError: error,
					listParentId: props.listParentId,
					listIndex: props.listIndex,
					auditRequired,
					ignoreChildQuestionsOn: true,
					questionType,
					transactionLog,
					// validate: get(props, 'question.dob_valid_flag', false),
				}),
			);
		},
		handleBlur: (response, isEdited, hasError, isDateCleared) => {
			const auditRequired = get(props, 'question.audit_required', false);
			const childQuestions = get(props, 'question.child_questions', false);
			const _isMrasQ = get(props, 'question.is_mras_question', false);
			const questionResponse = get(props, 'question.response', '');
			let preventReflexiveCallOnBlur = !hasCalPopper ? isEdited : !(childQuestions && questionResponse !== response)
			if(!hasError.error && _isMrasQ)
				preventReflexiveCallOnBlur = false;
			dispatch(
				updateAnswersArray({
					questionId,
					response,
					// preventReflexiveCall: !hasCalPopper ? isEdited : true,
					preventReflexiveCall: preventReflexiveCallOnBlur,
					sectionIndex: props.sectionIndex,
					isError: hasError && hasError.error,
					validate: get(props, 'question.dob_valid_flag', false) && isEdited,
					auditRequired,
					ignoreChildQuestionsOn: true,
					questionType,
					isDateCleared,
					transactionLog,
				}),
			);
		},
		updateTimeStamp: (
			timeStampData,
			isPrefilled=false,
		  ) => {
			  dispatch(updateQuestionAnswerTimeStamp(questionId, timeStampData, props.sectionIndex, isPrefilled))
    },
		updateListMode: (mode) => {
			dispatch({
			  type: SET_LIST_MODE,
			  index: mode,
			})
		},
	};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(DatePicker));
