import get from 'lodash/get';
import { getQuestionStatus } from '../actions/listQuestionHelper';

export const test = '';

export const getContinueState = (
	state,
	sectionIndex,
	currentBreadcrumbId,
) => {
	const { questions } = state;
	const currentSection = questions.sections[currentBreadcrumbId];
	if (currentSection && currentSection.length) {
		const currentQuestions = currentSection[sectionIndex];
		const { enableContinue, completed } = currentQuestions;
		return {
			enableContinue,
			completed,
		};
	}
	return {
		enableContinue: false,
		completed: false,
	};
};

export const getPopupQuestions = (state) => {
	const { questions } = state;
	return get(questions, 'popupQuestions', []);
}

export const getEnableAdd = (
	state,
	sectionIndex,
	currentBreadcrumbId,
	questionIds,
	isEnableListUpdate,
	hiddenListQIds,
) => {
	const { questions } = state;
	const currentSection = questions.sections[currentBreadcrumbId];
	if(!currentSection) {
		return false;
	}
	const currentQuestions = currentSection[sectionIndex];
	const { answersArray, errorsArray } = currentQuestions
	let enableAdd;
	const listErrorsArray = errorsArray;

	/** Required List Qs filter removed */
	// if(questionIds?.length)
	// 	listErrorsArray = errorsArray.filter(eachErr => questionIds.includes(eachErr?.question_id))

	const relativeQuestions = answersArray.filter(eachField => eachField?.relativeQId && eachField?.answer)

	if(relativeQuestions?.length){
		const answeredRelativeQId = relativeQuestions.map(relQ => relQ.relativeQId)[0]
		const modifiedListQIds = questionIds?.filter(qId => !(answeredRelativeQId.indexOf(qId) > -1))
		enableAdd = modifiedListQIds && !listErrorsArray.length &&
		modifiedListQIds.every(id => answersArray.find(
			ans => ans.question_id === id && (ans.answer || ans.answer === 0)));
		return enableAdd
	}

	if(isEnableListUpdate){
		/*  Removing answerArrayId Check - To be checked later
			const answerArrayIds = answersArray.map(ans => ans.question_id);
			(answerArrayIds.indexOf(qId) > -1)
		*/
		const listQuestionIds = questionIds.filter(qId => !(hiddenListQIds?.indexOf(qId) > -1))
		enableAdd = listQuestionIds && !listErrorsArray.length &&
		listQuestionIds.every(id => answersArray.find(
			ans => ans.question_id === id && (ans.answer || ans.answer === 0)));
		return enableAdd;
	}
		enableAdd = questionIds && !listErrorsArray.length &&
		questionIds.every(id => answersArray.find(
			ans => ans.question_id === id && (ans.answer || ans.answer === 0)));
	return enableAdd;
};

export const getLoaders = (state) => {
  const { questions } = state;
  const { loadingSection, isLoading } = questions;
  return { loadingSection, isLoading };
}

export const getEnableContinueBreadCrumb = (state, currentBreadcrumbId, mode, pageId, sectionIndex, dependantSelectiveFieldIds, isSubListSubmitBtn, listStateContext) => {
  const isListMode = state?.questions?.listMode;

  if(mode === 'listEdit' || mode === 'nestedListEdit' || (isListMode && !isSubListSubmitBtn)){
	  return false;
  }

	const { questions, nList } = state;
	const { requiredSchema, enableAdd } = nList;
	const currentSection = questions.sections[currentBreadcrumbId];
	const isReview = (pageId === 'quotereview' || pageId === 'review');

	const illustrationSearchButton = document.querySelector('div[id*="BH_SearchIllustrationButton"]');

	if(illustrationSearchButton && currentSection?.[sectionIndex]){
		const searchIllusQ = currentSection[sectionIndex]?.questionList?.filter((eachQ) => eachQ?.question_id === 'BH_SearchIllustrationButton')
		if(listStateContext !== undefined && searchIllusQ?.length && !listStateContext?.subListAction?.match(/illustration_search_button_click/i)){
			return false;
		}
	}

	if((!!dependantSelectiveFieldIds?.length || isSubListSubmitBtn) && currentSection[sectionIndex]){
		const answersArray = currentSection[sectionIndex]?.answersArray
		const errorsArray = currentSection[sectionIndex]?.errorsArray
		const selectiveInputFields = answersArray.filter(eachAns => dependantSelectiveFieldIds.includes(eachAns.question_id))

		const selectiveInputFieldIds = selectiveInputFields.map(eachSelectiveField => eachSelectiveField.question_id)
		if(errorsArray.length){
			const selectiveInputFieldErrors = errorsArray.filter(errorElement => selectiveInputFieldIds.includes(errorElement.question_id))
			if(selectiveInputFieldErrors.length > 0)
				return !(selectiveInputFieldErrors.length > 0);
		}
		if(selectiveInputFields.length){
			const isSelectiveInputFieldsAnswered = selectiveInputFields.every(eachField => eachField?.answer !== '')
			return isSelectiveInputFieldsAnswered
		}
		return false;
	}

	const enableContinue = currentSection && currentSection.reduce((acc, section) => {
		const questionList = section?.questionList?.filter(question => ((question?.index && question?.question_type !== 'group') || question?.display_type === 'list'))
		let isList;
		if(questionList && !isReview){
			questionList.forEach((question) => {
				if(question.display_type === 'list' && !question.is_hidden){
					isList = true;
				}
			})
			if(isList && questionList?.length > 3){
				const listError = getQuestionStatus(questionList)
				const listQs = questionList.filter(q => q.display_type === 'list');
				const editableListQ = listQs.filter(q => q.question_id === state.questions.listEditQId)[0];
				if(pageId === 'questions'){
					const isQsGroupAnswered = (section?.answersArray.filter(eachQues => section?.requiredSchema?.includes(eachQues.question_id) && !eachQues?.answer)?.length === 0)
					return !listError && isQsGroupAnswered && !editableListQ;
				}
				return !listError;
			}
		}
		return (acc && section.enableContinue);
	}, true)
	if (requiredSchema.length) {
		return enableAdd && enableContinue
	}
	return enableContinue;
}
