import React, { useState } from 'react';
import styled from 'styled-components';
import AccordionSummary from './AccordionSummary';
import AccordionDetails from './AccordionDetails';

const Card = styled.div`
  border: 1px solid var(--global-bh-light-gray, #e9e9e9);
  width: 100%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  ${({ isAccordionSelected, isAccordionCardsDisabled }) =>
    isAccordionSelected
      ? `
      ${
        !isAccordionCardsDisabled
          ? `
            & {
              outline: 3px solid #0B465A;
              outline-offset: 2px;
            }
          `
          : /** disabled selected accordion hover */
            `
            &:hover {
              outline: 3px solid #0B465A;
              outline-offset: 2px;
            }
         `
      }
    `
      : /** unselected accordion hover */
        `
      &:hover {
        border: 2px solid #0B465A;
      }

    `}
`;

const AccorionCard = ({
  index,
  question,
  handleChange,
  sectionIndex,
  setActiveCardIdx,
  isAccordionSelected,
  accordionSummaryData,
  accordionDetailsData,
  cardIdentifierKeys,
  activeSubsectionId,
}) => {
  const { is_editable: isEditable, presentation_type: presentationType } =
    question;
  const [expanded, setExpanded] = useState(false);

  const currentSection = window.location.pathname.split('/')[1] || '';

  const isAccordionCardsDisabled =
    !isEditable ||
    presentationType === 'review' ||
    currentSection.match(/review/i);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleAccordionClick = (idx) => {
    if (isAccordionCardsDisabled) return;

    setActiveCardIdx(idx);
    handleChange(cardIdentifierKeys);
  };

  const accordionSummaryProps = {
    index,
    expanded,
    sectionIndex,
    cardIdentifierKeys,
    isAccordionSelected,
    accordionSummaryData,
    isAccordionCardsDisabled,
    activeSubsectionId,
    onToggle: () => handleToggle(),
    onAccordionClick: (idx, cardIdentifier) =>
      handleAccordionClick(idx, cardIdentifier),
  };

  const accordionDetailsProps = {
    expanded,
    sectionIndex,
    cardIdentifierKeys,
    accordionDetailsData,
    activeSubsectionId,
  };

  const cardStyleProps = {
    isAccordionSelected,
    isAccordionCardsDisabled,
  };

  return (
    <Card className="accordion-item" { ...cardStyleProps }>
      <AccordionSummary { ...accordionSummaryProps } />
      <AccordionDetails { ...accordionDetailsProps } />
    </Card>
  );
};

export default AccorionCard;
