import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { UPDATE_QUESTION_ANSWER, GET_QUESTIONS_SUCCESS } from '../types'
import HELPER from './helper';

function getListQuestion (payload) {
  const question = get(payload, 'data.questionnarie.questions', null);
  if (question) {
    const flatList = [];
    HELPER.flattenQuestions([question], flatList);
    return flatList.filter(q => q.display_type === 'list' && q.submission_type === 'all')[0];
  }
  return [];
}

function createRequiredSchema (payload) {
  const listQuestion = getListQuestion(payload);
  if (!listQuestion) return [];
  let targetList = [];
  if (listQuestion.questions) { // look for last item in array to determine required schema
    // listQuestion.questions should be an array of array
    if (isArray(listQuestion.questions)) {
      targetList = listQuestion.questions[listQuestion.questions.length - 1];
    } else {
      return [];
    }
  } else {
    targetList = listQuestion.original_questions;
  }
  const flatList = [];
  HELPER.flattenQuestions(targetList, flatList);
  return flatList.reduce((acc, q) => {
    if (q.question_type !== 'group' && get(q, 'validations.required.value', false)) {
      acc.push(q.question_id);
    }
    return acc;
  }, []);
}


// checks for valid answer
function isValidAnswer(response) {
  if (isArray(response) && !response.length) {
    return false;
  }
  if (isPlainObject(response) && !response.id) {
    return false;
  }
  return Boolean(response);
}

function createAnswersArray(answersArray = [], payload) {
  const { questionId, response } = payload;
  const prevAnswer = answersArray.filter(q => q.questionId === questionId)[0];
  if (prevAnswer) {
    return answersArray.reduce((acc, ans) => {
      if (ans && ans.questionId === questionId) {
        acc.push({
          questionId,
          response: isValidAnswer(response),
        })
      } else {
        acc.push(ans);
      }
      return acc;
    }, []);
  }
  return [
    ...answersArray, {
      questionId,
      response: isValidAnswer(response),
    },
  ]
}

function shouldEnableAdd(requiredSchema, answersArray) {
  if (answersArray.filter(q => q.response).length < requiredSchema.length) {
    return false;
  }
  for (let index = 0; index < requiredSchema.length; index += 1) {
    const qId = requiredSchema[index];
    const hasAnswer = answersArray.filter(q => q.questionId.indexOf(qId) > -1 && q.response)[0];
    if (!hasAnswer) {
      return false;
    }
  }
  return true;
}
// ########### REDUCER ###########

const initialState = {
  enableAdd: false,
  requiredSchema: [],
  answersArray: [],
};

export default function nList(state = initialState, action) {
  const { type, payload } = action;
  let answersArray = []
  if (payload) {
    const { questionId } = payload;
    switch(type) {
      case UPDATE_QUESTION_ANSWER:
        if (questionId.match(/_\$ureify_/g)) {
          answersArray = createAnswersArray(state.answersArray, payload);
          return {
            ...state,
            answersArray,
            enableAdd: shouldEnableAdd(state.requiredSchema, answersArray),
          };
        }
        return state;
      case GET_QUESTIONS_SUCCESS:
        return {
          ...state,
          requiredSchema: createRequiredSchema(action.payload),
          answersArray: [],
          enableAdd: false,
        };
      default:
        return state;
    }
  }
  return state;
}
