import styled from 'styled-components';

const Cover =  styled.div.attrs(()=>({
  id: 'loading-wrapper',
}))`
  background: rgba(255, 255, 255, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
	z-index: 9998;
  `;

export default Cover;