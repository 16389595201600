import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import { getSections, getCurrentBreadcrumbs } from './questions';

export const getListProps = createSelector(
  [getSections, getCurrentBreadcrumbs],
  (sections, currentBreadcrumb) => memoize( sectionIndex => {
    if (isEmpty(currentBreadcrumb)) {
      return {}
    }
    const currentSection = sections[currentBreadcrumb.id];
    const listQuestion = currentSection[sectionIndex]
    if (listQuestion) {
      const { isList, listDisplay, hasItems, addButtonText, originalQuestions, questionObj } = listQuestion.list;
      return {
        isList,
        listDisplay,
        hasItems,
        addButtonText,
        originalQuestions,
        questionObj,
      }
    }
    return {}
  }),
)


/**
 * @description This function is supposed to provide the title for accordions.
 * This is limited to insurance and beneficiary. This should ideally come from backend,
 * but at the moment, custom code works fine.
 * @param {Array} listQuestion
 */
export const getListTitle = (listQuestion, isReview) => {
  let title = '';
  try {

    // if List Policy Details (Nested List)
    if(listQuestion[1]?.question_id.match(/NewSalesCharge/i)){
      title = `${listQuestion[0].response} - (${listQuestion[1].response}%)`
    }

    // if insurance question
    if (listQuestion[0].question_id.match(/insurance/i)) {
      title = listQuestion[0].response
    }
    const liabilitytype = listQuestion.filter(q => q.question_id.match(/LiabilityType/i))[0];
    if (liabilitytype) {
      title = `${liabilitytype.response} `
    }
    // if beneficiary or child rider question
    if (listQuestion[0].question_id.match(/beneficiary/i) ||
      listQuestion[0].question_id.match(/childrider/i) || listQuestion[0].question_id.match(/agent/i) ||
      listQuestion[0].question_id.match(/company/i) || listQuestion[0].question_id.match(/physician/i) || listQuestion[1]?.question_id.match(/company/i) || listQuestion[0]) {
      const firstName = listQuestion.filter(q => q.question_id.match(/firstname/i))[0];
      const lastName = listQuestion.filter(q => q.question_id.match(/lastname/i))[0];
      const companyName = listQuestion.filter(q => q.question_id.match(/company/i))[0];
      const name = listQuestion.filter(q => q.question_id.match(/name/i))[0];
      const physicianName = listQuestion.filter(q => q.question_id.match(/physician/i))[0];
      const allocQuestion = listQuestion.filter(q => q.question_id.match(/Percentage/i))[0];
      if (name) {
        title = `${name.response} `
      }
      if(firstName) {
        title = `${firstName.response} `;
      }
      if(lastName) {
        title += `${lastName.response} `;
      }
      if(companyName) {
        title = `${companyName?.response?.label}` || ` ${companyName.response} `;
      }
      if(allocQuestion) {
        title +=  `(${allocQuestion.response}%)`;
      }
      if(physicianName){
        title = `${physicianName.response} `;
      }
    }
    if (!title && listQuestion[0]?.response?.label) {
      title = listQuestion[0]?.response?.label
    }
    if(!title || title === "undefined"){
      const questionTypes = ['text', 'number', 'text_area', 'date', 'singleselection', 'multiselection'];
      const questionTypeRegex = new RegExp(`^(${questionTypes.join('|')})`, 'i');
      const firstAnsweredListQ = listQuestion.filter(q => questionTypeRegex.test(q.question_type))[0];
      if (typeof firstAnsweredListQ?.response === 'string')
        title = firstAnsweredListQ.response;
      if (typeof firstAnsweredListQ?.response === 'object'){
        if(firstAnsweredListQ?.response?.label)
          title = firstAnsweredListQ.response.label
      }
    }
    if (!title && isReview) {
      const firstResponse = listQuestion[0].response
      if (typeof firstResponse === 'string')
        title = firstResponse;
    }
    if (listQuestion[1]?.question_id.match(/BH_PrimaryCarePhysicianName/i)) {
      title = listQuestion[1].response
    }
  } catch (e) {
    // error.
    title = ''
  }
  return title;
}
