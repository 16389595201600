/* eslint-disable prefer-const */
import get from 'lodash/get';
import moment from 'moment';
import { getUTCTimestamp } from '../../utils';

const shape = {
  radio_button: 'oval',
  radio: 'classic',
  cards: 'card',
  product_card: 'product_card',
  default_radio:'defaultRadio',
};

const getShape = ({ questionId, singleSelect, displayType }) => {
  const { shapeOverride, plansPage } = singleSelect;
  const field = Object.keys(shapeOverride).find(key => questionId.search(key) !== -1)
  if(shapeOverride[field]) {
    return shapeOverride[field]
  }
  return {
    shape: shape[displayType],
    overlap: false,
    labelWidth: (plansPage && plansPage.labelWidth) || null,
  };
};

const getWidth = (component, pageId, pageOverrides, size = '') => {
  if ( size ){
    return size;
  }
  if (pageOverrides[pageId] && pageOverrides[pageId][component]) {
    return pageOverrides[pageId][component].width;
  }
  return 'XXL';
};

const shouldShowPageInfo = (pageId, pageOverrides) => {
  if (pageOverrides[pageId]) {
    return pageOverrides[pageId].showPageInfo;
  }
  return false;
}

const getLabelProps = (
  componentType,
  componentProperties,
  pageId,
  pageOverrides,
) => {
  if (pageOverrides[pageId] && pageOverrides[pageId][componentType]) {
    if(pageId==='quotereview' && componentType==='button' && window.innerWidth < 768){
      return {
        ...pageOverrides[pageId][componentType],
        width:'100%',
      };
    }
    return {
      ...pageOverrides[pageId][componentType],
    };
  }
  return {
    ...componentProperties[componentType],
  };
};

const getTooltipProps = (tooltipTitle, tooltipBody, tooltipHTML) => {
  if (tooltipTitle && tooltipBody) {
    return {
      hasTooltip: true,
      tooltipTitle,
      tooltipBody,
    }
  }
  if (tooltipTitle) {
    return {
      hasTooltip: true,
      tooltipTitle,
      tooltipBody: '',
    }
  }
  if (tooltipBody) {
    return {
      hasTooltip: true,
      tooltipTitle: '',
      tooltipBody,
    }
  }
  if (tooltipHTML) {
    return {
      hasTooltip: true,
      tooltipHTML,
    }
  }
  return {};
}

/** 
 * @description Gets CSS Variables defined in config file.
 * @requires theme Config based theme object
 * 
 * @returns global - Returns the global CSS variables defined.
 * @returns pageOverride - Returns the page specified CSS variables defined.
 */
const getCSSVariables = (theme) => {
  const screen = get(theme, 'screenViewport', 'desktop');

  const cssVariables = get(theme, `config.theme.cssVars.${screen}`, {});
  const pageId = get(theme, 'pageId', '');
  
  const global = {};
  const pageOverride = {};
  // Iterating through CSS Variables object.
  Object.keys(cssVariables).forEach((key) => {
    const value = cssVariables[key];

    global[key] = value.global || {};
    if (pageId) {
      pageOverride[key] = value[pageId] || {};
    }
  })
  return {
    global,
    pageOverride,
  };
}

// This utility trims forward and backward slashes from the pattern value.
const trimSlashes = (str) => {
  let actualPattern = str;
  if (actualPattern.slice(-1) === '/' && actualPattern.slice(0, 1) === '/') {
    actualPattern = actualPattern.slice(1, actualPattern.length - 1);
  }
  return actualPattern;
};

// Get Question response by id
const getQuestionResponseByID = (answerArray, id) => {
  for(let i = 0 ; i < answerArray.length; i += 1){
    if(answerArray[i].question_id === id){
      return answerArray[i].answer;
    }
  }
  return '';
}

const formatDate = (date, formatValue = 'MM/DD/YYYY') => {
  let _date = date;
  if (!_date) return false;

  if (!moment(date, formatValue, true).isValid())
    _date = moment(date).format(formatValue)

  _date = new Date(moment(_date, formatValue))

  return _date
}

const getValidators = (question, currentSectionAnswerList) => {

  const {
    validations,
    properties,
    is_editable: isEditable,
    is_readonly: isReadOnly,
    display_type: displayType,
    question_text: questionText,
    question_status: questionStatus,
    question_status_message: questionStatusMessage,
    response,
  } = question;

  const {
    auto_format: autoFormat,
    pattern,
    metric,
    step,
    mask,
    min_value: minValue,
    max_value: maxValue,
    min_length: minLength,
    max_length: maxLength,
    precision,
    placeholder_text: placeholderText,
    phone_number_validation:phoneNumberValidation,
    custom_validations : customValidations,
    must_match: mustMatch,
  } = validations;

  let showCustomError = false;
  // Calendar Popper Properties
  let popperMinValue;
  let popperMaxValue;
  if (properties && properties.range) {
    const { range } = properties;
    popperMinValue = range.min;
    popperMaxValue = range.max;
  }

  const disabled = !isEditable || isReadOnly;
  let required = get(validations, 'required', {
    value: false,
    error_message: '',
  })

  const relativeCheck = get(validations, 'relative', undefined);
  if(!response && relativeCheck) {
    const compareQuestionAnswer = getQuestionResponseByID(currentSectionAnswerList, relativeCheck?.questionId);
    if(relativeCheck?.compare === 'either'){
      if(compareQuestionAnswer) required = false;
    }
  }

  let customError = {};

  let placeholder = placeholderText && placeholderText.hide ? '' : questionText.replace(/(<([^>]+)>)/gi, '');
  if (placeholderText && placeholderText.value) {
    placeholder = placeholderText.value;
  }

  let responseFormat;
  if (autoFormat && autoFormat.value) {
    responseFormat = autoFormat.value.toUpperCase();
    autoFormat.value = autoFormat.value.toUpperCase();
  }

  let updatedAutoFormat = autoFormat;
  if (autoFormat && autoFormat.type === 'currency') {
    updatedAutoFormat = {
      format: 'en-US',
      preserveFormattingInCb: true,
    };
    placeholder = autoFormat.value;
  } else if (autoFormat && autoFormat.value.indexOf('00') > -1) {
    responseFormat = placeholderText.value;
    updatedAutoFormat.value = placeholderText.value;
  }

  let updatedPatternValue;
  if (pattern && pattern.value) {
    updatedPatternValue = {
      ...pattern,
      value: trimSlashes(pattern.value),
    };
  }
  customError = {
    error: questionStatus === 'invalid',
    error_message: questionStatusMessage || validations.error_message,
  }


  if (displayType === 'slider') {
    return {
      maxValue: parseInt(maxValue.value, 10),
      step: parseInt(step.value, 10),
      minValue: parseInt(minValue.value, 10),
      currency: updatedAutoFormat,
    };
  }

 let matchQuestionAnswer;

  if (mustMatch && currentSectionAnswerList.length > 0) {
      const { question_id: questionId } = mustMatch;
      showCustomError= true;
      matchQuestionAnswer = getQuestionResponseByID(currentSectionAnswerList, questionId);
      if (matchQuestionAnswer && response &&  (matchQuestionAnswer !== response)) {
        customError = {
          error: true,
          error_message: mustMatch.error_message,
        }
      }
  }
  let customMinValue = '';
  let customMaxValue = '';


  if (customValidations) {
    const { minValidations, maxValidations } = customValidations
    .reduce((acc, validation) => {
      if(validation?.equalTo){
        matchQuestionAnswer = validation.equalTo;
        showCustomError = true;
        customError = {
          error: validations.error_message_percentage && response !== matchQuestionAnswer,
          error_message: questionStatusMessage || validations.error_message_percentage,
        }
      }
      const value = getQuestionResponseByID(currentSectionAnswerList, validation.question_id);

      if (!formatDate(value))
        return acc;
      const _response =   {
          value,
          error_message: validation.error_message,
        };
      if (validation.operator === '>')
        return {
          ...acc,
          minValidations : [
            ...acc.minValidations,
            _response,
          ],
        };
      if (validation.operator === '<')
        return {
          ...acc,
          maxValidations : [
            ...acc.maxValidations,
            _response,
          ],
        };
        return acc;
    }, {
      minValidations: [],
      maxValidations: [],
    })
    customMinValue = minValidations.sort((a, b) => { return a.value < b.value }).shift();
    customMaxValue = maxValidations.sort((a, b) => { return a.value > b.value }).shift();
  }

  let customMinDateVal; let customMaxDateVal;
  if (properties && properties?.range_config) {
    const { range_config: rangeConfig } = properties;

    let dayVal; let monthVal; let yearVal; let currentDate = new Date(); let minDate; let maxDate;

    dayVal = currentDate.getDate();
    monthVal = currentDate.getMonth() + 1;
    yearVal = currentDate.getFullYear();

    if(rangeConfig?.min?.metric.includes('day')){
      dayVal = rangeConfig?.min?.value + dayVal; 
      minDate = `${monthVal}/${dayVal}/${yearVal}`
    }

    if(rangeConfig?.max?.metric.includes('year')){
      yearVal = rangeConfig?.max?.value + yearVal;
      maxDate = `${monthVal}/${dayVal}/${yearVal}`
    }

    popperMinValue = minDate;
    popperMaxValue = maxDate;
    customMinDateVal = {
     ...minValue,
     'value': minDate, 
    }
    customMaxDateVal = {
      ...maxValue,
      'value': maxDate, 
     }
  }
  if (validations?.error_message_text) {
    if(validations?.error_message_text?.max?.metric.includes('day')){
      let dayVal = validations?.error_message_text?.max?.value;
      customMaxDateVal = {
        value: moment().format('MM/DD/YYYY'),
        error_message: `Date should be less than ${(moment().add(dayVal,'days')).format('MM/DD/YYYY')}.`,
      }
    }
  }
  return {
    metric,
    pattern: updatedPatternValue,
    maxLength,
    minLength,
    autoFormat: updatedAutoFormat,
    currency: updatedAutoFormat,
    step: step && step.value ? step.value : '',
    mask,
    minValue : customMinDateVal || customMinValue || minValue,
    maxValue : customMaxDateVal || customMaxValue || maxValue,
    precision,
    required,
    disabled,
    placeholder,
    customError,
    responseFormat,
    popperMinValue,
    popperMaxValue,
    phoneNumberValidation,
    mustMatch,
    matchQuestionAnswer,
    showCustomError,
    ...(validations?.is_selective_field_validate && { isSelectiveFieldValidate: question?.question_id?.match(/nipr/i) ? false : validations?.is_selective_field_validate }), 
    ...(properties?.age_config && { ageValidation: properties?.age_config }),
    ...(mustMatch && { validateMustMatchField: true }),
  };
};

const checkMonthYearFormat = (popperFormatValue) => {
  if (!popperFormatValue) return false
  return (
    popperFormatValue === 'MM/YY' ||
    popperFormatValue === 'YY/MM' ||
    popperFormatValue === 'MM/YYYY' ||
    popperFormatValue === 'YYYY/MM'
  )
}


const validateQuestionInput = (value,questionIdToBeValidated ,currentSectionQuestionsList,validators, inputErValidation, isMrasQ) => {
  const { minValue, maxValue, matchQuestionAnswer, pattern } = validators;
  const currentQues = currentSectionQuestionsList.filter(
    (q) => q.question_id === questionIdToBeValidated && q.is_mras_question,
  )[0] || {};
  const unformat = (val) => {
    if(typeof(val) !== 'string') return val; 
    return (val?.replaceAll(',','') || 0 )
  }; 
  currentQues.validAnswer = true;
  if(currentQues?.length){
    if ((maxValue || minValue) && value !== '') {
      if (!value || +(unformat(value)) > +(unformat(maxValue?.value)) || +(unformat(value)) < +(unformat(minValue?.value))) {
        currentQues.validAnswer = false;
      }
    }  else if (matchQuestionAnswer && value !== matchQuestionAnswer) {
      currentQues.validAnswer = false;
    }

    if(value && pattern?.value && !new RegExp(pattern.value).exec(value))
      currentQues.validAnswer=false
  }

  if(isMrasQ){
    currentQues.validAnswer = !inputErValidation
  }

  return currentQues.validAnswer;
}
const getCsvString = ( options ) => {
  const valueArray = [];
  options.forEach(option => option.response && option.response.value && valueArray.push(option.response.value));
  return valueArray.join('; ');
}

const isPresent = ( array, property, value) => {
  let isPresentInArray = false;
   array.forEach(val => {
     if(val[property] === value) {
       isPresentInArray = true
     }
   });
   return isPresentInArray;
}

const getTimeStampData = (ref, transactionLog, meta) => {
  const lastSubmittedAt = getUTCTimestamp();
		const prevTransactionLog = transactionLog || [];
    if (prevTransactionLog.length) {
      /* we dont update the transaction_log array if
        a) (the time_start, time_end) of last object of the 'transaction_log' array is same as (ref.current.timeStart
          and lastSubmittedAt)
        b) the difference between the last time_end and lastSubmittedAt is <= 1 millisecond
        c) some times the lastObj of 'time_start' and 'time_end' is same as ref.current.timeStamp
          for reflexive questions.
      */
      let shouldReturnPrevTime = false;
      const lastObj = prevTransactionLog[prevTransactionLog.length - 1];
      if (
        (new Date(lastSubmittedAt) - new Date(lastObj.time_end)) / 1000 <= 1
      ) {
        shouldReturnPrevTime = true;
      }
      if (!shouldReturnPrevTime && lastObj.time_start === ref.current.timeStart) {
        if (
          lastObj.time_end === lastSubmittedAt ||
          lastObj.time_end === ref.current.timeStart
        ) {
          shouldReturnPrevTime = true;
        }
      }

      if (shouldReturnPrevTime) {
        return {
          transactionLog: prevTransactionLog,
          lastSubmittedAt,
        };
      }
    }
    const { os, userAgent: browser, platform } = meta;
		return  {
			transactionLog: [
			  ...prevTransactionLog,
			  { 
          time_start: ref.current.timeStart, 
          time_end: lastSubmittedAt, 
          os,
          browser, 
          platform,  
        },
			], 
			lastSubmittedAt,
		}
}

const getDataSelectorOptions = (options)=> {
  const dataSelectorOptions = [];
   if(options && Array.isArray(options)){
   options.forEach((option, index) => {
     const selectorOption = { id: index, label: getCsvString(option) };
     if(!isPresent(dataSelectorOptions, 'label', selectorOption.label)){
      dataSelectorOptions.push(selectorOption);
     }
   });
  }
   return dataSelectorOptions;
}

// Get response by id
const getResponse = (id, options) => {
  let response = { value: '' };
  options.forEach(option => {
    if(option.target_question_id === id){
      response = option.response;
    }
  })
  return response;
}

// Maps response options and selector options
const getMappedOptions = ( responseOptions, selectorOptions) => {
  const mappedOptions = []; 
  responseOptions.forEach(responseOption => {
    const options = [];
    selectorOptions.forEach(selectorOption => {
      let currentOption = { target_question_id: selectorOption.id };
      const response = getResponse(selectorOption.id, responseOption);
      currentOption = { ...currentOption, response };
      options.push(currentOption);
    })
    mappedOptions.push(options);
  })
  return mappedOptions;
}

const MODALTYPES = {
  retry: 'RETRY',
  alertTimeout: 'ALERT_TIMEOUT',
  default: '',
}

export {
  getShape,
  getWidth,
  getLabelProps,
  getValidators,
  shouldShowPageInfo,
  getTooltipProps,
  getDataSelectorOptions,
  getMappedOptions,
  getCSSVariables,
  MODALTYPES,
  validateQuestionInput,
  getTimeStampData,
  checkMonthYearFormat,
};
