import React, { useEffect } from 'react';

const VideoEmbedder = ({ questionText, videoUrl }) => {
    
    const videoId = videoUrl && videoUrl.substr(videoUrl.lastIndexOf('/') + 1)

    const onPlayerStateChange = (event) => {
        const videoState = event.data;
        if(videoState === -1){
          global.ProcessMetrics('Video',['Life Made Simple']);
        }
        else if(videoState === 0){
          global.ProcessMetrics('VideoEnded',['Life Made Simple']);
        }
    }

    const loadVideo = () => {
    
        // the Player object is created uniquely based on the id in props
        // Reference - https://bit.ly/3lywJvK
        window.YT.ready(() => {
          // eslint-disable-next-line no-unused-vars
          const player = new window.YT.Player(`youtube-player-${videoId}`, {
            videoId,
            title: questionText,
            height: '350px',
            width: '100%',
            events: {
              onStateChange: onPlayerStateChange,
            },
          })
        })
      };
    
    useEffect(()=> {
        if (!window.YT) { // If not, load the script asynchronously
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
      
            // onYouTubeIframeAPIReady will load the video after the script is loaded
            window.onYouTubeIframeAPIReady = loadVideo;
      
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      
          } else { // If script is already there, load the video directly
            loadVideo();
          }
    })

    return (
          <div id={ `youtube-player-${videoId}` } />
      );
}

export default VideoEmbedder;