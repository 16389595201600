import React, { useEffect , useState } from 'react';
import at from 'lodash/at';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Loader from '../../cruxComponents/Loader';
import { getCSSVariables } from '../../cruxComponents/utils';
import Left from './Left';
import Right from './Right';
import { useDocumentWidth } from '../../utils';
import Cover from '../../Components/StyledComponents/MaskCover/Index';

const preventKeyboard = (e) => {
	if ([9,16, 13, 32, 37, 38, 39, 40].includes(e.keyCode)) {
		/* Arrow Up, down, left, right
			Shift, Tab, Enter, space
		*/
		e.preventDefault();
	  }
}

const Main = styled.main`
	${({ background, layout }) => {
		if (layout === 'single') {
			return `background: linear-gradient(180deg, ${background} 480px, #f7f7f7 480px);`;
		}
		if (layout === 'auto') {
			return 'background: #ffffff';
		}
		return `background: ${background}`;
	}};
	@media (max-width: 767px) {
		${({ background, layout }) => {
			if (layout === 'single') {
				return `background: linear-gradient(180deg, ${background} 480px, #f7f7f7 480px);`;
			}
			if (layout === 'auto') {
				return 'background: #ffffff';
			}
			return 'background: #f8f8f8';
		}};
	}
`;

const MainWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: ${({ screenViewport }) => (screenViewport === 'mobile') ? 'column' : 'row'};
`;

const HeaderThemeStyles = styled.span`
	${({ pageSpecificHeaderTheme }) => pageSpecificHeaderTheme };
	cursor: default;
	@media (max-width: 768px) {
		background-image: none;
	}
`;

const Content = ({ children, layout, loading, theme, leftData, reload }) => {
	const {
		screenViewport,
		activeSubsectionId,
		pageId,
		config: {
			theme: {
				global: {
					background,
					leftSidebarColor,
					headerTheme,
				},
				pageOverrides,
			},
		},
	} = theme;

	const [backgroundSize, setbackgroundSize] = useState();
	const docWidth = useDocumentWidth();
	const configHeight = 800;
	const configWidth = 1440;
	const { singleLayoutLinearGradient } = pageOverrides[pageId] || {};

	 useEffect(() => {
		 setbackgroundSize(docWidth > configWidth
			? `${docWidth}px ${
				(docWidth / configWidth) * configHeight
				}px`
			: headerTheme?.['background-size'])
	 }, [docWidth])
	 
	 useEffect(() => {
		 if (loading) {
			document.addEventListener('keydown', preventKeyboard);
		 } else{
			 document.removeEventListener('keydown', preventKeyboard);
		 }
	 }, [loading])

	 useEffect(() => {
		return () => {
			document.removeEventListener('keydown', preventKeyboard);
		}
	 }, [])

	// CSS Variables
	const cssVariables =  getCSSVariables(theme);
	const height = at(cssVariables, ['pageOverride.height.content-main', 'global.height.content-main']);
	
	let pageSpecificHeaderTheme;
	if (pageOverrides[pageId] && pageOverrides[pageId].headerTheme)
		pageSpecificHeaderTheme = pageOverrides[pageId].headerTheme;

	const _headerTheme = { ...headerTheme, height: height[0] || height[1] , 'background-size': backgroundSize };

	if (layout === 'auto') {
		return (
			<Main
				data-name="main"
				leftSidebarColor={ leftSidebarColor }
				layout={ layout }
				background={ singleLayoutLinearGradient ||  background }>
				{children}
			</Main>
		);
	}

	const renderPage = () => {
		return (
		  <MainWrapper screenViewport={ screenViewport } data-name="main">
			{loading && <Cover />}
			<Left
			  leftSidebarColor={ leftSidebarColor }
			  layout={ layout }
			  screenViewport={ screenViewport }
			  leftData={ leftData }
			/>
			<Right
			  screenViewport={ screenViewport }
			  content={ children }
			  layout={ layout }
			  leftData={ leftData }
			  pageId={ pageId }
			/>
		  </MainWrapper>
		)
	}

	const skipPageLoader =
		(layout === 'double' ||
		activeSubsectionId !== '' )
		&& !reload
 	return (
		<Main
			data-name="main"
			layout={ layout }
			background={ singleLayoutLinearGradient || background }
		>
			{ pageSpecificHeaderTheme && <HeaderThemeStyles tabIndex= '-1' pageSpecificHeaderTheme={ pageSpecificHeaderTheme } /> }
			{ headerTheme && <div style={ _headerTheme } /> }
			{ loading && !skipPageLoader ? <Loader /> : renderPage() } 
		</Main>
	);
};

Content.defaultProps = {
	loading: false,
	leftData: [],
	layout: 'single',
};

Content.propTypes = {
	leftData: PropTypes.array,
	layout: PropTypes.string,
	children: PropTypes.node.isRequired,
	loading: PropTypes.bool,
};

export default withTheme(Content);
