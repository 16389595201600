/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
// N-List Component
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Button, Tooltip } from 'crux';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import NListQuestions from './NListQuestions';
import TextElement from '../Components/UIElements/TextElement';
import { submitSectionQuestion } from '../redux/actions/questions';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: 20px;
  }
`;

const getRandomId = () => {
  return Math.floor((Math.random() * 99999) + 1);
}

const updateQuestionId = (questions, id = getRandomId()) => {
  if (isArray(questions)) {
    questions.forEach(q => {
      q.question_id = `_$ureify_${q.question_id}_$ureify_${id}`;
      if (q.questions && q.questions.length > 0) {
        updateQuestionId(q.questions, id);
      }
    })
  }
}

const getQuestionsCopy = (questionsCopy) => {
  const qCopy = cloneDeep(questionsCopy);
  updateQuestionId(qCopy);
  return qCopy;
}


// This is a temporary fix, check backend for the solution.
// Problem: when page is reloaded, the backend sends an array of objects in
// questions. But when an item is added, backend sends an array of array of objects.

const getExistingItems = (questions) => {
  if (isArray(questions) && isPlainObject(questions[0])) {
    return [getQuestionsCopy(questions, getRandomId())];
  }
  return questions.map(item => {
    return getQuestionsCopy(item, getRandomId());
  })
}

function NList ({
  listQ,
  theme,
  enableAdd,
  removeListItem,
}) {
  const {
    question_description: questionDescription,
    question_text: questionText,
    original_questions: questionsCopy,
    add_button_text: addButtonText,
    add_button_tooltip: addTooltip,
    remove_button_text: removeButtonText,
    remove_button_tooltip: removeTooltip,
    question_id: questionId,
    presentation_type: presentationType,
    questions,
  } = listQ;

  const [enable, toggleEnable] = useState(enableAdd);
  const [questionsGrid, setQuestionsGrid] = useState({});
  const hasListItems = questions && questions.length > 0
  const existingListItems = hasListItems ? questions : []; 
  const isReview = presentationType === 'review';
  const nListAddButtonTheme = get(theme, 'config.theme.global.nListAddButtonTheme', {});

  /**
   * This useEffect hooks takes care of the list items in the questions.
   * For the first time, when there are no list items, i.e. the questions array
   * is not present, make use of questionsCopy which is the original_questions,
   * and create the questions and display on the screen,
   * 
   * When a list item is answered, a reflexive call is made and the backend returns
   * the selected answer (question object with response filled). In this case, create
   * a list of existing items to be displayed on the screen, as well as any child questions.
   */
  useEffect(() => {
    if (hasListItems) {
      setQuestionsGrid({
        ...questionsGrid,
        [questionId]: [
          ...getExistingItems(existingListItems),
        ],
      })
    } else if(!isReview){
      setQuestionsGrid({
        ...questionsGrid,
        [questionId]: [
          [...getQuestionsCopy(questionsCopy, getRandomId())],
        ],
      })
    } 
  }, []);

  useEffect(() => {
    toggleEnable(enableAdd);
  }, [enableAdd]);

  const handleAdd = () => {
    setQuestionsGrid({
      ...questionsGrid,
      [questionId]: [
        ...questionsGrid[questionId],
        [...getQuestionsCopy(questionsCopy, getRandomId())],
      ],
    })
    toggleEnable(!enable);
  }

  if (isEmpty(questionsGrid)) {
    return null;
  }

  const handleAddButtonEnable = () => {
    return enable && enableAdd && !isReview;
  }

  return (
    <>
      <TextElement text={ questionText } styleType="questions_group" />
      <TextElement text={ questionDescription } styleType="normal" />
      <div>
        <NListQuestions
          list={ questionsGrid }
          removeButtonText={ removeButtonText }
          removeTooltip={ removeTooltip }
          removeItem={ removeListItem }
          isReview={ isReview }
        />
        {
          handleAddButtonEnable() &&
            <ButtonContainer>
              <Button
                label={ addButtonText }
                disabled={ false }
                handleButtonClick={ handleAdd }
                { ...nListAddButtonTheme }
              />
              <Tooltip tooltipHTML={ addTooltip } />
            </ButtonContainer>
        }
      </div>
    </>
  )
}

const mapStateToProps = ({ nList }) => {
  return {
    enableAdd: nList.enableAdd,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeListItem: (key, index) => {
      dispatch(submitSectionQuestion(0, {
        isReflexive: true,
        removeIndex: {
          [key]: index,
        },
      }));
    },
  }
}
export default withTheme(connect(mapStateToProps, mapDispatchToProps)(NList));
