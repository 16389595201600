import styled from 'styled-components';

const FooterBannerWrapper= styled.div`
    height:100px;
    background-color:#00534c;
    padding:16x;
    display:flex;
    justify-content:space-around;
    align-items:center;
`

const Info= styled.div`
    color:#ffffff;
    font-weight:900;
    font-size:20px;

`

const ImgWrapper=styled.div`
    width: 25%;
    height: 100%;
    img {
        height: 100%;
    }
`

export { FooterBannerWrapper, Info,ImgWrapper }