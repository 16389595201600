import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MobileBreadCrumbs from '../../Components/UIElements/Breadcrumb/MobileBreadcrumbs';

const getWidth = (props) => {
  const { screenViewport, layout } = props;
  if (screenViewport === 'mobile') {
    return '90%';
  }
  if (layout === 'single') {
    if (screenViewport === 'tablet') {
      return '100%';
    }
    return '80%'
  }
  return '67%';
}

const RightWrapper = styled.div`
  width: ${(props) => getWidth(props)};
  margin: 20px auto 0px auto;
  z-index: 99;
  ${(props) => props.pageId !== 'review' && 'background: #f8f8f8' };
  padding-bottom: 120px;
  @media (max-width: 480px) {
    z-index: 99;
  }
  margin-bottom: ${({ screenViewport }) => (screenViewport === 'mobile') ? '20px' : '120px'}
  //  https://github.com/philipwalton/flexbugs/issues/157
  @media screen and (-ms-high-contrast: active) {
    margin: 20px auto 120px 0px;
  }
  @media screen and (-ms-high-contrast: none) { 
    margin: 20px auto 120px 0px;
  }
`;

const Right = ({
  screenViewport,
  content,
  layout,
  leftData,
  pageId,
}) => {
  if ((screenViewport === 'mobile') && layout === 'double' && !leftData.length) {
    return (
      <MobileBreadCrumbs>
        <RightWrapper data-name="right" screenViewport={ screenViewport }>
          { content }
        </RightWrapper>
      </MobileBreadCrumbs>
    )
  }
  return (
    <RightWrapper
      data-name="right"
      screenViewport={ screenViewport }
      layout={ layout }
	  pageId={ pageId }
    >
      { content }
    </RightWrapper>
  )
};

Right.defaultProps = {};

Right.propTypes = {
  screenViewport: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  layout: PropTypes.string.isRequired,
};

export default Right;
