import React, { useEffect, Fragment } from 'react';
import get from 'lodash/get';
import GetElementFunc from './GetElement';

function CreateScript(props){
    const { key, item, sectionIndex, required } = props;
    const sourceUrl = get(item, 'script.src', '');
    
    // embed script when questionType is html_template and script sourceUrl is defined
    useEffect(()=>{
        if(sourceUrl){
            const script = document.createElement('script');
            script.src = sourceUrl;
            script.async = true;
            document.body.appendChild(script);
            setTimeout(() => {
                const event = document.createEvent('Event');
                event.initEvent('load', true, true);
                window.dispatchEvent(event);
            }, 1000) 
        }
    }, [sourceUrl])
    
    return (
        <Fragment key={ key } >
            <GetElementFunc
                questionObj={ item }
                sectionIndex={ sectionIndex }
                required={ required }
                isEditMode={ false }
                listParentId= ''
                listIndex={ null }
			/>
        </Fragment>
    );
}

export default CreateScript;