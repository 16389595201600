/* eslint-disable no-continue */
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { getUTCTimestamp } from '../../utils';

const HELPER = {
	LABEL_QUESTION_DISPLAY_TYPES: [
		'list',
		'questions_group',
		'breadcrumb',
	],
	getRandomId: () => {
		return Math.random().toString(36).substring(7);
	},
	extractAnswer: (answer, currentAnswer = []) => {
		if (typeof answer !== 'object') {
			return answer;
		}
		if (isPlainObject(answer) && answer.type === 'array') {
			let _currentAnswer = currentAnswer;
			if (_currentAnswer && !Array.isArray(_currentAnswer)) {
				_currentAnswer = [_currentAnswer];
			}
			if (answer.checked) {
				let currentAnsweredArray = [..._currentAnswer, answer.id];
				currentAnsweredArray = [...new Set(currentAnsweredArray)];
				return currentAnsweredArray;
			}
			return _currentAnswer.filter((a) => a !== answer.id);
		}
		if (typeof answer === 'object') {
			return answer;
		}
		return null;
	},
	/**
	 *
	 */
	updateResetAnswer: (obj, currentAnswer) => {
		const { reset, answer } = obj;
		const isReset = reset.filter(item => item.id === answer.id)[0];
		let _currentAnswer = currentAnswer;
		if (_currentAnswer && !Array.isArray(_currentAnswer)) {
			_currentAnswer = [_currentAnswer];
		}
		if (answer.checked) {
			if (isReset) {
				return [answer.id];
			}
			if (_currentAnswer.length) {
				return [
					..._currentAnswer.filter(a => {
						return reset.filter(item => item.id !== a).length;
					}),
					answer.id,
				];
			}
			return [
				answer.id,
			]
		}
		return _currentAnswer.filter(a => a !== answer.id);
	},

	addOrReplace: (list = [], obj, key) => {
		let matchFound = false;
		let newList = [];
		newList = list.map((item) => {
			if (item.question_id === obj.question_id) {
				matchFound = true;
				let resp = {
					...item,
					question_id: obj.question_id,
					hasEdit: obj.hasEdit || false,
					hasMask: obj.hasMask || false,
					[key]: obj.reset ? HELPER.updateResetAnswer(obj, item.answer) : HELPER.extractAnswer(obj[key], item.answer),
				}
				if(!isEmpty(obj.transactionLog) && key === 'transaction_log') {
					const timeStampData = obj.transactionLog;
					const numTimesAnswered = timeStampData.length; 
					resp = {
						...resp,
						transaction_log: timeStampData,
						num_times_answered: numTimesAnswered,
						last_submitted_at: obj.lastSubmittedAt,
					}
				} else if(key !== 'transaction_log') {
					resp = {
						...resp,
						[key]: obj.reset ? HELPER.updateResetAnswer(obj, item.answer) : HELPER.extractAnswer(obj[key], item.answer),
					}
				}
				if (obj.parentId) {
					resp = {
						...resp,
						parentId: obj.parentId,
					}
				}
				if (obj.immediateParent) {
					resp = {
						...resp,
						immediateParent: obj.immediateParent,
					}
				}
				// Parent List Question Id - N List
				if (obj.parentListQuestionId) {
					resp = {
						...resp,
						parentListQuestionId: obj.parentListQuestionId,
					}
				}
				// Parent List Question Id - Normal List
				if (obj.listParentId) {
					resp = {
						...resp,
						listParentId: obj.listParentId,
					}
				}
				// Normal List Index
				if (Number.isInteger(obj.listIndex) && obj.listIndex >= 0) {
					resp = {
						...resp,
						listIndex: obj.listIndex,
					}
				}
				if(obj.isSelectiveFieldValidate){
					resp = {
						...resp,
						isSelectiveFieldValidate: obj.isSelectiveFieldValidate,
					}
				}
				if(obj.relativeQId){
					resp = {
						...resp,
						relativeQId: obj.relativeQId,
					}
				}
				return resp; 
			}
			return item;
		});
		if (!matchFound) {
			const isArrayType = get(obj, 'answer.type', '') === 'array';
			if (obj.question_id && isArrayType) {
				const _answer = get(obj, 'answer.id', '');
				newList.push({ ...obj, answer: [_answer] });
			} else {
				newList.push(obj);
			}
		}
		return newList;
	},

  /**
   * 
   */
  getRequiredSchema: (list, isRelative) => {
	let relativeQId;
    return list.reduce((acc, question) => {
	  const isHidden = get(question, 'is_hidden', false);
	  const isRelativeQ = question?.validations?.relative
      if (HELPER.LABEL_QUESTION_DISPLAY_TYPES.indexOf(question.display_type) > -1) {
        return acc;
      }
	  if(!isHidden && isRelativeQ && (question.response === '' || ((question.question_id === relativeQId) && question.response === ''))){
		acc.push(question.question_id);
		relativeQId = question?.validations?.relative?.questionId
	  }
      if (get(question, 'validations.required.value', false) && !isHidden) {
		if(isRelative && isRelativeQ && question.response === ''){
			return acc;
		}
			acc.push(question.question_id);
		
      }
      return acc;
    }, []);
  },

  /**
   * 
   */
  isDisplayTypeQuestion: (question) => {
    return question.question_type !== 'group';
  },

  /**
   * 
   */
  parseArray: (list, rex = 0)=> {
	const parentList = list;
    for (let index = 0; index < parentList.length; index+=1) {
			const question = parentList[index];
			if (question.submission_type === 'all') continue;
      if (HELPER.isDisplayTypeQuestion(question) && question.questions && question.questions.length) {
        if (question.child_questions) {
          const rIndex = question.reflexive_index === undefined ? 0 : question.reflexive_index
		  parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, reflexive_index: rIndex + 1, question_id: q.question_id.toString() })))
		}else if(question.question_type === 'modal') {
			parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, isModalQuestion: true ,  question_id: q.question_id.toString() })));
		}  else {
          parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, question_id: q.question_id.toString() })));
        }
        question.questions.length = 0;
      }
      if (question.questions && question.questions.length) {
        HELPER.parseArray(question.questions, question.reflexive_index);
      }
      question.reflexive_index = question.reflexive_index || rex;
    }
  },

  /**
   * @description function to extract and insert reflexive questions in the question list.
   * @param {Object} questionObject - the parent (group) question after the breadcrumb.
   */
  insertReflexiveQuestion: (questionObject, hasModal = false) => {
    const questionList = [cloneDeep(questionObject)];
    HELPER.parseArray(questionList)
    const temp = []
    HELPER.flattenQuestions(questionList, temp, 0, hasModal);
    return temp
  },

	/**
	 * @description funtion to flatten the questions.
	 * @param questions question list - array
	 * @param newList array
	 * @param shouldFlatListQ boolean
	 */
	flattenQuestions: (questions, newList, shouldFlatListQ = false, hasModal = false) => {
		if (questions?.length) {
			questions.forEach((question) => {
				newList.push({
					...question,
				});
				if (question.questions && question.submission_type !== 'all' && (shouldFlatListQ || question.display_type !== 'list')) {
					const nextSet = cloneDeep(question.questions);
					if (hasModal) {
						// eslint-disable-next-line no-param-reassign
						question.questions.length = 0;
					}
					HELPER.flattenQuestions(nextSet, newList, shouldFlatListQ, hasModal);
				}
			});
		}
	},
	/**
	 * @description functione extracts the current breadcrumb name and transforms it
	 * to a keyword.
	 * @param {Object} data received from the backend
	 * @returns {String}
	 */
	getCurrentBreadcrumb: (breadcrumbs, id) => {
		const breadcrumb = breadcrumbs.filter((item) => {
			if (id && item.state === 'active') {
				return item.title.replace(/ /g, '').toLowerCase() === id;
			}
			return item.state === 'active';
		})[0];
		if (breadcrumb) {
			return {
				id:
					breadcrumb.breadcrumb_id.toLowerCase() ||
					breadcrumb.title.replace(/ /g, '').toLowerCase(),
				index: breadcrumbs.findIndex(
					(obj) => obj.breadcrumb_id === breadcrumb.breadcrumb_id,
				),
				active: true,
				...breadcrumb,
			};
		}
		return null;
	},

  /**
   * 
   */
  shouldEnableContinue: (requiredSchema=[], allAnswers, allErrors = [], isList=false, listDisplay=false, listError=false, eSign, isListHidden) => {
    let enable = true;
    if (allErrors.length !== 0) {
      return false;
	}
	
	if(listError){
	  return false
	}

	for (let index = 0; index < requiredSchema.length; index += 1) {
		const answeredQuestion = allAnswers.filter(
			(item) =>
				item.question_id === requiredSchema[index] && (item.answer || item.answer === 0),
		);
		if (!answeredQuestion.length) {
			enable = false;
			break;
		}
		
		const { answer } = answeredQuestion[0];
		if(isArray(answer) && !answer.length){
			enable = false;
			break;
		}
	}

    if(isList && !listDisplay && !isListHidden){
      enable = false
    }
	
	if(eSign) {
		const { hasESign, eSignEnable } = eSign;
		if (hasESign) {
			enable = enable && eSignEnable;
		}
	}
	
    return enable;
  },

  isValidAnswer: (answer) => {
		if (typeof answer === 'string' || typeof answer === 'number') {
			return !!answer;
		}
		if (isArray(answer) && answer.length) {
			return true;
		}
		if (isPlainObject(answer) && answer.id) {
			return true;
		}
		return false;
	},

	findAndUpdateTimeStamp: (
		questionList,
		questionId,
		transactionLog,
		lastSubmittedAt,
	) => {
		return questionList.map((question) => {
			if (question.question_id === questionId) {
				const numTimesAnswered = get(question, 'num_times_answered', undefined)
				return {
					...question,
					transaction_log: transactionLog,
					num_times_answered: numTimesAnswered ? (numTimesAnswered + 1) : 1,
					last_submitted_at: lastSubmittedAt,
				}
			}
			return question;
		});
	},

	/**
	 * @description This function inserts new and updates existing audit timestamp values
	 * when auditRequired value is true for the particular questionId.
	 */
	insertAndUpdateTimestamp: (audit = [], questionId, auditRequired = false) => {
		if (auditRequired) {
			const currentUTCTimestamp = getUTCTimestamp();
			const hasAudit = audit.filter(a => a.question_id === questionId);
			if (hasAudit.length) {
				return audit.map((a) => {
					if (a.question_id === questionId) {
						return {
							...a,
							timestamp: currentUTCTimestamp,
						}
					}
					return a;
				})
			}
			return [
				...audit, {
					question_id: questionId,
					timestamp: currentUTCTimestamp,
				},
			]
		}
		return [];
	},

	findAndUpdateResponse: (
		questionList,
		questionId,
		response,
		questionResponse,
		isControlledInput = false,
		questionType,
		isDateCleared,
	) => {
		return questionList.map((question) => {
			if (question.question_id === questionId) {
				return {
					...question,
					isControlledInput,
					response: HELPER.isValidAnswer(questionResponse)
						? questionResponse
						: HELPER.extractAnswer(response, question.response),
					...(questionType === 'date' && isDateCleared) && { isDateCleared }, 
				};
			}
			return question;
		});
	},
    // sort 2 JSON arrays by prop value
	getSortOrder(prop) {    
		return (a, b) => {    
			if (a[prop] > b[prop]) {    
				return 1;    
			} if (a[prop] < b[prop]) {    
				return -1;    
			}    
			return 0;    
		}    
	},  
	
	// returns the answers array of matching id
	getAnswersArray(answersArray, questionId ){
	   return answersArray.filter(question => question.question_id === questionId);
	},

  removeDuplicate: (sectionAllQuestions, currentQuestions) => {
    // check for two layout property for question
	const layout = get(currentQuestions, 'properties.layout', undefined);
	let currentQuestionId;
	if(!currentQuestions.questions) {
		currentQuestionId = currentQuestions.questionId;
	} else {
		currentQuestionId = layout ? currentQuestions.questions[1].question_id :
		currentQuestions.questions[0].question_id; // get the questions_groups question id
	}
    return sectionAllQuestions.reduce((acc, questions) => {
      // check index 2 for first question in the list and for index 1 fro two-column question
      if (questions.questionList[1].question_id === currentQuestionId ||
         (layout && questions.questionList[0].question_id === currentQuestionId)) {
        return acc;
      }
      acc.push({
        ...questions,
        completed: true,
      });
      return acc;
    }, [])
  },

  updateAnswersArray: (answersArray, qList) => {
	  const hiddenQIds = qList?.filter(q => q?.is_hidden)?.map(q => q?.question_id);
	  return answersArray.filter(eachAns => !hiddenQIds.includes(eachAns.question_id))
  },

  replaceSectionQuestion: (oldQuestions, newQuestionsList, index, eSign, currentRoute) => {
    return oldQuestions.map((quesObj, i) => {
      if (i === index) {
       const requiredSchema = HELPER.getRequiredSchema(newQuestionsList)
        return {
          ...quesObj,
		  ...(currentRoute === 'signature' && { answersArray: HELPER.updateAnswersArray(quesObj.answersArray, newQuestionsList) }),
          questionList: newQuestionsList,
          requiredSchema,
          enableContinue: HELPER.shouldEnableContinue(requiredSchema, quesObj.answersArray, undefined, undefined, undefined, undefined, eSign),
        }
      }
      return quesObj;
    })
  },

  	buildAnswersArrayReduceFunc: (Q, arr) => {
		return Q.reduce((acc, q) => {
			if (
				q.display_type === 'list' ||
				q.display_type === 'questions_group' ||
				q.display_type === 'breadcrumb'
			) {
				return acc;
			}
			if (q.question_id) {
				let { response } = q;
				if (isPlainObject(response)) {
					response = response.id;
				}
				if (isArray(response)) {
					response = response.map((r) => r.id);
				}
				// Checks if question contain masked value.
				const mask = !!get(q, 'validations.mask.mask_char', false);
				const isSelectiveFieldValidate = get(q, 'validations.is_selective_field_validate', false);
				acc.push({
					question_id: q.question_id,
					answer: response,
					hasEdit: false,
					hasMask: mask,
					...(isSelectiveFieldValidate && { isSelectiveFieldValidate }),
				});
			}
			return acc;
		}, arr);
	},

	buildAnswersArray: (questionList, originalQ = []) => {
		const answersArray = []
		HELPER.buildAnswersArrayReduceFunc(questionList, answersArray);
		HELPER.buildAnswersArrayReduceFunc(originalQ, answersArray);
		return answersArray
	},

  	getOriginalQuestions: (questionObject) => {
		const listQuestion = questionObject.questions[0];
		if (listQuestion.display_type === 'list') {
			// Adds reflexive questions support for list questions
			// Related Task - https://sureify.atlassian.net/browse/AS-323
			const reflexiveListQ = []
			HELPER.flattenQuestions(listQuestion.original_questions || listQuestion.questions, reflexiveListQ, true)
			return reflexiveListQ
		}
		return [];
	},
	
	getAnsweredLisQuestionIds: (questionObj) => {
		return questionObj
		.filter(question => question.display_type === 'list' && question.child_questions_completed_flag)
		.map(q => q.question_id)
	},
}

export const { getCurrentBreadcrumb } = HELPER;

export default HELPER;