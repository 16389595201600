/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, withTheme } from 'styled-components';
import get from 'lodash/get';
import { useDocumentWidth } from '../../../utils';
import Checklist from '../../../assets/Images/Checklist.png';
import './styles.css'
// Three card sizes are defined in current designs
// S / M / L Card Sizes
export const Size = {
  S: 'Small',
  M: 'Medium',
  L: 'Large',
  B: 'Banner',
};

// Card behaviour is distinguished in three states
export const State = {
  INCOMPLETE: 'incomplete',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};



const ProgressLabel = styled.p`
  font-size: ${(props) => (props.size === Size.S ? '12px' : '14px')};
  color: ${(props) =>
    props.isActive ? props?.activeBreadcrumbTheme?.progressLabelColor : props?.disabledBreadcrumbTheme?.progressLabelColor};
  padding-left: 20px;
  // padding-bottom: 16px;
  @media only screen and (min-width: 768px) and (max-width: 768px) {
    width: max-content;
    padding-left: 2px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding-left: 13px;
    font-size:11px;
  }
  @media only screen and (min-width:1024px) and (max-width:1280px) {
    padding-left: 13px;
    font-size:12px;
  }
`;

const cardStyles = css`
  position: relative;
  ${({ isActive, size }) =>
    (isActive || size === Size.B) &&
    `
    cursor: pointer;
  `};
  ${({ clickable, isActive, isClickableCard }) =>
    ((!isActive && !clickable) || !isClickableCard ) &&
    `
    cursor: not-allowed;
  `};
  ${({ isActive, size }) =>
    isActive &&
    size !== Size.B &&
    `
    box-shadow: 6px 5px 0px 1px rgba(0,0,0,0.2);
  `};
  ${({ isActive, size }) =>
    isActive &&
    size !== Size.L &&
    size !== Size.B &&
    `
    background: rgba(255, 255, 255,1);
  `};
  ${({ isActive, size }) =>
    !isActive &&
    size !== Size.B &&
    `
    display: -ms-grid !important;
  `};
`;

const BreadCrumbCard = styled.div`
  ${cardStyles};
  ${(props) => props.breadcrumbTheme};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ isActive, activeBreadcrumbTheme, disabledBreadcrumbTheme }) => 
    isActive ? activeBreadcrumbTheme.border : disabledBreadcrumbTheme.border};
`;

const LeftSection = styled.div`
  > * + * {
    padding-top: 7px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1280px) {
    > * + * {
      padding-top: 0;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    font-size: ${(props) => props.size === Size.L && '30px'};
    margin: ${(props) => props.size === Size.L && '10px 0 10px 15px'};
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0 0 20px;
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: ${(props) => props.size === Size.L && '30px'};
    margin: ${(props) => props.size === Size.L && '10px 0 10px 15px'};
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const LabelWrapper = styled.div`
  padding: 42px;
  ${({ breadcrumbTitle }) => breadcrumbTitle?.length > 30 && 
    `
      display: table-cell;
      width: 240px;
    `
  }
  ${({ size, breadcrumbTitle }) =>
    size !== Size.L &&
    size !== Size.B &&
    `
    padding-top: 20px;
    padding-right: ${breadcrumbTitle?.length > 30 ? '20px' : '20px'};
    padding-bottom: 5px;
    padding-left: 20px;
    position: relative;
    @media only screen and (min-width:320px) and (max-width:480px) {
      padding:0;
    }
  `}
  @media only screen and (min-width:1024px) and (max-width:1280px) {
    padding: 18px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 13px 13px;
    padding-bottom:0;
    display: table-cell;
    width: 140px;
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    padding: 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 768px) {
    padding-bottom:16px;
    padding-left: 10px;
    display: table-cell;
    width: 130px;
  }
  @media only screen and (min-width: 820px) and (max-width: 820px) {
    padding-left: 10px;
    display: table-cell;
    width: 130px;
  }
`;

const Title = styled.h3`
  && {
    font-family: 'Roboto-Bold';
    font-size: ${(props) => (props.size === Size.M ? '18px' : '18px')};
    font-size: ${(props) => props.size === Size.L && '48px'};
    font-size: ${(props) => props.size === Size.B && '40px'};
    color: ${(props) =>
      props.isActive || props.size === Size.B ? props.activeBreadcrumbTheme.titleColor : props.disabledBreadcrumbTheme.titleColor};
    margin: 0 !important;
    line-height: 1.5;
    position: relative;
    ${({ size }) =>
      size !== Size.L &&
      `
      height: 45px;
      max-height: 45px;
    `}
    @media only screen and (min-width:1024px) and (max-width:1280px) {
      && {
        // font-weight: bold;
        font-size: ${(props) => (props.size === Size.M ? '18px' : '18px')};
        font-size: ${(props) => props.size === Size.L && '48px'};
        font-size: ${(props) => props.size === Size.B && '40px'};
        color: ${(props) =>
          props.isActive ? props.activeBreadcrumbTheme.titleColor : props.disabledBreadcrumbTheme.titleColor};
        margin: 0 !important;
        line-height: 1.3;
        position: relative;
        ${({ size }) =>
          size !== Size.L &&
          `
          height: 45px;
          max-height: 45px;
        `}
      }
    }
    @media only screen and (min-width: 767px) and (max-width: 1024px) {
      && {
        // font-weight: bold;
        font-size: ${(props) => (props.size === Size.M ? '18px' : '16px')};
        font-size: ${(props) => props.size === Size.L && '48px'};
        font-size: ${(props) => props.size === Size.B && '40px'};
        color: ${(props) =>
          props.isActive ? props.activeBreadcrumbTheme.titleColor : props.disabledBreadcrumbTheme.titleColor};
        margin: 0 !important;
        line-height: 1.3;
        position: relative;
        ${({ size }) =>
          size !== Size.L &&
          `
          height: 33px;
          max-height: 36px;
        `}
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 767px) {
      font-size: ${(props) => props.size === Size.L && '30px'};
      font-size: ${(props) => props.size === Size.B && '24px'};
      max-width: 80%;
    }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      font-size: ${(props) => props.size === Size.L && '30px'};
      font-size: ${(props) => props.size === Size.B && '24px'};
      max-width: 80%;
      ${({ size }) =>
        size === Size.B &&
        `
          height: auto;
          max-height: unset;
        `}
    }
    @media only screen and (min-width: 768px) and (max-width: 768px) {
      line-height:1;
    }
  }
`;

const TitleWrapper = styled.span`
  ${({ size , isActive }) =>
    size !== Size.L &&
    size !== Size.B &&
    `
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    @media (min-width: 320px) and (max-width: 480px) {
      top:${ isActive ? '70%':'10%' };
      padding: 0 10px;
      padding-left: 18px;
      width: 100%;
    }
    @media only screen and (min-width: 481px) and (max-width: 767px) {
      top:${ isActive ? '45%':'25%' };
      width: max-content;
    }

    @media only screen and (min-width : 768px) and (max-width : 768px) {
      top:${ isActive ? '40%':'45%' };   
      font-size:14px;     
    }

  `}
`;

const Footer = styled.span`
  font-size: 16px;
  color: rgba(93, 93, 93, 1);
`;

const Line = styled.hr`
  border: 1px solid rgba(216, 216, 216, 1);
  width: 80px;
  margin: 10px 0;
`;

const DisabledDiv = styled.div`
  z-index: -1;
  cursor: ${(props) =>
    props.state === State.INCOMPLETE ? 'not-allowed' : 'default'};
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.background};
  position: absolute;
  border-radius: ${({ borderRadius }) => borderRadius};
`;

const Progess = styled.span`
  color: #0b465a;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px 20px;
  }
`;

const Image = styled.img`
  position: absolute;
  height: 50%;
  right: 25px;
  top: 16px;
  z-index: 99; // to display "inactive" image as per the configured image.
  @media (max-width: 320px) {
    width: 80px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
      top: 10px;
      height: 72%;
      max-width: 13%;
      right: 12px;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    top: 10px;
    height: 50%;
    right: 10px;
    // display: block;
    display:table-cell;
    max-width: 19%;
  }
  @media (min-width: 1281px){
    ${({ showImage })=>(!showImage &&
      `top: 16px;
      height: 40%;
      right: 10px;
      display:table-cell;`
    )}
    max-width: 15%;
  }
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
  opacity: ${({ isActive, activeBreadcrumbTheme, disabledBreadcrumbTheme }) => (isActive ?  activeBreadcrumbTheme.imageOpacity: disabledBreadcrumbTheme.imageOpacity)};
  display: ${({ showImage }) => (!showImage &&  'none')};
  filter: ${({ isActive }) => (isActive ? '' : 'grayscale(100%)')};
`;

const CardWrapper = styled.div`
  name: ${(props) => props.name};
  width: ${({ override }) => (override ? '100%' : '65.22%')};
  margin: ${({ override }) => (override ? '5px 0' : '13px')};
  margin-right: 0;
  @media only screen and (min-width: 1281px)  {
    margin-right: 50px;
  }
  @media (min-width: 768px) and (max-width: 768px) {
    width: 89%;    
	}
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 80%;    
	}
  z-index: 95;
`;

const LeftBorder = styled.div`
  height: auto;
  width: ${({ width }) => width || '8px'};
  background: ${({ colorCode }) => colorCode || 'transparent'};
  z-index: 99;
`

const showSectionProgressLabel = (totalSections, currentSectionNumber) => {
  if (
    typeof totalSections !== 'number' ||
    typeof currentSectionNumber !== 'number'
  ) {
    return false;
  }
  if (typeof totalSections === 'number' && totalSections < 0) {
    return false;
  }
  if (typeof currentSectionNumber === 'number' && currentSectionNumber < 0) {
    return false;
  }
  return true;
};

const Breadcrumb = (props) => {
  const {
    id,
    breadcrumb,
    imgUrl,
    isActive,
    breadcrumbTheme,
    size,
    onClick,
    override,
    leftBorderWidth,
    leftBorderColor,
    isClickableCard = true,
  } = props;

  const breadCrumdRef = useRef();
  const docWidth = useDocumentWidth();

  const [showImage, setShowImage] = useState(true);

  const borderRadius = get(props, 'theme.config.theme.components.progressCard.borderRadius', '9');

  const activeBreadcrumbTheme = get(props, 'theme.config.theme.global.breadcrumb.active', {})
  const disabledBreadcrumbTheme = get(props, 'theme.config.theme.global.breadcrumb.disabled', {})

  const commonProps = {
    breadcrumb,
    onClick,
    isActive,
    imgUrl,
    breadcrumbTheme,
    size,
  };
  const {
    total_sections: totalSections,
    current_section_number: currentSectionNumber,
  } = get(breadcrumb, 'section_progress', 0);

  useEffect(() => {
    setShowImage(breadCrumdRef.current.clientWidth > 249);
}, [docWidth])
  return (
    <CardWrapper
      tabIndex={ (breadcrumb.clickable || breadcrumb.active || isActive) ? '0' : '-1' }
      name='card-wrapper'
      id={ `breadcrumb-${id}` }
      override={ override }
      onKeyPress={ (event) => {
        if ((breadcrumb.clickable || breadcrumb.active) && event.key === 'Enter') {
          onClick(breadcrumb.id);
        }
      } }
    >
      <BreadCrumbCard
        ref={ breadCrumdRef }
        borderRadius={ borderRadius }
        name="breadcrumb-card"
        size={ size }
        theme={ breadcrumbTheme }
        isActive= { isActive }
        activeBreadcrumbTheme={ activeBreadcrumbTheme }
        disabledBreadcrumbTheme= { disabledBreadcrumbTheme }
        clickable=  { breadcrumb.clickable }
        isClickableCard = { isClickableCard }
        { ...commonProps }
        onClick={ () => {
          if (breadcrumb.clickable || breadcrumb.active) {
            onClick(breadcrumb.id);
          }
        } }
      >
        {leftBorderColor && leftBorderWidth && <LeftBorder colorCode={ leftBorderColor } width = { leftBorderWidth }/>}
        <Image showImage={ showImage } borderRadius={ borderRadius } name="image" src={ imgUrl } alt="" isActive={ isActive } className={ `${process.env.REACT_APP_CARRIER_ID}-breadcrumb` } activeBreadcrumbTheme={ activeBreadcrumbTheme } disabledBreadcrumbTheme= { disabledBreadcrumbTheme }/>
        <LeftSection name="left-section" size={ size }>
          {size === Size.S && isActive && (
            <Progess name="progress">
              {
                showSectionProgressLabel(totalSections, currentSectionNumber)
                ? `${currentSectionNumber} of ${totalSections} Groups completed`
                : <p />
              }
            </Progess>
          )}

          <LabelWrapper name="label" size={ size } breadcrumbTitle = { breadcrumb.title }>
            <Title id={ `breadcrumb-title-${id}` } name="title" size={ size } isActive={ isActive } activeBreadcrumbTheme={ activeBreadcrumbTheme } disabledBreadcrumbTheme= { disabledBreadcrumbTheme }>
              <TitleWrapper name="title-wrapper" size={ size } isActive={ isActive }>
                {breadcrumb.title}
              </TitleWrapper>
            </Title>
            {size === Size.L && (
              <Footer name="footer">
                <Line name="line" />
                {
                  showSectionProgressLabel(totalSections, currentSectionNumber)
                  ? `${currentSectionNumber} of ${totalSections} Groups completed`
                  : <p />
                }
              </Footer>
            )}
          </LabelWrapper>
          {!override && (
            <ProgressLabel
              name="progress-label"
              isActive={ isActive }
              activeBreadcrumbTheme={ activeBreadcrumbTheme }
              disabledBreadcrumbTheme= { disabledBreadcrumbTheme }
              size={ size }
            >
              {showSectionProgressLabel(totalSections, currentSectionNumber) ? (
                `${currentSectionNumber} of ${totalSections} Groups completed`
              ) : (
                <p />
              )}
            </ProgressLabel>
          )}
        </LeftSection>
        {!isActive && size !== Size.L && size !== Size.B && (
          <DisabledDiv borderRadius={ borderRadius } name="disabled-div" state={ breadcrumb.state } theme={ disabledBreadcrumbTheme }/>
        )}
      </BreadCrumbCard>
    </CardWrapper>
  );
};

export default withTheme(Breadcrumb);
