import React from 'react';
import get from 'lodash/get';
import at from 'lodash/at';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'crux';
import styled, { withTheme } from 'styled-components';
import TextElement from './index';
import { getNextQuestionSet } from '../../../redux/actions';
import ReviewBreadcrumb from '../../QuestionsLib/ReviewBreadcrumb';
import { getCSSVariables } from '../../../cruxComponents/utils';

const ButtonWrapper = styled.div`
  margin-bottom: 30px;
  & > button {
    padding-right: 0;
  }
`;

const GroupQuestionTextWrapper = styled.div.attrs((props) => ({
  'tabIndex': props.activeSubsectionId=== 'completed' ? '0' : '-1',
}))`
  display: flex;
  justify-content: ${({ questionId, pageId }) => (questionId.match(/CustomerFinal/i) || pageId.match(/completed/i)) ? 'space-around' : 'space-between' };
  ${({ pageId }) => pageId.match(/completed/i) && 'text-align: center;'}
  align-items: flex-start;
  @media (min-width: 320px) and (max-width: 1024px) {
    align-items: center;
  }

  ${({ groupQProps: { grid_display: gridDisplay, grid_positional_area: gridArea, text_align: textAlign } = {} }) => gridDisplay && `
      display: grid;
      grid-template-columns: 1fr 5fr 1fr;
      grid-template-areas: "left-space center-space right-space";
      > h2 {
        grid-area: ${gridArea};
        text-align: ${textAlign};
      } 
  `}
`;

const GroupQuestionText = ({ questionObj, uid, makeAPICall, handlePopup, theme, activeSubsectionId }) => {
  const {
    question_id: questionId,
    question_text: questionText,
    question_type: questionType,
    question_description: questionDescription,
    isBreadcrumbCard,
    is_edit_icon_visible: isEditable,
    /** GroupQuestionText Properties - Completed Route Grid style Configurations */
    properties = {},
  } = questionObj;

  const {
    pageId,
  } = theme;

  // CSS Variables
  const element = '.groupQuestionText';
  const pElement = '.textElement-p';
  const updateButtonElement='.button-update';
  const cssVariables =  getCSSVariables(theme, 'groupQuestionText');
  const font = at(cssVariables, [`pageOverride.font${element}`, `global.font${element}`]);
  const pFont = at(cssVariables, [`pageOverride.font${pElement}`, `global.font${pElement}`]);
  const updateButtonFont = at(cssVariables,[`pageOverride.font${updateButtonElement}`, `global.font${updateButtonElement}`] )
  if (!questionText && !questionDescription) {
    return null;
  }
  if (isBreadcrumbCard) {
    return <ReviewBreadcrumb question={ questionObj } />;
  }

  const updateButtonTheme = get(theme, 'config.theme.global.updateButton.themeConfiguration', {});
  updateButtonTheme.font=updateButtonFont[0] || updateButtonFont[1]
  let displayUpdateButton = questionType === 'group' && isEditable;
  const handleUpdate = () => {
    const params = {
      uid,
      q_id: questionId,
    };
    makeAPICall(params);
  };

  if (!questionText && !questionDescription) {
    return null;
  }
  displayUpdateButton=false;
  return (
    <>
      <GroupQuestionTextWrapper name='group-question-text-wrapper' questionId = { questionId } activeSubsectionId={ activeSubsectionId } groupQProps = { properties } pageId={ pageId }>
        { questionText && <TextElement id={ `group-header-${questionId}` } text={ questionText } styleType="questions_group" font={ font } activeSubsectionId={ activeSubsectionId }/> }
        {displayUpdateButton && (
          <ButtonWrapper name="button-wrapper">
            <Button 
              label="Update" 
              type="text" 
              handleButtonClick={ handleUpdate } 
              ariaLabel={ `Update ${questionText}` } 
              themeConfiguration={ updateButtonTheme }
            />
          </ButtonWrapper>
        )}
      </GroupQuestionTextWrapper>
      {questionDescription && (
        <TextElement text={ questionDescription } styleType="normal" handlePopup={ handlePopup } pFont={ pFont[0] || pFont[1] }/>
      )}
    </>
  );
};

GroupQuestionText.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  questionObj: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    uid: state.uid,
    activeSubsectionId: state?.questions?.activeSubsectionId,
    groupQId: state.questions?.pageType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeAPICall: (params) => {
      dispatch(getNextQuestionSet('/questions', params));
    },
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(GroupQuestionText));
