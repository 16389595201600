import { createSelector } from 'reselect';
import last from 'lodash/last';
import get from 'lodash/get';
import memoize from 'lodash/memoize';

export const getSections = (state) => state.questions.sections;
const getBreadcrumbList = (state) =>
	state.questions.breadcrumbs.breadcrumbList;
export const getCurrentBreadcrumbs = (state) =>
	state.questions.breadcrumbs.currentBreadcrumb;
export const getBreadcrumbHeading = (state) => state.breadcrumbs;
const activeSubsectionId = (state) => state.questions.activeSubsectionId

export const getCurrentQuestionSet = createSelector(
	[getSections, activeSubsectionId],
	(sections, subsectionId) => {
		const currentSection = sections[subsectionId];
		if (currentSection && currentSection.length) {
			return last(currentSection);
		}
		return {};
	},
);

export const getCurrentBreadcrumbsList = createSelector(
	[getBreadcrumbList],
	(breadcrumbList) => breadcrumbList,
);

export const getSectionQuestions = createSelector(
	[getSections, activeSubsectionId],
	(sections, subsectionId) =>
		memoize((props) => {
			return sections[subsectionId][props.sectionIndex]
				.questionList;
		}),
);

export const getSectionHeader = (sectionData) => {
	if (sectionData && sectionData.length) {
		const { questionObj } = sectionData[0];
		return {
			sectionTitle: questionObj.question_text,
			sectionTitleHidden: get(questionObj, 'is_hidden', false),
			sectionSubTitle: questionObj.question_description,
			pageTitle: questionObj.page_title,
		};
	}
	return {
		sectionTitle: '',
		sectionSubTitle: '',
		pageTitle: 'Brighthouse Financial',
	};
};

export const getHaveQuestions = (sectionData, hasBreadcrumbs) => {
	const haveQuestions =!hasBreadcrumbs &&(get(sectionData, '[0].questionObj.have_questions') || get(sectionData, '[0].leftQuestionList[0].have_questions')) ;
	return haveQuestions;
}

export const getErrorDetail = (sectionData) => {
	if (sectionData && sectionData.length) {
		const section = sectionData[sectionData.length - 1];
		const { title, message } = section.errors;
		if (title || message) {
			return {
				hasError: true,
				errorMessage: message,
				errorTitle: title,
			};
		}
	}
	return {
		hasError: false,
	};
};

export const getSectionMemo = createSelector(
	[getSections, activeSubsectionId],
	(sections, subsectionId) =>
		memoize(() => {
			return sections[subsectionId] || [];
		}),
);

export const getBreadCrumbInfo = createSelector(
  [getBreadcrumbList],
  (breadcrumbList) => (id) =>{
      return breadcrumbList && breadcrumbList.length && breadcrumbList.filter(breadcrumb => id === breadcrumb.id)[0]
  },
)
