import React, { useState } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import {
	getSectionMemo,
	getCurrentQuestionSet,
} from '../../redux/selector/questions';
import QuestionLibrary from '../../Components/QuestionsLib/QuestionLibrary';
import Content from '../../layout/content';
import { ListContext } from '../../Components/List/ListContext';

function NewQuestions(props) {
	const { leftData, loading, reload, questionsLayout } = props;
	const {
		theme: {
			pageLayout,
			pageId,
			config: {
				theme: {
					global: {
						background,
					},
				},
			},
		},
	} = props;

	const [listState, setListAction] = useState('');

	const handleListAction = (listAction) => {
		setListAction(listAction)
	}

	return (
		<ListContext.Provider value={ { listState, handleListAction } }>
			<Content
				leftData={ leftData }
				loading={ loading }
				reload={ reload }
				layout={ questionsLayout || pageLayout }
				background={ background }
			>
				<QuestionLibrary
					layout={ questionsLayout || pageLayout }
					page=""
					// pageId={ pageId === 'questions' ? 'q' : pageId }
					pageId={ pageId }
					{ ...props }
				/>
			</Content>
		</ListContext.Provider>
	);
}

function mapStateToProps(state, ownProps) {
	const currentQuestionSet = getCurrentQuestionSet(state);
	const sectionMem = getSectionMemo(state);
	const sectionData = sectionMem(ownProps);
	let leftData;
	for (let i = 0; i < sectionData.length; i += 1) {
		const question = sectionData[i];
		const leftQuestionList = get(question, 'leftQuestionList', []);
		if (leftQuestionList.length > 0) {
			leftData = leftQuestionList;
		}
	}
	return {
		leftData,
		questions:currentQuestionSet && currentQuestionSet.questionList,
		uid: state.uid,
		loading: state.questions.isLoading,
		reload: state.questions.reload,
		questionsLayout: state.questions.layout,
	};
}

export default withTheme(withRouter(
	connect(mapStateToProps, null)(NewQuestions),
));
