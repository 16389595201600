import * as React from 'react';
import { createStore } from '@react-pdf-viewer/core';
import ReadingIndicator from './ReadingIndicator';

const ReadingIndicatorPlugin = () => {
    const store = React.useMemo(() => createStore({}), []);

    const ReadingIndicatorDecorator = () => <ReadingIndicator store={ store } />;

    return {
        install: (pluginFunctions) => {
            store.update('getPagesContainer', pluginFunctions.getPagesContainer);
        },
        ReadingIndicator: ReadingIndicatorDecorator,
    };
};

export default ReadingIndicatorPlugin;