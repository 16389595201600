/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { at } from 'lodash';
import { connect } from 'react-redux';
import { ReactSelect, Select } from 'crux';
import { withTheme } from 'styled-components';
import { axiosInstance } from '../util/axios';
import { updateAnswersArray, updateQuestionAnswerTimeStamp } from '../redux/actions';
import { getLabelProps, getWidth, getTooltipProps, getCSSVariables, getTimeStampData } from './utils';
import { SelectWrapper } from './utils/styles';
import { getUTCTimestamp } from '../utils';
import { SET_LIST_MODE } from '../redux/types';

const SelectOption = ({
	question,
	uid,
	theme,
	handleSelectOption,
	isEditMode,
	type,
	screenViewport,
	lastFocusedElementId,
	selectedOptions,
	loading,
	listParentId,
	updateTimeStamp,
	meta,
	changeMode,
	isListMode,
	updateListMode,
	isDisabled,
	currentSection
}) => {

	const cssVariables =  getCSSVariables(theme);
	const font = at(cssVariables, ['pageOverride.font', 'global.font']);
	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme, colorOverride, reactSelect },
			},
		},
	} = theme;

	const {
		question_id: questionId,
		question_text: questionText,
		question_type: questionType,
		display_type: displayType,
		response_options: responseOptions,
		hint_title: tooltipTitle,
		hint_text: tooltipBody,
		hint_html: tooltipHTML,
		is_editable: isEditable,
		isControlledInput,
		response,
		tooltip,
		validations,
		reflexive_index: reflexiveIndex,
		is_hidden: isHidden,
		transaction_log: transactionLog,
		last_submitted_at: lastSubmittedAt,
		properties,
	} = question;

	const singleResponseOption = responseOptions?.length === 1 && responseOptions[0].id;
	// Setting the initial response for the field
	useEffect(() => {
		if(question.response || singleResponseOption) {
			handleSelectOption(question.response || responseOptions[0], true, undefined, undefined, question);
			updateTimeStamp({ transactionLog, lastSubmittedAt }, true)
		}
		if(isEditMode && question.response){
			handleSelectOption(question.response, true, undefined, undefined, question);
      updateTimeStamp({ transactionLog, lastSubmittedAt }, true)
		}
		if(isListMode && !listParentId){
			changeMode('')
			updateListMode(false)
		}
	}, [])

	// Tab Navigation
	const inputRef = useRef();
	const timeStampRef = useRef(null);

	useEffect(() => {
	const shouldFocus = lastFocusedElementId === questionId
	if (inputRef && inputRef.current && shouldFocus) {
		if (typeof inputRef.current.focus === 'function') {
			inputRef.current.focus();
			setTimeout(() => {
				document.activeElement.blur();
			}, 10);
		}
	  }
	}, []);

	const defaultSelected = (isPlainObject(response)
		? response.id
		: response) || singleResponseOption;


	const { floatingLabel, labelPosition, labelIsHTML, disableCapitalization = true } = getLabelProps(
		'selectOption',
		components,
		pageId,
		pageOverrides,
	);

	const themeConfiguration = colorOverride ?  {
		...colorOverride,
	}: null;

	if (reactSelect) {
		reactSelect.selectLabel.font = font?.[0]?.['selectOption-label'] || font?.[1]?.['selectOption-label']
		reactSelect.placeholder.font = font?.[0]?.['selectOption-placeholder'] || font?.[1]?.['selectOption-placeholder']
		const mql = window.matchMedia('(forced-colors: active)');
		reactSelect.optionHoverColor = (mql.matches)? 'Highlight': 'rgb(210, 228, 234)';
	}

	const reactSlectThemeConfiguration = reactSelect ? {
		...reactSelect,
	} : null;

	const getThemeConfiguration=()=>{
		let themeConfig = { ...themeConfiguration,...reactSlectThemeConfiguration }

		if(properties?.border?.match(/none/i)){
			themeConfig = {
				...themeConfig,
				bottomBorderWidth: '0px',
				borderColor: '#fff',
				customDropdownIndicator: {
					color: '#fff',
					fontSize: '0px',
					width: '0px',
				},
			}
		}

		if(properties?.cursor){
			themeConfig = {
				...themeConfig,
				disabledPointer: properties?.cursor,
			}
		}
		return themeConfig
	}

	const handleFocus = () => {
		timeStampRef.current.timeStart = getUTCTimestamp();
	}

	const updateTimeStampData = () => {
		// Update of Timestamp Data
		updateTimeStamp(getTimeStampData(timeStampRef, transactionLog, meta));
	}

	// AutoComplete-Text Theme Configs
	const autoCompleteTextTheme = get(components, 'autocomplete-text.themeConfiguration', {});
	autoCompleteTextTheme.font = font?.[0]?.['input-label'] || font?.[1]?.['input-label'];
	autoCompleteTextTheme.placeholderFont = font?.[0]?.['input-placeholder'] || font?.[1]?.['input-placeholder'];

	const selectWrapperProps = {
		floatingLabel,
		questionTextLength: questionText?.length,
		reflexiveIndex,
		listParentId,
		isEditable,
		questionId,
		border: properties?.border,
	}

	const getValue = (selectedValue, options) => {
		if(type === 'M' && selectedValue) {
			return options.filter(option => selectedValue.includes(option.id) || selectedValue.includes(option.label));
		}
		const valueObject = options.filter(option=>(option.id===selectedValue || option.label===selectedValue))
		return valueObject[0]
	}
	const size = get(question, 'properties.size.value', '');
	const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);

	const customSelectPlaceholder = get(validations, 'custom_placeholder.value', 'Select...');

	const responseOptionsFiltered =()=>{
		return responseOptions.filter(el=>isArray(selectedOptions) && !selectedOptions?.includes(el.id))
	}
	const isAsteriskConfig = get(
		theme,
		'config.theme.components.inputAsteriskRequired',
	);
	let isAsterisk;
	if(isAsteriskConfig && question.validations.required && question.validations.required.value === true){
		isAsterisk = true;
	}
	if(isHidden) return null;

	if (
		displayType === 'dropdown' || displayType === 'radio_button' ||
		displayType === 'dropdown_search' || displayType === 'radio'
	) {
		return (
			<SelectWrapper ref={ timeStampRef } value={ getValue(defaultSelected, responseOptions) } { ...selectWrapperProps }>
				<ReactSelect
					isControlled={ isControlledInput }
					value={ getValue(defaultSelected, responseOptions) }
					key={ questionId }
					id={ questionId }
					inputRef={ inputRef }
					floatingLabel={ floatingLabel }
					labelPosition={ labelPosition }
					labelText={ questionText }
					labelIsHTML={ labelIsHTML }
					placeholder="Please select an option"
					customSelectPlaceholder={ customSelectPlaceholder }
					options={ (selectedOptions?.length)? responseOptionsFiltered() : responseOptions }
					color={ colorScheme }
					themeConfiguration={ getThemeConfiguration() }
					defaultSelected={ defaultSelected }
					onFocus={ handleFocus }
					handleSelect={ (selected) => {
						updateTimeStampData();
						handleSelectOption(selected, false, true, undefined, question);
					} }
					type={ type }
					width={ (screenViewport === 'mobile') ? 'M' : getWidth('selectOption', pageId, pageOverrides, size) }
					isFilterable
					tabSelectsValue
					{ ...tooltipProps }
					disableCapitalization={ disableCapitalization }
					disabled={ isDisabled || loading || !isEditable }
					isAsterisk={ isAsterisk }
					isCustomDropdown
				/>
			</SelectWrapper>
		);
	}

	if (questionType === 'autocomplete-text') {
		const defaultParams = {
			uid,
			question_id: questionId,
			properties: JSON.stringify(
				get(question, 'properties.autocomplete', {}),
			),
		};
		const allowUnrecognized = get(question, 'properties.autocomplete.allow_unrecognized', false);
		return (
			<SelectWrapper>
				<Select
					id={ questionId }
					floatingLabel={ false }
					inputRef={ inputRef }
					labelPosition="top"
					labelText={ questionText }
					labelIsHTML
					placeholder="Enter 3 or more characters"
					customSelectPlaceholder={ customSelectPlaceholder }
					asyncProps={ {
						api: '/search',
						method: 'post',
						defaultParams,
						searchKey: 'key',
						fetchFn: axiosInstance,
						responseMap: {
							id: 'id',
							label: 'value',
						},
						allowUnrecognized,
					} }
					color={ colorScheme }
					themeConfiguration={ { ...autoCompleteTextTheme } }
					handleSelect={ (selected, makeApiCall) => {
						handleSelectOption(selected, false, true, makeApiCall, question);
					} }
					defaultSelected={ defaultSelected }
					type="A"
					{ ...tooltipProps }
					isAsterisk={ isAsterisk }
				/>
			</SelectWrapper>
		);
	}
	return null;
};

const mapStateToProps = (state) => {
	const { uid, meta, questions: { isLoading, lastFocusedElementId, activeSubsectionId, sectionIndex, sections } } = state;
	return {
		uid,
		screenViewport: meta.screenViewport,
		lastFocusedElementId,
		loading: isLoading,
		currentSection: sections[activeSubsectionId][sectionIndex],
		meta: state.meta,
		isListMode: state?.questions?.listMode,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const questionId = get(props, 'question.question_id', '');
	const transactionLog = get(props, 'question.transaction_log', []);
	return {
		handleSelectOption: (response, preventReflexiveCall, hasEdit, makeApiCall, questionObj) => {
			const questionId = get(questionObj, 'question_id', '');
			const auditRequired = get(questionObj, 'audit_required', false);
			const isSelectiveFieldValidate = get(questionObj, 'validations.is_selective_field_validate', false);
			const { type } = props;
			// for multi-select and auto-suggest, store array.
			let updatedResponse = [response];
			if(type === 'A'){
				if(!isPlainObject(response)){
					updatedResponse = response;
				}
			}
			else if (type === 'M') {
				if(response && !isPlainObject(response)) {
					updatedResponse = response.map(
						option => option.id,
					);
				}
			}
			else {
				updatedResponse = response.id
			}
			dispatch(
				updateAnswersArray({
					questionId,
					response: updatedResponse,
					hasEdit,
					questionResponse: updatedResponse,
					sectionIndex: props.sectionIndex,
					preventReflexiveCall,
					propQuestion: questionObj,
					listParentId: props.listParentId,
					listIndex: props.listIndex,
					auditRequired,
					makeApiCall,
					isSelectiveFieldValidate,
					transactionLog,
				}),
			);
		},
		/** Prefill TimeStamp */
		updateTimeStamp: (
			timeStampData,
			isPrefilled=false,
		  ) => {
			dispatch(updateQuestionAnswerTimeStamp(questionId, timeStampData, props.sectionIndex, isPrefilled))
    },
		updateListMode: (mode) => {
			dispatch({
			  type: SET_LIST_MODE,
			  index: mode,
			})
		},
	};
};

export default withTheme(
	connect(mapStateToProps, mapDispatchToProps)(SelectOption),
);