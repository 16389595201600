/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CheckboxGroup, Tooltip } from 'crux';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import { at, isObject } from 'lodash';
import { updateAnswersArray, updateQuestionAnswerTimeStamp } from '../redux/actions';
import { getWidth, getTooltipProps, getLabelProps, getCSSVariables, getTimeStampData } from './utils';
import TextElement from '../Components/UIElements/TextElement';
import SelectWrapper from './SelectWrapper';
import { getUTCTimestamp, parseHtml } from '../utils'

export const TooltipWrapper = styled.div`
	display: flex;
	align-items: center;
	& div {
		margin-right: 10px;
		margin-bottom: 15px;
	}
`;

const ConsentText = styled.div`
	position:absolute;
	top: 65%;
    left: -3.2%;
	@media only screen and (max-width: 479px){
		top: 58%;
		left: -6%;
	}
`;

const SelectContainer = styled.div`
	position: relative;
	div[id*="checkbox-wrapper"] > label > span > p {
		margin-left: 15px;
	}
	${({ consentText, pageId }) =>
		 consentText &&
		`
			div[id*="checkbox-wrapper"] > div > label > span[name=span-component] {
				left: -2%;
			}
		`
	}
`;

const getSelectedOptions = (response) => {
	if (!response) {
		return [];
	}
	if (response && response.length) {
		if(!isObject(response[0])){
			return response
		}
		return response.map((r) => r?.id)?.filter((r) => r);
	}
	return [];
};

const getCurrentAnswerById = (answersArray, id) => {
	return answersArray.filter(ans => ans.question_id === id)
}

const MultiSelect = ({
	question,
	handleChange,
	theme,
	isEditMode,
	activeSubsectionId,
	enableESignCheckbox,
	handlePopup,
	answersArray,
	errorsArray,
	lastFocusedElementId,
	updateTimeStamp,
	meta,
	isDisabled,
}) => {
	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme },
				checkboxTheme,
			},
		},
	} = theme;
	const themeConfig=checkboxTheme&&checkboxTheme.themeConfiguration
	const {
		question_id: questionId,
		question_text: questionText,
		display_type: displayType,
		response_options: options,
		hint_title: tooltipTitle,
		hint_text: tooltipBody,
		hint_html: tooltipHTML,
		consent_text : consentText,
		response,
		orientation,
		tooltip,
		is_editable: isEditable,
		transaction_log: transactionLog,
		last_submitted_at: lastSubmittedAt,
	} = question;

	const labelProps = getLabelProps(
		'multiSelect',
		components,
		pageId,
		pageOverrides,
	);

	const [multiSelectOptions, setMultiSelectOptions] = useState([])

	const optionCheckedRef = useRef([]);
	const timeStampRef = useRef(null);

	let responseOptions = [];

	// Tab Navigation
	useEffect(() => {
		const shouldFocus = options.filter((x) => x.id === lastFocusedElementId);
		const textElementContainer = document.querySelector(`div[id*='checkbox-wrapper-${questionId}']`);
		textElementContainer?.setAttribute('data-clarity-mask', 'True');
		if (shouldFocus.length) {
			const element = document.getElementById(`checkbox-option-${questionId}-${lastFocusedElementId}`);
			if(element) {
				element.focus();
			}
		}
	}, []);


	const dependantQIds = get(question, 'validations.dependant_question_ids', null)
	let isCheckboxEnabled = true;
	if(dependantQIds){
		const dependantQsAnsweredList = answersArray.filter((eachAnsObj) => (eachAnsObj.answer || eachAnsObj.answer === 0) && dependantQIds.includes(eachAnsObj.question_id))
		const dependantQsErrorList = errorsArray.filter((eachErrorObj) => dependantQIds.includes(eachErrorObj.question_id))
		isCheckboxEnabled = (dependantQsAnsweredList?.length === dependantQIds?.length) && !dependantQsErrorList?.length;
	}

	/* inspect options on onlyOption property, except the one checked to disable the rest */
	const getResponseOptions = (selectOptions, questionResponse, isOnChange) => {
		selectOptions.map((opt) => {
			let conditionalOptions = []

			if(isOnChange)
				conditionalOptions = optionCheckedRef.current.filter(optn => opt?.id !== optn?.id && optn?.checked && optn?.onlyOption)
			else
				conditionalOptions = Array.isArray(questionResponse) && questionResponse.filter(resp => opt?.id !== resp?.id && opt?.onlyOption && resp?.onlyOption)

			if(opt.onlyOption && conditionalOptions.length)
				opt.disabled = true

			return opt;
		})
		return selectOptions
	}

	useEffect(() => {
		if (isEditMode || Array.isArray(question.response)) {
			Array.isArray(question.response) &&
			question.response.forEach(element => {
				handleChange({
					id: element?.id,
					checked: true,
					type: 'array',
				},
				true,
				false,
				isEditMode,
				);
			});
			responseOptions = getResponseOptions(options, response, false)
			setMultiSelectOptions(responseOptions)
			updateTimeStamp({ transactionLog, lastSubmittedAt }, true);
		}else
			setMultiSelectOptions(options)
	}, []);

	const handleFocus = e => {
		timeStampRef.current = {}
		timeStampRef.current.startTime = getUTCTimestamp();
	}

	const handleBlur = () => {
		updateTimeStamp(getTimeStampData(timeStampRef, transactionLog, meta));
	}

	const handleOptionChange = (option, checked, isOnChange) => {
		let currentOptionIndex = -1;

		const optionObj = { ...option, checked }

		optionCheckedRef.current.map((optn, index) => {
			if(optn.id === option.id){
				optionCheckedRef.current[index] = optionObj
				currentOptionIndex = index;
			}
			return optn
		})

		if(currentOptionIndex === -1){
			optionCheckedRef.current.push(optionObj);
		}

		responseOptions = getResponseOptions(options, undefined, isOnChange)
		setMultiSelectOptions(responseOptions)
	}

	const pdfScrollToLast = get(labelProps, 'pdfScrollToLast', false);

	// disable esign checkbox until the user scrolls through all of the PDF pages
	if(pdfScrollToLast && activeSubsectionId === 'signature' && options.length > 0){
         options[0].disabled = !enableESignCheckbox;
	}

	let selectedOptions = response;
	let isControlledInput = false;

	const reset = get(question, 'validations.reset_on', null)
	if( reset ) {
		const currentAnswer = getCurrentAnswerById(answersArray, questionId);
		isControlledInput = true;
		if( currentAnswer.length > 0) {
			selectedOptions = currentAnswer[0].answer;
		}
	}

	const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);
	const labelTheme = get(labelProps, 'labelText.themeConfiguration', {});
	const element = '.multiSelect';
	const selectDesElement='.multiSelect-label';
	const cssVariables =  getCSSVariables(theme);
	const font = at(cssVariables, [`pageOverride.font${element}`, `global.font${element}`]);
	const selectDesFont = at(cssVariables, [`pageOverride.font${selectDesElement}`, `global.font${selectDesElement}`]);
	const isAsteriskConfig = get(
		theme,
		'config.theme.components.inputAsteriskRequired',
	);
	let isAsterisk;
	if(isAsteriskConfig && question.validations.required && question.validations.required.value === true){
		isAsterisk = true;
	}
	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
		<fieldset>
		<legend style={ { fontSize:0,margin:0, display: 'none' } }>{parseHtml(questionText,handlePopup)}</legend>
		<SelectWrapper
			width={ getWidth('multiSelect', pageId, pageOverrides) }
			ref={ timeStampRef }
			onBlur={ handleBlur }
		>
			<TooltipWrapper>
				<TextElement
					id={ `multiselect-label-${questionId}` }
					styleType="label"
					text={ questionText }
					sectionIndex={ 99 }
					elementTheme={ labelTheme }
					baseFont={ selectDesFont[0] || selectDesFont[1] }
					isAsterisk={ isAsterisk }
				/>
				{
					tooltipProps.hasTooltip &&
					<Tooltip
						ariaLabel={ `${questionText} tooltip` }
						{ ...tooltipProps }
					/>
				}
			</TooltipWrapper>
			{ displayType === 'checkbox' && (
				<SelectContainer consentText = { consentText } pageId = { pageId }>
				<ConsentText>{consentText}</ConsentText>
				<CheckboxGroup
					id={ questionId }
					orientation={ orientation || 'columns' }
					selectedOptions={ getSelectedOptions(selectedOptions) }
					handlePopup = { handlePopup }
					onChange={ (e, option) => {
						const { checked } = e.target;
						handleOptionChange(option, checked, true)
						handleChange(
							{
								id: option.id,
								checked,
								type: 'array',
							},
							false,
							reset,
							true,
						);
					} }
					onFocus={ handleFocus }
					options={ multiSelectOptions }
					disableGroup={ isDisabled || !isCheckboxEnabled || !isEditable }
					color={ colorScheme }
					themeConfiguration={ { ...themeConfig , font:font[0]||font[1] } }
					isControlledInput = { isControlledInput }
				/>
				</SelectContainer>
			)}
		</SelectWrapper>
		</fieldset>
	);
};

MultiSelect.propTypes = {
	/* eslint-disable react/forbid-prop-types */
	question: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	const activeSubsectionId = get(state, 'questions.activeSubsectionId', '');
	const lastFocusedElementId = get(state, 'questions.lastFocusedElementId', '');
	const sectionIndex = get(state, 'questions.sectionIndex', '');
	const sections = get(state, 'questions.sections', '');
	const hasESign = get(state, 'questions.eSign.hasESign', false);
	const eSignEnable = get(state, 'questions.eSign.eSignEnable', false);
	const enableESignCheckbox = hasESign && eSignEnable;
	let answersArray = []
	let errorsArray = []

	if(activeSubsectionId && sections) {
		answersArray = get(sections[activeSubsectionId][sectionIndex], 'answersArray');
		errorsArray = get(sections[activeSubsectionId][sectionIndex], 'errorsArray');
	}
    return {
		activeSubsectionId,
		enableESignCheckbox,
		answersArray,
		errorsArray,
		lastFocusedElementId,
		meta: state.meta,
    }
};

const mapDispatchToProps = (dispatch, props) => {
	const questionId = get(props, 'question.question_id', '');
	const transactionLog = get(props, 'question.transaction_log', []);
	return {
		handleChange: (response, preventReflexiveCall, reset, hasEdit = false) => {
			const auditRequired = get(props, 'question.audit_required', false);
			const { sectionIndex } = props;
			dispatch(
				updateAnswersArray({
					response,
					questionId,
					sectionIndex,
					hasEdit,
					preventReflexiveCall,
					listIndex: props.listIndex,
					listParentId: props.listParentId,
					propQuestion: props.question,
					reset,
					auditRequired,
					transactionLog,
				}),
			);
		},
		updateTimeStamp: (
			timeStampData,
			isPrefilled=false,
		  ) => {
			dispatch(updateQuestionAnswerTimeStamp(questionId, timeStampData, props.sectionIndex, isPrefilled))
		},
	};
};
export default withTheme(
	connect(mapStateToProps, mapDispatchToProps)(MultiSelect),
);
