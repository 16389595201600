/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import './Accordion.css';

// const Text = styled.p`
//   display: block;
//   font-size: 1.5rem;
//   margin-top: 0;
//   margin-bottom: 0.5rem;
//   margin-left: 0;
//   margin-right: 0;
//   font-weight: 500;
// 	color: rgba(93, 93, 93, 1);
// 	@media only screen and (max-width: 767px) {
// 		max-width: 150px;
// 	}
// `;

const Accordion = ({ index, len, isReview, expanded, setExpanded, children }) => {
	// const [expanded, setExpanded] = React.useState(true);
	const extendChild = child => React.cloneElement(child, { showQuestionText: false })
	const extendedChildren = Array.isArray(children) ? children.map(extendChild) : extendChild(children)
	const isExpanded = expanded ? 'expanded' : 'collapsed';
	useEffect(() => {
		if (index !== len - 1 && !isReview) {
			setExpanded(false);
		}
	}, [index, len,  isReview])
	return (
		<div className={ `Accordion ${isExpanded}` }>
			{/* <div onClick={ () => {
				setExpanded(!expanded);
			} }>
				<ChevronIcon />
				<Text className="padding-bottom-15">
					{title}
				</Text>
			</div> */}
			<div className={ `AccordionContent ${isExpanded}` }>
				{extendedChildren}
			</div>
		</div>
	);
}

export default Accordion;