const SET_SCREEN_VIEWPORT = 'SET_DISPLAY_WIDTH';
const SET_LIST_RELATION = 'SET_LIST_RELATION';
const SET_IP_ADDRESS = 'SET_IP_ADDRESS';
const SET_VISITOR_ID = 'SET_VISITOR_ID';
const IS_INTERNET_CONNECTED = 'IS_INTERNET_CONNECTED';

const setScreenViewport = (payload) => ({
  type: SET_SCREEN_VIEWPORT,
  payload,
})

const setListRelation = (payload) => ({
  type: SET_LIST_RELATION,
  payload, 
});

const setIPAddress = (payload) => ({
  type: SET_IP_ADDRESS, 
  payload,
});

const setVisitorId = (payload) => ({
  type: SET_VISITOR_ID,
  payload,
});

const isInternetConnected = (payload) => ({
  type: IS_INTERNET_CONNECTED,
  payload,
});

export {
  SET_SCREEN_VIEWPORT,
  setScreenViewport,
  SET_LIST_RELATION,
  setListRelation,
  SET_IP_ADDRESS,
  setIPAddress,
  SET_VISITOR_ID,
  setVisitorId,
  IS_INTERNET_CONNECTED,
  isInternetConnected,
}
