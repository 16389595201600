import React from 'react';
import styled from 'styled-components';
import Button from '../../cruxComponents/Button';

const AccordionDetailsWrapper = styled.div`
  height: auto;
  overflow: visible;
  padding: ${({ expanded }) => (expanded ? '16px 16px 8px 16px' : '0')};
  max-height: ${({ expanded }) => (expanded ? 'auto' : '0px')};
  ${({ expanded }) => !expanded && 'visibility: hidden;'}
  transition: max-height 222ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const PolicyInfoContainer = styled.div`
  display: grid;
  gap: 20px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(130px, auto));
  >:last-child{
    display: flex;
    align-items: end;
    grid-column: span 2/-1;
  }
`;

const PInfoCell = styled.div`
  color: var(--global-bh-black, #333);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  > * + * {
    margin-top: 5px;
  }
`;
const PInfoLabel = styled.div``;
const PInfoValue = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const AccordionDetails = ({ expanded, accordionDetailsData, sectionIndex, cardIdentifierKeys, activeSubsectionId }) => {
  return (
    <AccordionDetailsWrapper expanded={ expanded } className="accordion-details">
      {expanded && (
        <PolicyInfoContainer expanded={ expanded }>
          {accordionDetailsData.map((eachAccDet) => {
            if(eachAccDet?.display_type === 'button'){
              return (
                <Button
                  question={ eachAccDet }
                  key={ eachAccDet.question_id }
                  sectionIndex={ sectionIndex }
                  cardIdentifierKeys={ cardIdentifierKeys }
                  activeSubsectionId = { activeSubsectionId }
                />
              )
            }
            let formattedAccResp = eachAccDet.response.split(',');
            formattedAccResp = formattedAccResp
              .filter((accResp) => accResp)
              .map((accResp) => accResp.trim());
            return (
              <PInfoCell>
                <PInfoLabel>{eachAccDet.question_text}</PInfoLabel>
                {formattedAccResp.map((eachFAccDetail) => {
                  return <PInfoValue>{eachFAccDetail}</PInfoValue>;
                })}
              </PInfoCell>
            );
          })}
        </PolicyInfoContainer>
      )}
    </AccordionDetailsWrapper>
  );
};

export default AccordionDetails;
