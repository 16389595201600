import React from 'react';
import at from 'lodash/at';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Alert, Card } from 'crux';
import { getCSSVariables } from '../../cruxComponents/utils';
import Section from './Section';

const Cardwrapper = styled.div.attrs({
  // 'tabIndex':'0',
})``

const Questions = ({
  errors,
  sectionData,
  index,
  sectionLength,
  processing,
  theme,
  changeMode,
  isPageType,
}) => {
  const { errorMessage, errorTitle, hasError } = errors;

  const {
    pageId,
    pageLayout,
    config: {
      theme: {
        components,
        pageOverrides,
      },
    },
  } = theme;
  const { id, review, questionList } = sectionData;

  const cardThemeConfig = get(components, 'card.themeConfiguration', {});
  const marginLeft = get(pageOverrides, `${pageId}.card.margin.left`);
  let cardWidth  = get(pageOverrides, `${pageId}.card.width`);
  if(pageOverrides[pageId]?.newBusinessTransaction && isPageType){
    cardWidth = get(pageOverrides[pageId], 'newBusinessTransaction.cardWidth');
  }

  if (cardWidth)
    cardThemeConfig.width = cardWidth;

  let leftBorderWidth;
  let leftBorderColor;

  if (questionList.length > 0) {
    if (questionList[0].style && questionList[0]?.style?.left_border) {
      leftBorderWidth = questionList[0]?.style?.left_border?.width;
      leftBorderColor = questionList[0]?.style?.left_border?.color_code;
    }
  }
  
  const  maxWidth  = pageOverrides[pageId] &&pageOverrides[pageId].maxWidth

  // CSS Variables
  const cssVariables = getCSSVariables(theme);
  const padding = at(cssVariables, ['pageOverride.padding.card', 'global.padding.card']);
  const singleLayoutPadding = 'clamp(24px, 5%, 80px) clamp(16px, 5%, 100px) clamp(24px, 5%, 40px) clamp(16px, 5%, 100px)';

  return (
    <Cardwrapper>
    <Card
      key={ id }
      id={ `group-container-${id}` }
      noStyle={ pageLayout === 'auto' }
      layout={ pageLayout }
      leftBorderWidth={ leftBorderWidth }
      leftBorderColor={ leftBorderColor }
      themeConfiguration={ cardThemeConfig }
      padding={ pageLayout === 'single' ? singleLayoutPadding : (padding[0] || padding[1]) }
      maxWidth= { maxWidth }
      marginLeft={ marginLeft }
    >
      {hasError && sectionLength - 1 === index && (
        <Alert message={ errorMessage } type="error" title={ errorTitle } />
      )}
        <Section
          questionList={ questionList }
          key={ id }
          sectionIndex={ index }
          pageId={ pageId }
          reviewProps={ review }
          processing={ processing }
          changeMode={ changeMode }
        />
    </Card>
    </Cardwrapper>
  );
};

Questions.propTypes = {
  sectionData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Questions);
