import React from 'react';
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { getCurrentBreadcrumbsList } from '../../../redux/selector/questions';
import { getBreadcrumbStatus } from '../../../util';

const Layout = styled.div`
	height: 80px;
	background: #ffffff;
	border-radius: 8px;
	margin-bottom: 10px;
	// box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
	border: 1px solid rgb(130, 130, 130);
	border-radius: ${({ borderRadius }) => borderRadius};
	@media (min-width: 1281px) {
		margin-right: 50px;
	}
	
	@media (min-width: 769px) and (max-width: 1024px) {
		width: 80%;
		height: 80px;
		background: #ffffff;
		border-radius: ${({ borderRadius }) => borderRadius};
		margin-right: 0;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		width: 65.22%;
		height: 80px;
		background: #ffffff;
		border-radius: ${({ borderRadius }) => borderRadius};
		margin-right: 0;
	}
	@media (min-width: 1281px) {
		width: 65.22%;
		height: 80px;
		background: #ffffff;
		border-radius: ${({ borderRadius }) => borderRadius};
		margin-right: 50px;
	}
	@media (max-width: 767px) {
		border-radius: ${({ borderRadius }) => borderRadius};
		// margin: 20px 5%;
		#global-application-progress {
			display: none;
		}
		height: 0;
	}
`;

const InfoContainer = styled.div`
	padding: 13px 0 13px 20px;
	@media (min-width: 1281px) {
		padding: 13px 0 13px 20px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		padding: 13px 0 13px 20px;
	}
	@media (min-width: 769px) and (max-width: 1024px) {
		padding: 13px 0 13px 20px;
	}
	@media (min-width: 768px) and (max-width: 768px) {
		padding: 13px 10px 13px 12px;
	}
	@media (min-width: 820px) and (max-width: 820px) {
		padding: 15px 7px 11px 7px;
	}
`;

const Label = styled.span`
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-family: 'Roboto-Bold';
	@media (min-width: 1281px) {
		color: rgb(51, 51, 51);
		font-size: 14px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		color: rgb(51, 51, 51);
		font-size: 14px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		color: rgb(51, 51, 51);
		font-size: 14px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		align-self: flex-start;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		align-self: flex-start;
	}
`;

const Percentage = styled.span`
	color: rgb(51, 51, 51);
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	@media (min-width: 1281px) {
		color: rgb(51, 51, 51);
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		color: rgb(51, 51, 51);
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		color: rgb(51, 51, 51);
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		align-self: flex-end;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		align-self: flex-end;
	}
`;
const ProgressHolder = styled.div`
	width: 100%;
	height: 8px;
	display: flex;
	flex-direction: row;
	background-color: #b3b3b3;
	border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
	border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
	overflow: hidden;
	@media (min-width: 1281px) {
		width: 100%;
		height: 8px;
		display: flex;
		flex-direction: row;
		border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
		border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
		margin: -5px 0px;
	}
	@media (min-width: 768px) and (max-width: 1280px) {
		width: 100%;
		height: 8px;
		display: flex;
		flex-direction: row;
		border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
		border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
		margin: -3px 0px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		margin: -3px 0px;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		margin: -5px 0px;
	}
`;
const Done = styled.div`
	width: ${(props) => `${props.percentage}%`};
	flex: ${(props) => props.percentage};
	background: #3eb0b9;
	border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
	transition: all 0.5s linear;
	@media (min-width: 1281px) {
		flex: ${(props) => props.percentage};
		background: #3eb0b9;
		border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		flex: ${(props) => props.percentage};
		background: #3eb0b9;
		border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		flex: ${(props) => props.percentage};
		background: #3eb0b9;
		border-bottom-left-radius: ${({ borderRadius }) => borderRadius};
	}
	@media (min-width: 481px) and (max-width: 767px) {
		border-bottom-left-radius: 0px;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		border-bottom-left-radius: 0px;
	}
`;
// const Pending = styled.div`
// 	flex: ${(props) => props.percentage};
// 	background-color: #b3b3b3;
// 	border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
// 	@media (min-width: 1281px) {
// 		flex: ${(props) => props.percentage};
// 		background-color: #b3b3b3;
// 		border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
// 	}
// 	@media (min-width: 1025px) and (max-width: 1280px) {
// 		flex: ${(props) => props.percentage};
// 		background-color: #b3b3b3;
// 		border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
// 	}
// 	@media (min-width: 768px) and (max-width: 1024px) {
// 		flex: ${(props) => props.percentage};
// 		background-color: #b3b3b3;
// 		border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
// 	}
// 	@media (min-width: 481px) and (max-width: 767px) {
// 		border-bottom-right-radius: 0px;
// 	}
// 	@media only screen and (min-width: 320px) and (max-width: 480px) {
// 		border-bottom-right-radius: 0px;
// 	}
// `;

const FlexedDiv = styled.div`
	flex: 1;
`;
// Progress bar component accepts one param as props called progress
const Progress = ({ progress, theme }) => {
	const borderRadius = get(theme, 'config.theme.components.progressCard.borderRadius', '10px');
if(progress)
  return (
    <Layout name='layout' borderRadius={ borderRadius }> 
          <InfoContainer id='global-application-progress' name='info-container'>
            <FlexedDiv name='flexed-div'>
              <Label id="progress-label">Application progress</Label>
            </FlexedDiv>
            <FlexedDiv name='flexed-div'>
              <Percentage id="progress-indication">{`${progress}% Complete`}</Percentage>
            </FlexedDiv>
          </InfoContainer>
      <ProgressHolder name='progress-holder' borderRadius={ borderRadius }>
        <Done name='done' percentage={ progress } borderRadius={ borderRadius } />
      </ProgressHolder>
    </Layout>
  )
 return <></>
};

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  let activeSectionQuestions;
  let progress = 0;
  const breadcrumbs = getCurrentBreadcrumbsList(state);
  const [activeBc] = getBreadcrumbStatus(breadcrumbs || []);
  if (activeBc.length > 0) {
    activeSectionQuestions = state.questions.sections[activeBc[0].id];
    progress =
	activeSectionQuestions?.length && activeSectionQuestions[activeSectionQuestions.length - 1]?.otherParams.question_progress;
  }
  return {
    screenViewport: state.meta.screenViewport,
    progress,
  }
}

export default connect(mapStateToProps)(withTheme(Progress));
