/* eslint-disable react/no-danger */
import React from 'react';
import at from 'lodash/at';
import styled, { withTheme } from 'styled-components';
import { validateInnerHtml } from '../../utils';
import { getCSSVariables } from '../../cruxComponents/utils';

const Text = styled.p`
    font: ${({ font }) => font?.[0]?.haveQuestions || font?.[1]?.haveQuestions};
    color: #2f3847;
    line-height: 24px;
`;

const Description = styled.p`
    p p.header {
        color: #0B465A;
        font-weight: bold;
        font-size: 14px; 
        background-color: white;
        height: auto;   
    }
    p div.content {
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
    }
    p div.footer {
        padding: 15px 0px 0px 0px;
        // font-weight: bold;
        text-align: left;
        font-family: Roboto-Medium;
        font-size: 14px;
    }
    p .footer a {
        color: #0B465A;
    }
    p div.footer::after {
        content: ' >';
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        p div.content {
            width: inherit;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 768px) {
        p div.content {
            width: 110%;
        }
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
        p div.content {
            width: 110%;
        }
    }
`;

const HaveQuestionsWrapper = styled.div`
    ${({ layout, padding, maxWidth, width }) => `
        ${layout === 'single' && `
            margin: 0 auto;
            max-width: ${maxWidth?.[0]?.['haveQuestions-haveQuestionsWrapper'] || maxWidth?.[1]?.['haveQuestions-haveQuestionsWrapper']};
        `};
         ${layout === 'double' && 'margin: 20px 0'};
         ${width && `width: ${width}`};
        padding: ${padding?.[0]?.['haveQuestions-haveQuestionsWrapper'] || padding?.[1]?.['haveQuestions-haveQuestionsWrapper']};
    `}
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 25px 0px 0px 0px;
    width: 65.22%;
    border-top: 1px solid #ddd;
    margin: 30px 50px;
    line-height: 27px;
    font-size: 14px;
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        align-self: flex-start;
        width: 100%;
        margin: auto;
        background-color: #f8f8f8;
    }
    @media only screen and (min-width: 768px) and (max-width: 768px) {
        width: 75%;
        margin: 30px 20px;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
        margin: 30px 20px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1280px) {
        margin-right: 0;
    }
`;

const HaveQuestions = ({ layout, haveQuestions ,theme, width }) => {
    // CSS Variables
    const cssVariables = getCSSVariables(theme);
    const font = at(cssVariables, ['pageOverride.font', 'global.font']);
    const padding = at(cssVariables, ['pageOverride.padding', 'global.padding']);
    const maxWidth = at(cssVariables, ['pageOverride.maxWidth', 'global.maxWidth']);

    if (!haveQuestions) return null;
    const { text, description } = haveQuestions;
    return (
        <HaveQuestionsWrapper layout={ layout } padding={ padding } width={ width } maxWidth={ maxWidth }>
            {text && <Text font={ font }>{ text }</Text>}
            <Description>
                <p 
                    dangerouslySetInnerHTML={ { __html: validateInnerHtml(description) } }
                />
            </Description>
        </HaveQuestionsWrapper>
    );
}

export default withTheme(HaveQuestions);