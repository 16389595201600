import PropTypes from 'prop-types';

const questionType = PropTypes.shape({
	child_questions: PropTypes.bool.isRequired,
	child_questions_on: PropTypes.array,
	display_type: PropTypes.string.isRequired, // if possible, replace with one-of
	hint_text: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	is_edit_icon_visible: PropTypes.bool.isRequired,
	is_editable: PropTypes.bool.isRequired,
	is_hidden: PropTypes.bool.isRequired,
	is_reviewable: PropTypes.bool.isRequired,
	orientation: PropTypes.string.isRequired,
	parent_question_answer: PropTypes.array.isRequired,
	presentation_type: PropTypes.string.isRequired,
	question_id: PropTypes.string.isRequired,
	question_status: PropTypes.string.isRequired,
	question_status_message: PropTypes.string.isRequired,
	question_text: PropTypes.string.isRequired,
	question_type: PropTypes.string.isRequired,
	questions: PropTypes.array.isRequired,
	response: PropTypes.any.isRequired,
	response_options: PropTypes.any.isRequired,
	sequence_number: PropTypes.number.isRequired,
	sureify_meta: PropTypes.any.isRequired,
	validations: PropTypes.any.isRequired,
});

const test = 'Intentional unnecessary export';

export { questionType, test };
