import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { Button } from 'crux';
// import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withTheme } from 'styled-components';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Viewer, SpecialZoomLevel, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';

// import { Document } from 'react-pdf/dist/entry.webpack';
import { PdfWrapper, PageLabel, PdfDisplayWrapper, Wrapper } from './styles';
import { axiosInstance } from '../../../util/axios';
import { toggleESignButton, setPDFConfigs } from '../../../redux/actions';
import Cover from '../../StyledComponents/MaskCover/Index';
// import PDFPage from './PDFPage';
import './pdf.css';

export const getFileURL = (data) => {
    const byteChars = window.atob(data);
    const byteNums = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i += 1) {
        byteNums[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNums);
    const fileURL = new Blob([byteArray], { type: 'application/pdf' });
    return URL.createObjectURL(fileURL);
};

const getDefaultScale = (screenViewport) => {
    if (screenViewport === 'mobile') return 1;
    return 2.5;
}

const Pdf = ({ question, theme, screenViewport, pdfConfigs, _setPDFConfigs, enableESign }) => {
    const { pdfBlobURL } = pdfConfigs;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fileURL, setFileURL] = useState(pdfBlobURL);
    const [loading, setLoading] = useState(true);
    // const docRef = useRef();
    // const [pdfPages, setPDFPages] = useState([]);
    const [scale, setScale] = useState(getDefaultScale(screenViewport));
    const { primaryButton } = get(theme, 'config.theme.global');
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
    useEffect(() => {
        (async () => {
            let documentURL = get(question, 'get_document_url');
            if (!documentURL) documentURL = get(question, 'response', '/?');
            const queryStartIndex = documentURL.indexOf('?');
            const queryParams = queryString.parse(
                documentURL.substring(queryStartIndex),
            );
            try {
                setLoading(true);
                const response = await axiosInstance.post(
                    '/documents',
                    queryParams,
                );
                if (response.data) {
                    const URL = getFileURL(response.data);
                    setFileURL(URL);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error(err);
            }
        })();
        enableESign(false);
        return () => {
            enableESign(true);
          };
    }, []);

    const handleScaleClick = (type) => {
        if (scale <= 0.3 && type === '-') return;
        if (type === '+') setScale(scale+0.2);
        else setScale(scale-0.2);
    }

    // const onDocumentLoadSuccess = (props) => {
    //     _setPDFConfigs(fileURL, props.numPages);
    //     if (docRef.current && docRef.current.setAttribute && typeof docRef.current.setAttribute === 'function') {
    //         docRef.current.setAttribute('tabindex', '0')
    //     }
    //     setNumPages(props.numPages);
    // };
    const onDocumentLoadSuccess = (e) => {
        const { numPages: noofPages } = e.doc._pdfInfo;
        _setPDFConfigs(fileURL, noofPages);
        setNumPages(noofPages);
      };
    useEffect(() => {
        if (pageNumber === numPages) {
            enableESign(true);
        }
    }, [pageNumber,numPages]);

    // const getAllPages = () => {
    //     const pages = [];
    //     // if (pages.length) return pages;
    //     for (let i = 0; i < numPages; i += 1) {
    //         pages[i] = (
    //             <PDFPage
    //                 index={ i }
    //                 scale={ scale }
    //                 getInView={ debounce((inView) => inView && setPageNumber(i+1), 100) }
    //             />
    //         );
    //     }
    //     // setPDFPages(pages);
    //     return pages;
    // };

    return (
        <>
            {!loading ? (
                <PdfWrapper>
                    <PageLabel>
                        Page {pageNumber} of {numPages}
                    </PageLabel>
                    {/* { (screenViewport === 'mobile') && ( */}
                        <Wrapper>
                            <ZoomInButton/>
                            <ZoomOutButton/>
                        </Wrapper>
                    {/* )} */}
                    <PdfDisplayWrapper>
                        {/* <Document
                            file={ fileURL }
                            onLoadSuccess={ onDocumentLoadSuccess }
                            onSourceSuccess={ () => {} }
                            inputRef={ docRef }
                        >
                            {numPages &&
                                getAllPages().map((page) => {
                                    return page;
                                })}
                        </Document> */}

{fileURL && (
                        <Worker workerUrl={ pdfjsWorker }>
                            <Viewer
                              onDocumentLoad={ onDocumentLoadSuccess }
                              onPageChange={ (e) => setPageNumber(e.currentPage + 1) }
                              fileUrl={ fileURL }
                              plugins={ [zoomPluginInstance] }
                              defaultScale={ SpecialZoomLevel.PageWidth }
                            />
                        </Worker>
                      )}
                    </PdfDisplayWrapper>
                    { (screenViewport === 'mobile') && (
                        <Wrapper>
                            <Button
                                color={ primaryButton.color }
                                type='text'
                                label={ <i className="fa fa-search-plus" aria-hidden="true"/> }
                                handleButtonClick={ () => handleScaleClick('+') }
                            />
                            <Button
                                color={ primaryButton.color }
                                type='text'
                                label={ <i className="fa fa-search-minus" aria-hidden="true"/> }
                                handleButtonClick={ () => handleScaleClick('-') }
                            />
                        </Wrapper>
                    )}
                </PdfWrapper>
            ) : (
                <>
                    <Cover />
                    <Wrapper>
                        <h5>Loading... <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"/></h5>
                    </Wrapper>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        screenViewport: state.meta.screenViewport,
        pdfConfigs: get(state, 'questions.eSign'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        enableESign: (enable) => dispatch(toggleESignButton(enable)),
        _setPDFConfigs: (pdfBlobURL, numPages) =>
            dispatch(setPDFConfigs(pdfBlobURL, numPages)),
    }
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Pdf));
