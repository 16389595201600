import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  max-height: 68%;
  overflow-y: auto;
  width: ${(props) => props.size ? '620px' : '420px' };
  background: rgba(255, 255, 255, 1);
  border: 1px solid #000;
  border-radius: ${({ cardThemeConfiguration }) =>
    cardThemeConfiguration && cardThemeConfiguration.borderRadius
      ? cardThemeConfiguration.borderRadius
      : '6px'};
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: ${(props) => (props.isLoading ? 0 : 9999)};
  transition: all ease 0.8s;
  @media (min-width: 481px) and (max-width: 767px) {
    width: 340px;
  }
  @media (min-width: 321px) and (max-width: 480px) {
    width: 290px;
    max-height: 500px;
  }
  // for iphone 5/SE, NAT-1470
  @media (max-width: 320px) {
    top: -7%;
    position: absolute;
    width: 290px;
    max-height: 400px;
  }

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
`;

export const ModalHeading = styled.div`
  font-size: 14px;
  font-weight: 600px;
  line-height: 32px;
  margin: 0px 0px 0px 0px;
  @media (min-width: 1281px) {
    font-size: 24px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 24px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 22px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 18px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionBlock = styled.div`
  padding: 30px;
  cursor: pointer;
  border: ${(props) =>
    props.selectedQuestion
      ? '2px solid rgba(28, 87, 165, 1)'
      : '2px solid rgba(215, 215, 215, 1)'};
  border-radius: 6px;
  margin: 5px;
  display: flex;
  align-items: center;
`;

export const QuestionHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  @media (min-width: 1281px) {
    font-size: 20px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 20px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 18px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const QuestionContent = styled.div`
  display: flex;
  .fa-map-marker {
    padding-top: 4px;
  }
`;

export const Address = styled.div`
  font-size: 8px;
  color: rgba(118, 118, 118, 1);
  @media (min-width: 1281px) {
    font-size: 16px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 16px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 14px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 10px;
  }
`;

export const Button = styled.button.attrs((props) => ({
  checked: props.checked,
  type: props.type,
  id: props.id,
}))`
  width: 90%;
  border: 1px solid black;
  background-color: rgba(41, 101, 204, 1);
  color: rgba(255, 255, 255, 1);
  max-height: 45px;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  padding: 10px 32px;
  border-radius: 24px;
  margin: 0.5em 0.5em 0.5em 0px;
  ${(props) =>
    props.label === 'cancel' &&
    css`
      border: 0;
      background: none;
      cursor: pointer;
      color: rgba(41, 101, 204, 1);
    `}
  @media (max-width: 320px) {
    padding: 10px 28px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
  @media (min-width: 320px) and (max-width: 480px) {
    justify-content: center;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const OuterCircle = styled.div`
  name: ${(props) => props.name};
  background: ${(props) => (props.selected ? 'rgba(28, 87, 165, 1)' : null)};
  border: ${(props) => (!props.selected ? '2px solid grey' : null)};
  border-radius: 50%;
  height: 20px;
  min-width: 20px;
  position: relative;
`;

export const InnerCircle = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin: 5px 0px 0px 5px;
`;

export const Error = styled.p`
  color: rgba(255, 0, 0, 1);
  font-size: 16px;
  align-self: center;
  margin: 0px !important;
`;

export const QuestionDescription = styled.p`
  color: rgba(69, 69, 69, 1);
  margin: 0px 10px 10px 0px;
  font-size: 16px;
  margin-bottom: ${(props) => (props.error ? '0px !important' : '10px')};
`;
export const Message = styled.p`
  font-size: 16px;
`;
