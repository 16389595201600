import styled from 'styled-components';

export const FooterNote = styled.p`
    font-size: ${({ styles })=>styles.fontSize|| '12px'};
    font-weight: 400;
    color: ${({ styles })=>styles.color || '#454545' };
    margin: 15px 0px;
    line-height: 1.33;
`;
export const FooterWrapper = styled.div`
    margin: 0 auto;
    padding: 20px 0;
    ${({ layout, width }) => layout !== 'single' && `
        width: ${ width?.[0]?.['footerNotes-footerWrapper'] || width?.[1]?.['footerNotes-footerWrapper']};
        max-width: 780px;
    `};
    max-width: ${({ maxWidth }) => maxWidth?.[0]?.['footerNotes-footerWrapper'] || maxWidth?.[1]?.['footerNotes-footerWrapper']};
`;