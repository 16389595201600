import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import { updateAnswersArray } from '../../redux/actions';
import useReflexiveWrapResizeHandler from '../../util/CustomHooks/useReflexiveWrapResizeHandler';
import AccorionCard from './AccordionCard';

const AccordionWrapper = styled.div`
  border: 20px solid rgba(226, 226, 226, 1);
  margin-bottom: 30px;
  height: auto;
`;

const AccordionContainer = styled.div`
  display: flex;
  padding: 48px 72px;
  flex-direction: column;
  align-items: flex-start;
  > * + * {
    margin-top: 15px;
  }
  > p {
    margin-top: 40px;
    margin-bottom: 0;
  }
  > p:first-child {
    margin: 0;
  }
`;

const GroupedCardHeader = styled.p`
  color: var(--global-bh-black, #333);
  font-family: 'Roboto-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.1px;
`;

const Accordion = ({ question, sectionIndex, handleChange, activeSubsectionId }) => {

  const {
    question_id: questionId,
    accordion_cards: accordionCards = [],
    response,
  } = question;

  useEffect(() => {
    if (response) {
      handleChange(response);
    }
  }, []);

  const reflexiveWrapId = 'accordion-wrapper';
  useReflexiveWrapResizeHandler(reflexiveWrapId);

  const [activeCardIdx, setActiveCardIdx] = useState(-1);

  function getAccordionData(accordionCard) {
    const accordionSummaryData = [];
      const accordionDetailsData = [];

    accordionCard.card_questions.map((eachEl) => {
      if (eachEl.question_type === 'accordion_summary')
        accordionSummaryData.push(eachEl);
      if (eachEl.question_type === 'accordion_details')
        accordionDetailsData.push(eachEl);

      return eachEl;
    });

    return [accordionSummaryData, accordionDetailsData];
  }

  function sortByDate(a, b) {
    if (a.date_created > b.date_created) {
      return 1;
    }
    if (a.date_created < b.date_created) {
      return -1;
    }
    return 0;
  }

  const sortedAccordionCards = accordionCards.sort(sortByDate);
  let cardCreatedDt;
    let isGroupedCardHeader = true;
    let isResponseSetAccordion = false;

  return (
    <AccordionWrapper id="accordion-wrapper">
      <AccordionContainer>
        {sortedAccordionCards.map((eachAccCard, index) => {
          const accordionData = getAccordionData(eachAccCard);
          if (response && activeCardIdx < 0)
            isResponseSetAccordion = eachAccCard.id === response.id;
          const cardIdentifierKeys = {
            id: eachAccCard.id,
            case_id: eachAccCard.case_id,
          };
          if (cardCreatedDt === eachAccCard.date_created)
            isGroupedCardHeader = false;
          else isGroupedCardHeader = true;
          cardCreatedDt = eachAccCard.date_created;
          return (
            <>
              {isGroupedCardHeader && (
                <GroupedCardHeader className="grouped-card-header">
                  Date Created: {cardCreatedDt}
                </GroupedCardHeader>
              )}
              <AccorionCard
                key={ eachAccCard.id }
                index={ index }
                questionId={ questionId }
                question={ question }
                sectionIndex={ sectionIndex }
                activeSubsectionId={ activeSubsectionId }
                handleChange={ (curSelection) => handleChange(curSelection) }
                setActiveCardIdx={ (idx) => setActiveCardIdx(idx) }
                isAccordionSelected={
                  isResponseSetAccordion || index === activeCardIdx
                }
                accordionSummaryData={ accordionData[0] }
                accordionDetailsData={ accordionData[1] }
                cardIdentifierKeys={ cardIdentifierKeys }
              />
            </>
          );
        })}
      </AccordionContainer>
    </AccordionWrapper>
  );
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleChange: (response, preventReflexiveCall = false, hasEdit = false) => {
      const questionId = get(props, 'question.question_id', '');
      const auditRequired = get(props, 'question.audit_required', false);
      const displayType = get(props, 'question.display_type', '');
      const currentStatus = get(props, 'theme.pageId', '');
      dispatch(
        updateAnswersArray({
          questionId,
          hasEdit,
          response: response || '',
          sectionIndex: props.sectionIndex,
          propQuestion: props.question,
          preventReflexiveCall,
          auditRequired,
          displayType,
          currentStatus,
        }),
      );
    },
  };
};

export default withTheme(connect(null, mapDispatchToProps)(Accordion));
