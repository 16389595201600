/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import at from 'lodash/at';
import get from 'lodash/get';
import { validateInnerHtml } from '../../utils';
import { getCSSVariables } from '../../cruxComponents/utils';

const Wrapper = styled.div`
  padding: ${({ padding }) => padding?.[0]?.['reviewBreadcrumb-wrapper'] || padding?.[1]?.['reviewBreadcrumb-wrapper']};
  width:100%;
  h1 {
    margin: 0;
    padding: 0;
    // font-weight: bold;
    color: ${({ color }) => color};
    line-height: 1.5;
    height: 50px;
    width: 540px;
    @media (max-width: 640px) {
      line-height: 30px;
      width: 250px;
    }
  }
  position: relative;
`;

const BreadCrumbHeader = styled.h1 `
  display: table-cell;
  vertical-align: middle;
  ${(props)=> props.fontSize && `font-size: ${props.fontSize} !important`};
  font-family: Roboto-Bold;
  @media (min-width: 420px) and (max-width: 640px) {
    font-size: 28px !important;
  }
  @media (max-width: 420px) {
    font-size: 22px !important;
  }
`

const ReviewBreadcrumb = ({
  question,
  theme,
}) => {

  const color = get(theme, 'config.theme.pageOverrides.review.headerColor', '#ffffff');
  const fontSize = get(theme, 'config.theme.pageOverrides.review.breadCrumbFontSize', '40px');
  // CSS Variables
  const cssVariables =  getCSSVariables(theme);
  const padding = at(cssVariables, ['pageOverride.padding', 'global.padding']);

  const {
    question_text: questionText,
  } = question;
  return (
    <Wrapper name='wrapper' color={ color } padding={ padding }>
      <BreadCrumbHeader name='breadcrumb-text' fontSize={ fontSize }>
        <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(questionText) } } />
      </BreadCrumbHeader>
  </Wrapper>
  )
};

ReviewBreadcrumb.propTypes = {
  question: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ReviewBreadcrumb);
