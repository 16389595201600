import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import questions from './questions';
import meta from './meta';
import auth from './auth';
import nList from './nlist';
import config from './config'
import { SET_UID } from '../types';

function uid (state = '', action) {
  switch(action.type) {
    case SET_UID:
      return action.uid;
    default:
      return state;
  }
}

const createRootReducer = (history) =>
	combineReducers({
    auth,
		router: connectRouter(history),
		uid,
		questions,
    meta,
    nList,
    config,
	});

export default createRootReducer;
