import React, { Fragment, useContext } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import Breadcrumb, { Size, State as BreadcrumbState } from './index';
import {
  smCardStyles,
  mdCardStyles,
  lgCardStyles,
  bnCardStyles,
} from './breadcrumbStyles';
import { setCurrentBreadcrumb } from '../../../redux/actions/questions';
import GlobalProgress from '../GlobalProgress';
import { getNextQuestionSet } from '../../../redux/actions';
import HaveQuestions from '../../HaveQuestions';
import { setReflexiveListIndex } from '../../../redux/actions/listQuestion';
import { ListContext } from '../../List/ListContext';

const CrumbsWrapper = styled.div`
	padding-bottom: 0;
	padding-right: 20px;
	margin-top: ${({ override }) => override ? '0' : '160px'};
	background: ${({ override, complete }) => override && complete ? '#ffffff' : 'transparent'};
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	${({ screenViewport }) => (screenViewport !== 'mobile') && `
	  position: sticky;
	  top: 25px;`
  };
	@media (max-width: 767px) {
		padding: ${({ size }) => (size === Size.B ? '0' : '0 5%')};
		padding-bottom: ${({ override }) => override ? '20px' : '0'};
	}
`;

const progressLabelText = (state) => {
  let labelText = '';
  if (state === BreadcrumbState.ACTIVE) labelText = 'In Progress';
  else if (state === BreadcrumbState.INCOMPLETE)
    labelText = 'Not yet Started';
  else if (state === BreadcrumbState.COMPLETED)
    labelText = 'Completed!';
  else labelText = '';
  return labelText;
};

const getTheme = (size) => {
  switch (size) {
    case Size.S:
      return smCardStyles;
    case Size.M:
      return mdCardStyles;
    case Size.L:
      return lgCardStyles;
    case Size.B:
      return bnCardStyles;
    default:
      return smCardStyles;
  }
};

const isActiveState = (state, active) => {
  if (typeof active === 'undefined') {
    return state === BreadcrumbState.ACTIVE;
  }
  return active;
};

/**
 * @description returns the image path of the breadcrumbs if sent from backend else.
 * Precedence: 1. active or inactive urls are present 2. normal image url
 * use local.
 * @param breadcrumb
 * @returns {*|string}
 */
const getImageUrl = (breadcrumb) => {
  const {
    state,
    img_url: imgUrl,
    active_img_url: activeImgUrl,
    inactive_img_url: inactiveImgUrl,
  } = breadcrumb;

  /**
   * For active and complete - return activeImgUrl
   * For incomplete - return inactiveImgUrl
   */
  if (activeImgUrl && inactiveImgUrl) {
    return state === BreadcrumbState.INCOMPLETE
      ? activeImgUrl
      : activeImgUrl;
  }

  // otherwise return imgUrl with a fallback for image stored on the front end server.
  return imgUrl || '/images/svg/placeholder.svg';
};

const BreadcrumbWrapper = (props) => {
  const {
    breadcrumbs,
    imgUrl,
    size,
    uid,
    makeQuestionsCall,
    pageSeqNumber,
    onClick,
    override,
    complete,
    screenViewport,
    haveQuestions,
  } = props;

  const { listState } = useContext(ListContext)

  const breadcrumbClickHandler = (id, breadcrumbId) => {
		const params = {
      uid,
      breadcrumb_id: breadcrumbId,
      breadcrumb_nav_flag: true,
      page_sequence_number: pageSeqNumber,
		};
    if(!listState?.action)
		  makeQuestionsCall(params, id);
  };
  const availableBreadcrumbs = breadcrumbs && breadcrumbs.filter(crumb => crumb.show) || [];
  return (
    <CrumbsWrapper
      name="crumbs-wrapper"
      size={ size }
      override={ override }
      complete={ complete }
      screenViewport={ screenViewport }
    >
      {!override && <GlobalProgress/>}
      {availableBreadcrumbs.map((breadcrumb) => (
        <Fragment key={ breadcrumb.id }> 
          {!breadcrumb.is_hidden && 
            <Breadcrumb
              override={ override }
              id={ breadcrumb.id }
              breadcrumb={ breadcrumb }
              imgUrl={ getImageUrl(breadcrumb) || imgUrl || 'https://bit.ly/38R2acR' }
              isActive={ isActiveState(breadcrumb.state, breadcrumb.active) }
              breadcrumbTheme={ getTheme(size) }
              size={ size }
              progressLabel={ progressLabelText(breadcrumb.state) }
              onClick={ size === Size.B ? onClick : () => breadcrumbClickHandler(breadcrumb.id, breadcrumb.breadcrumb_id) }
              sectionCompleted={ 5 - 1 }
              leftBorderWidth = { breadcrumb?.style?.left_border?.width }
              leftBorderColor = { breadcrumb?.style?.left_border?.color_code }
              { ...(listState?.action && { isClickableCard: !listState?.action }) }
            />
          }
        </Fragment>
      ))}
      {(haveQuestions && !complete) && <HaveQuestions width={ screenViewport==='mobile' ? '100%' : '80%' } layout='double' haveQuestions={ haveQuestions } />}
    </CrumbsWrapper>
  );  
};

const mapStateToProps = ({ questions, meta, uid }, { overrideBreadcrumbs, complete }) => {
  const pageSeqNumber = get(questions, 'currentPageSeqNumber', -1);
  let breadcrumbs = questions.breadcrumbs.breadcrumbList;
  const { haveQuestions } = questions;
  if (meta.screenViewport === 'mobile') {
    if (overrideBreadcrumbs && overrideBreadcrumbs.length) {
      breadcrumbs = overrideBreadcrumbs;
    } else {
      breadcrumbs = []
    }
  }
  return {
    breadcrumbs,
    screenViewport: meta.screenViewport,
    uid,
    pageSeqNumber,
    override: !!overrideBreadcrumbs,
    complete,
    haveQuestions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    makeQuestionsCall: (params, id) => {
      dispatch(setReflexiveListIndex(-1, ''));
      dispatch(setCurrentBreadcrumb(id));
      dispatch(getNextQuestionSet('/questions', params, {
        ...params,
        breadcrumbNavigation: true,
      }));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbWrapper);
