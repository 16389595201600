/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getCurrentQuestionSet } from '../../redux/selector/questions';
import { getListProps } from '../../redux/selector/listQuestion';
import ScrollToSection from '../../ScrollToSection';
import Cover from '../StyledComponents/MaskCover/Index';
import QuestionRenderer from '../QuestionRenderer';
// const ScrollToSection = lazy(() => import( '../../ScrollToSection'))
// const QuestionRenderer = lazy(() => import( '../QuestionRenderer'))

function Section({
	questionList,
	sectionIndex,
	processing,
	listDisplay,
	isList,
	isReflexive,
	validate,
  pageId,
  theme,
  breadcrumbNavigation,
  changeMode,
  isUpdateMode,
}) {
	let collapse = true;
	if( questionList && questionList.length > 0) {
	  const question = questionList[0];
	  collapse = get(question, 'collapse', false); 
  }
  
  const {
    config: {
      layout,
    },
  } = theme;

	const skipScroll = isReflexive ||
    validate  ||
    breadcrumbNavigation ||
    (layout[pageId] && layout[pageId] === 'single');

	return (
		<>
      <ScrollToSection
        isUpdateMode = { isUpdateMode }
        collapse = { collapse }
        skipAutoScroll={ skipScroll }
        isList={ isList }
        pageId={ pageId }
        listDisplay={ listDisplay }
        sectionIndex={ sectionIndex }
      />
			{processing && <Cover name="cover" />}
      <Fragment key={ sectionIndex }>
				<QuestionRenderer
					pageId={ pageId }
					questionList={ questionList }
					sectionIndex={ sectionIndex }
					changeMode={ changeMode }
				/>
		</Fragment>
    </>
	);
}

const mapStateToProps = (state, props) => {
  const listSelector = getListProps(state);
  const { isList, listDisplay, addButtonText } = listSelector(
    props.sectionIndex,
  );
  const { otherParams } = getCurrentQuestionSet(state);

  const isReflexive = get(state, 'questions.isReflexive', false);
  const validate = get(state, 'questions.validate', false);
  const isUpdateMode = get(otherParams, 'show_edit_page_flag', false);

  return {
    isList,
    listDisplay,
    isReflexive,
    validate,
    addButtonText,
    isUpdateMode,
    breadcrumbNavigation: state.questions.breadcrumbNavigation,
  };
};

export default withRouter(withTheme(connect(mapStateToProps, null)(Section)));
