/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import { smoothScrollTo } from './util';


const ScrollToSection = function Scroller({
	sectionIndex,
	pageId,
	listDisplay,
	isList,
	skipAutoScroll,
	isUpdateMode,
	collapse,
}) {
	const ref = useRef(null);
 
	useEffect(() => {

		if (skipAutoScroll && pageId !== 'signature') {
			return;
		}
		if (sectionIndex === 0 && isList && listDisplay) {
			return;
		}
		if (sectionIndex === 0) {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 100);
			return;
		}
		setTimeout(() => {
			const el = ref.current;
			if(isUpdateMode) {
				if(collapse) {
				  return;
				}
			}
			if (!el ) {
				return;
			}
			const elementPosition = get(el, 'offsetTop', '');
			// disable IE check
			const isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent) && false;
			if (isIE11) {
				smoothScrollTo(elementPosition - 80, 400);
			} else {
				el.scrollIntoView({
					behavior: 'smooth',
				});
			}
		}, 100);
	}, [sectionIndex]);
	if (pageId === 'quote' || pageId === 'review') {
		return null;
	}
	return (
		<div style={ { position: 'relative', top: '-200px' } } ref={ ref } />
	);
};

ScrollToSection.propTypes = {
	sectionIndex: PropTypes.number.isRequired,
	pageId: PropTypes.string.isRequired,
	isList: PropTypes.bool.isRequired,
	skipAutoScroll: PropTypes.bool.isRequired,
};

export default ScrollToSection;
