import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { goToPreviousBreadCrumb } from '../../../redux/actions/questions';

const ReviseButtonWrapper= styled.div`
text-decoration:underline;
margin-bottom:16px;
font-size:14px;
  span {
      cursor: pointer;
  }
`

const ReviseButton= ({ reviseButtonLabel , handleBackLink })=>{
    return (
       <ReviseButtonWrapper>
            <span role = "button" onClick={ handleBackLink } tabIndex='0'>{reviseButtonLabel}</span>
        </ReviseButtonWrapper>
    )
}



function mapDispatchToProps (dispatch,props){
    const { sectionData } = props
    const sectionIndex = sectionData ?
    sectionData.length -1 :
    props.sectionIndex;

    return {
        handleBackLink: ()=>{

            dispatch(goToPreviousBreadCrumb(sectionIndex))
          },
    }

}
export default connect(null,mapDispatchToProps)(ReviseButton);