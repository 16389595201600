/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies,react/no-danger */
import React from 'react';
import get from 'lodash/get';
import at from 'lodash/at';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Alert } from 'crux';
import TEXT_STYLE_MAP from '../../../constants/textStyleMap';
import { parseHtml } from '../../../utils';
import { getCSSVariables } from '../../../cruxComponents/utils';

const H1 = styled.h1`
  color: ${({ color }) => color};
  font-size: 36px;
`;

const H2 = styled.h2`
  color: ${({ color }) => color};
  font-size: 36px;
  margin-bottom: ${({ activeSubsectionId }) => activeSubsectionId==='completed'?'40px':'20px'};
  font: ${({ font }) => font};
  & div.welcome {
	> hr {
		border-top: 1px solid black;
	}
    > h2 {
      font-size: 35px;
    }
    > h4 {
      font: 20px / 35px Roboto;
	  & a {
		  color: #3cc06f;
	  }
    }
    > ul > li {
      font: 20px Roboto;
    }
  }
`;

const P = styled.p`
	font: ${({ font }) => font};
`;

const H3 = styled.h3`
  color: ${({ color }) => color};
  font-size: 18px;
  margin-bottom: 60px;
`;

const Error = styled.h6`
  color: rgb(255, 0, 0, 1);
`;
const Label = styled.div`
	${(props) => props.isAsterisk ? `
	&:before{
	content:'*';
	margin:5px 5px 0 0;
	}` : ''};
	b {
		font-weight: unset;
	}
	& div.welcome {
		>h2 {
			font-size: 28px;
			margin: 10px 0 30px 0px;
			font-family: Roboto-Bold;
		}
		> h4{
			font-size: 23px;
			margin: 25px 0px;
			font-family: Roboto-Bold;
			> a {
				color:#00465a;
				border-bottom: 1px solid #00465a;
			}
		}
		> ul, p {
			font-size: 20px;
            line-height: 35px;
			font-family: Roboto;
			> b{
				font-family: Roboto-Bold;
				color: rgb(0,70,90);
			}
		}
	}
	color: ${({ color }) => color};
	font-size: 14px;
	line-height: 21px;
	margin-bottom: ${({ radioGroupLabel }) => radioGroupLabel ? '0px' : '15px'};
	font-size:${({ labelText }) => labelText.fontSize};
	font-weight:${({ labelText }) => labelText.fontWeight};
	font-family:${({ labelText, fontWeight }) => labelText.fontFamily || (fontWeight <= 500 && 'Roboto') || 'Roboto-Bold'};
	@media only screen and (max-width: 768px) {
		line-height: 25px;
	}
	${(props)=>props.textWithResult && `
	display: flex;
	flex-direction: column;
    align-items: end;
	width: 95%;
	// font-weight : bold;
	` }
	${(props)=> props.errorMessage && `
	> span.error{
		color:rgb(255, 0, 0, 1)
	}
	`}
	.costDisclosureHead > h4,
	.costDisclosureFooter > h4 {
		color: rgb(51, 51, 51);
		line-height: 25px;
		font-size: 17px;
		font-family: Roboto;
	}
	.costDisclosureBody > ul{
		font-size: 17px;
    	line-height: 25px;
    	font-family: Roboto;
	}
	// font:${({ baseFont }) => baseFont};
	// font: ${({ font }) => font?.[0]?.['textElement-labelText'] || font?.[1]?.['textElement-labelText']};
`;

const DefaultTextStyles = styled.p`
	a {
	  color: #00465A !important;
	  text-decoration:underline ;
	}

	a:hover {
	background-color: #D9E3E6;
	text-decoration: underline;
	}
`

function TextElement(props) {
	const { text, styleType, handlePopup, elementTheme, theme, font, pFont, id, isAsterisk,errorMessage,textWithResult, questionObj, activeSubsectionId } = props;
	const { headingColor, labelColor } = get(props, 'theme.config.theme.global.text');
	const {
		pageId,
		config: {
		  theme: {
			pageOverrides,
		  },
		},
	  } = theme;

	const fontFamily = {
		small: 'Roboto',
		medium: 'Roboto-Medium',
		bold: 'Roboto-Bold',
	}

	let labelText = get(pageOverrides[pageId],'themeConfiguration.labelText', elementTheme || {});
	const labelTextProperties = get(questionObj,'properties.text', {});
	if(Object.keys(labelTextProperties).length !== 0){
		const customFont = labelTextProperties?.font;
		labelText = { ...labelText, fontFamily: fontFamily[customFont]  }
	}

	if(questionObj?.presentation_type === 'alert'){
		return <Alert message={ questionObj.question_text } type="error" />
	}

	// CSS Variables
	const cssVariables =  getCSSVariables(theme);
	const _font = at(cssVariables, ['pageOverride.font', 'global.font']);
	const fontWeight = get(questionObj, 'properties.font.weight', undefined)

	if (!text) {
		return null;
	}
	switch (TEXT_STYLE_MAP[styleType]) {
		case 'H1':
			return (
				<H1 id={ id } color={ headingColor }>
					<span>{parseHtml(text, handlePopup)}</span>
				</H1>
			);
		case 'H2':
			return (
				<H2 id={ id } color={ headingColor } font={ font } activeSubsectionId={ activeSubsectionId }>
					<span>{parseHtml(text, handlePopup)}</span>
				</H2>
			);
		case 'H3':
			return (
				<H3 id={ id } color={ headingColor }>
					<span>{parseHtml(text, handlePopup)}</span>
				</H3>
			);
		case 'LABEL':
			return (
				<Label id={ id } color={ labelColor } labelText={ labelText } fontWeight={ fontWeight } font={ _font } { ...props }>
					{textWithResult?<>
						<span>{parseHtml(textWithResult, handlePopup,isAsterisk)}</span>
					</>:<span>{parseHtml(text, handlePopup,isAsterisk)}</span>}
					{errorMessage &&  <span className='error'>{parseHtml(errorMessage,handlePopup,isAsterisk)}</span>}
				</Label>
			);
		case 'ERROR':
			return (
				<Error id={ id }>
					<span>{parseHtml(text, handlePopup)}</span>
				</Error>
			);
		case 'NORMAL':
			return (
				<P font={ pFont }>
					<span>{parseHtml(text, handlePopup)}</span>
				</P>
			);
		default:
			return (
				<DefaultTextStyles id={ id }>
					<span>{parseHtml(text, handlePopup)}</span>
				</DefaultTextStyles>
			);
	}
}

TextElement.defaultProps = {
	id: null,
}

TextElement.propTypes = {
  styleType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default withTheme(TextElement);
