import React from 'react';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../Components/UIElements/Breadcrumb/wrapper';
import GlobalProgress from '../../Components/UIElements/GlobalProgress';
import LeftPanelQuestions from '../../Pages/LandingPage/leftPanelQuestions';

const SmallScreenWrapper = styled.div`
  background: ${({ background }) => background || '#f7f7f7'};
  width: 100%;
  top: 0;
  z-index: 100;
`;

const NormalScreenWrapper = styled.div`
  background: ${({ background }) => background || '#f7f7f7'};
  width: ${({ width }) => width};
  margin-top: 0;
  position: relative;
  z-index: 99;
`;

const Left = ({ screenViewport, layout, leftData, leftSidebarColor }) => {
  if ((screenViewport === 'mobile') && layout === 'double' && !leftData.length) {
    return (
      <SmallScreenWrapper
        background={ leftSidebarColor }
        screenViewport={ screenViewport }
        data-name='left'
      >
        <GlobalProgress screenViewport={ screenViewport } />
      </SmallScreenWrapper>
    )
  }
  if (leftData.length) {
    return (
      <NormalScreenWrapper
        background={ leftSidebarColor }
        screenViewport={ screenViewport }
        data-name='left'
        width={ (screenViewport === 'mobile') && leftData.length ? '100%' : '33%' }
      >
        <LeftPanelQuestions leftData={ leftData } />
      </NormalScreenWrapper>
    )

  }
  if (layout === 'double') {
    return (
      <NormalScreenWrapper
        background={ leftSidebarColor }
        screenViewport={ screenViewport }
        data-name='left'
        width={ (screenViewport === 'mobile') && leftData.length ? '100%' : '25.197%' }
      >
        <BreadcrumbWrapper />
      </NormalScreenWrapper>
    )
  }
  return null;
}

export default Left;
