import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    justify-content: space-around;
    @media (max-width: 768px) {
        justify-content: space-between;
        overflow: auto;
    }
`;

export const HeaderContentWrapper = styled.div`
    display: inline-flex;
    gap: 4px;
`;

export const TopHeadingWrapper = styled.div`
    & > p {
        display: flex;
        justify-content: center;
    }
`;

export const HeaderContent = styled.h2`
    font: ${({ font }) => font};

`;

export const HorizontalRowBold = styled.hr`
    border-top: 2px solid black;
`;

export const HorizontalRowNormal = styled.hr`
    border-top: 1px solid black;
    &:last-child {
        border-top: none;
    }
`;
