// eslint-disable-next-line import/no-cycle
/* eslint-disable consistent-return */
import React, { useContext } from 'react';
import get from 'lodash/get';
import { Tooltip } from 'crux';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { ReviewLabel } from '../Components/UIElements';
import TextElement from '../Components/UIElements/TextElement';
import Table from '../Components/UIElements/Table';
import Pdf from '../Components/UIElements/Pdf';
import Input from '../cruxComponents/Input';
import Button from '../cruxComponents/Button';
import MultiSelect from '../cruxComponents/MultiSelect';
import SingleSelect from '../cruxComponents/SingleSelect';
import SelectOption from '../cruxComponents/SelectOption';
import DatePicker from '../cruxComponents/DatePicker';
// eslint-disable-next-line import/no-cycle
import Modal from '../Components/UIElements/Modal';
import DataSelector from '../cruxComponents/DataSelector';
import VideoEmbedder from '../Components/UIElements/VideoEmbedder';
import GroupQuestionText from '../Components/UIElements/TextElement/GroupQuestionText';
import { getPopupQuestions } from '../redux/selector/validations';
import { setPopupDetails } from '../redux/actions/questions';
import { getTooltipProps } from '../cruxComponents/utils';
import { getPopupDetails } from '../utils';
import { ListContext } from '../Components/List/ListContext';
import { listDependantQHelper } from '../redux/actions/listQuestionHelper';
import Accordion from '../Components/Accordion';

const TooltipWrapper = styled.div`
  display: flex;
  justify-content:${({ pageProps })=>pageProps&&pageProps.compareQuotePosition};
`;
const TooltipIcon = styled.div`
  margin: 5px 10px;
`;
const GetElementWrapper = styled.div`
${({ questionType }) => questionType === 'document' && `
  overflow: hidden;
`}
${({ questionType }) => questionType === 'text_area' && `
  margin-bottom: 12px;
`}
`

let reflexiveQIds = []

/**
 * @description create an extra layer of div to add (if needed) any attribute
 * to any of the form elements. The reason of creating this wrapper is to have
 * a parent element which can take care of all the instances of execution of
 * GetElementFunc.
 */
const getElement = ({
  questionObj,
  sectionIndex,
  isEditMode = false,
  listParentId = '',
  listIndex = null,
  rootListIndex = null,
  rootListId = '',
  popupQuestions,
  updatePopupDetails,
  theme,
  selectedOptions,
  activeSubsectionId,
  curBreadcrumbSections,
  changeMode,
}, listState) => {
  const handlePopup = (e) => {
    // the preventDefault will prevent toggling of checkbox when label is clicked
    e.preventDefault();
    if(popupQuestions && updatePopupDetails) {
      const newPopupDetails = getPopupDetails(questionObj, popupQuestions);
      updatePopupDetails(newPopupDetails);
    }
  };
  const presentationType = questionObj.presentation_type
    ? questionObj.presentation_type
    : 'questions';
  const {
    display_type: displayType,
    hint_title: tooltipTitle,
    hint_text: tooltipBody,
    hint_html: tooltipHTML,
    tooltip,
    validations,
    is_hidden: isHiddden,
  } = questionObj;
  const tooltipProps = getTooltipProps(
    tooltipTitle,
    tooltipBody,
    tooltipHTML || tooltip,
  );
  const {
    screenViewport,
    pageId,
    config: {
      theme: {
        pageOverrides,
      },
    },
  } = theme;

  let adjacentQIdMatch = listState?.listParentQId !== questionObj.question_id

  if(listState?.curListParentQs?.length > 1){
    const curListParentQsRegex = new RegExp(`^(${listState?.curListParentQs?.join('|')})`, 'i');
    if(curListParentQsRegex.test(questionObj.question_id)) adjacentQIdMatch = false;
    else adjacentQIdMatch = true;
  }

  if(listState.listParentId !== undefined)
    if(questionObj.question_id === listState.listParentQId)
      adjacentQIdMatch = !(questionObj.question_id === listState?.listParentQId)

  if(listState.listParentId !== undefined || questionObj.parent_id !== undefined)
    if(questionObj.parent_id === listState.listParentQId){
      adjacentQIdMatch = !(questionObj?.parent_id === listState?.listParentQId)
      reflexiveQIds.push(questionObj.question_id);
    }

  reflexiveQIds = [...new Set(reflexiveQIds)];
  const reflexiveQIdsRegex = new RegExp(`^(${reflexiveQIds.join('|')})`, 'i');

  if(reflexiveQIds.length > 0 && reflexiveQIdsRegex.test(questionObj?.parent_id)) {
    adjacentQIdMatch = false
    reflexiveQIds.push(questionObj.question_id);
  }

  if(listState.listId !== undefined && listState?.action){
    if(questionObj?.question_id?.match(/BH_IsBGA_SGP/i)) adjacentQIdMatch = true;
  }

  let isListActionBasedDisabled = !listParentId && questionObj?.child_questions && listState?.action && adjacentQIdMatch

  if(questionObj?.question_id?.match(/BH_IsBGA_SGP/i)){
    isListActionBasedDisabled = !listParentId && listState?.action && adjacentQIdMatch
  }

  const shouldDisplayQ = listDependantQHelper(questionObj, curBreadcrumbSections)

  if(!shouldDisplayQ) return;

  if(isHiddden) return;

  // updateAnswersArray({
  //   questionId,
  //   hasEdit,
  //   response: response?.id || '',
  //   questionResponse: response,
  //   sectionIndex: props.sectionIndex,
  //   propQuestion: props.question,
  //   preventReflexiveCall,
  //   listParentId: props.listParentId,
  //   listIndex: props.listIndex,
  //   auditRequired,
  //   displayType,
  //              currentStatus,
  // }),

  // Accessibility Labels
  const { hasOptionalLabel, optionalText } =
    get(theme,'config.features.a11y', { hasOptionalLabel: false, optionalText: '' });
  const convertToDropdown =
    get(theme,'config.theme.components.radio.convertToDropdown', false);
  let optionalvalue= '';
  if (hasOptionalLabel) {
		const isRequired = get(validations, 'required.value', false);
    optionalvalue = isRequired ? '' : optionalText;
  }
  const pageProps=pageOverrides[pageId]
  if (presentationType === 'review' || displayType === 'html') {
    return (
      <ReviewLabel
        questionObj={ questionObj }
        key={ questionObj.question_id }
        handleLabelChange={ () => {} }
        tooltipProps={ tooltipProps }
        sectionIndex={ sectionIndex }
        pageId={ pageId }
      />
    );
  }

  switch (questionObj.question_type) {
    case 'text':
    case 'password':
    case 'number':
    case 'text_area':
      return (
        <Input
          question={ questionObj }
          optionalText = { optionalvalue }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
          isEditMode={ isEditMode }
          listParentId={ listParentId }
          listIndex={ listIndex }
          rootListIndex = { rootListIndex }
          rootListId = { rootListId }
          errorMessagePercentage = { (questionObj?.validations?.error_message_percentage) || null }
          changeMode = { changeMode }
          isDisabled = { isListActionBasedDisabled }
        />
      );
    case 'date':
      return (
        <DatePicker
          question={ questionObj }
          optionalText = { optionalvalue }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
          isEditMode={ isEditMode }
          listParentId={ listParentId }
          listIndex={ listIndex }
          rootListIndex = { rootListIndex }
          rootListId = { rootListId }
          changeMode = { changeMode }
          isDisabled = { isListActionBasedDisabled }
        />
      );
    case 'button':
      return (
        <Button
          question={ questionObj }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
          listIndex={ listIndex }
          isEditMode={ isEditMode }
          listParentId={ listParentId }
          activeSubsectionId = { activeSubsectionId }
        />
      );
    case 'video':
      return (
        <VideoEmbedder
          questionText={ questionObj.question_text }
          videoUrl={ questionObj.video_url }
        />
      );
    case 'singleselection':
       // if the number of options are greater than 3 , we will go with displaying dropdown in screenviewport mobile
      if (
        !(convertToDropdown &&
          questionObj.response_options.length > 3 &&
          screenViewport === 'mobile' &&
          questionObj.display_type !== 'cards') &&
        ((questionObj.display_type && questionObj.display_type === 'radio') ||
          questionObj.display_type === 'radio_button' ||
          questionObj.display_type === 'cards' ||
          questionObj.display_type === 'default_radio' ||
          questionObj.display_type === 'product_card')
      ) {
        return (
          <SingleSelect
            question={ questionObj }
            optionalText = { optionalvalue }
            key={ questionObj.id }
            sectionIndex={ sectionIndex }
            isEditMode={ isEditMode }
            listParentId={ listParentId }
            listIndex={ listIndex }
            rootListIndex = { rootListIndex }
            rootListId = { rootListId }
            changeMode = { changeMode }
            isDisabled = { isListActionBasedDisabled }
          />
        );
      }
      if(questionObj?.display_type === 'accordion'){
        return(
          <Accordion
            question={ questionObj }
            key={ questionObj.id }
            sectionIndex={ sectionIndex }
            activeSubsectionId = { activeSubsectionId }
          />
        )
      }
      if (
        questionObj.display_type &&
        questionObj.display_type === 'data_selector'
      ) {
        return (
          <DataSelector
            question={ questionObj }
            sectionIndex={ sectionIndex }
          />
        );
      }
      if (questionObj.display_type && questionObj.display_type === 'modal') {
        return (
          <Modal
            key={ questionObj.question_id }
            questionObj={ questionObj }
            response={ questionObj.response }
            sectionIndex={ sectionIndex }
          />
        );
      }
      return (
        <SelectOption
          question={ questionObj }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
          isEditMode={ isEditMode }
          listParentId={ listParentId }
          listIndex={ listIndex }
          rootListIndex = { rootListIndex }
          rootListId = { rootListId }
          changeMode = { changeMode }
          type="D"
          selectedOptions={ selectedOptions }
          isDisabled = { isListActionBasedDisabled }
        />
      );
    case 'modal':
      return (
        <Modal
          key={ questionObj.question_id }
          questionObj={ questionObj }
          response={ questionObj.response }
          sectionIndex={ sectionIndex }
        />
      );
    case 'multiselection':
      if (questionObj.display_type === 'checkbox') {
        return (
          <MultiSelect
            question={ questionObj }
            isEditMode={ isEditMode }
            sectionIndex={ sectionIndex }
            handlePopup={ handlePopup }
            listParentId={ listParentId }
            listIndex={ listIndex }
            isDisabled = { isListActionBasedDisabled }
          />
        );
      }
      return (
        <SelectOption
          question={ questionObj }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
          isEditMode={ isEditMode }
          listParentId={ listParentId }
          listIndex={ listIndex }
          rootListIndex = { rootListIndex }
          rootListId = { rootListId }
          isDisabled = { isListActionBasedDisabled }
          type="M"
        />
      );
    case 'autocomplete-text':
      return (
        <SelectOption
          question={ questionObj }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
          type="A"
        />
      );
    case 'document':
      return (
        <Pdf
          question={ questionObj }
          key={ questionObj.question_id }
          sectionIndex={ sectionIndex }
        />
      );
    case 'popup':
      if (questionObj.display_type === 'table') {
        return (
          <Table
            question={ questionObj }
            key={ questionObj.question_id }
          />
        )
      }
      return null;
    case 'group':
      return (
        <GroupQuestionText
          key={ questionObj.question_id }
          questionObj={ questionObj }
          handlePopup={ handlePopup }
        />
      );
    case 'label':
    default:
      return (
        <TooltipWrapper pageProps={ pageProps }>
          { questionObj.question_text ?
          <TextElement
            key={ questionObj.question_id }
            id={ `${questionObj.question_id}-${questionObj.question_type}-${questionObj.display_type}-label` }
            styleType={
              questionObj.presentation_type || questionObj.display_type
            }
            questionObj = { questionObj }
            text={ questionObj.question_text }
            textWithResult= { questionObj.question_text.includes('Total Allocations')?(`${questionObj.question_text  } - ${`${questionObj.response}` || 0}`): '' }
            sectionIndex={ sectionIndex }
            handlePopup={ handlePopup }
            errorMessage = { (questionObj?.validations?.error_message_percentage) || null }
            activeSubsectionId = { activeSubsectionId }
          /> : null }
          {tooltipProps.hasTooltip && (
            <TooltipIcon>
              <Tooltip
                ariaLabel={ `${questionObj.question_text} tooltip` }
                name="tool-tip" { ...tooltipProps }
              />
            </TooltipIcon>
          )}
        </TooltipWrapper>
      );
  }
};

const GetElementFunc = (props) => {
  const klass = get(props, 'questionObj.className', '');
  const questionType = get(props, 'questionObj.question_type');
  const displayType = get(props, 'questionObj.display_type');
  const questionId = get(props, 'questionObj.question_id');

  const { listState } = useContext(ListContext)

  return (
    <GetElementWrapper data-name="get-element-wrapper" id={ `${questionType}-${displayType}-container-${questionId}` } className={ klass } questionType={ questionType }>
      {getElement(props, listState)}
    </GetElementWrapper>
  );
};

const mapStateToProps = (state, props) => {
  const popupQuestions = getPopupQuestions(state);
  const { questions: { sections, activeSubsectionId } } = state;
  let curBreadcrumbSections = [];
  if (sections) {
    const { sectionIndex } = props;
    if (activeSubsectionId && sectionIndex !== undefined) {
      curBreadcrumbSections = sections[activeSubsectionId]
    }
  }
  return {
    popupQuestions,
    activeSubsectionId: state?.questions?.activeSubsectionId,
    curBreadcrumbSections,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePopupDetails: (payload) => {
      dispatch(setPopupDetails(payload));
    },
  }
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(GetElementFunc));