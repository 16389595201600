import { SET_DESTINATION_URL, SET_LOGIN_CONFIG } from '../types';

const initialState = {
  login: {},
  destinationURL: '/',
}

const auth = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DESTINATION_URL:
      return {
        ...state,
        destinationURL: payload,
      };
    case SET_LOGIN_CONFIG:
      return {
        ...state,
        login: payload,
      }
    default:
      return state;
  }
}

export default auth;
