/* eslint-disable import/no-cycle */
import Axios from 'axios';
// import axiosRetry from 'axios-retry';
import get from 'lodash/get';
import { getAccessToken } from '@sureifylabs/ext-frontend-identity';
import { convertQueryStringToObject } from '.';
import LocalStorageServices from './localStorageServices';
import SessionStorageServices from './sessionStorageServices';
import host from './host';

const { REACT_APP_BASE_URL } = host();

export const axiosInstance = Axios.create({
  baseURL: REACT_APP_BASE_URL,
  method: 'post',
  timeout: 1000 * 1000, // timeouts, if API is not resolved in 10 seconds
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
  },
});

// axiosRetry(axiosInstance, {
//   retries: 3, // number of retries
//   shouldResetTimeout: true, // resets axios timeout between retries
//   retryDelay: (retryCount) => {
//     // eslint-disable-next-line no-console
//     console.log(`Retry Attempt: ${retryCount}`);
//     return (2 ** retryCount + Math.random()) * 1000; // time interval between retries
//   },
//   retryCondition: (error) => {
//     // retry in case of network error or internet disconnections
//     // if retry condition is not specified, by default idempotent requests are retried
//     const isNetworkError = error.message === 'Network Error'; // Happens when server disconnection happens
//     const isConnectionAborted = error.code === 'ECONNABORTED'; // Happens when axios timeout is reached
//     const responseCode = get(error, 'response.status');
//     const isServerError = Number(responseCode)%500 <= 11; // Checks for server error codes
//     return isConnectionAborted || isNetworkError || !navigator.onLine || isServerError;
//   },
// });


axiosInstance.interceptors.request.use(
 async request => {
    if (request && request.data) {
      const retryState = request['axios-retry'] || {};
      let requestData = null;
      try {
        requestData = JSON.parse(request.data);
      } catch {
        requestData = request.data;
      }
      let accessToken;
      let refreshToken;
      const searchParams = window.location.search.replace('?', '');
      const queryObject = convertQueryStringToObject(searchParams);
      const isLoggedInWithSSO = LocalStorageServices.getLocalValue('isLoggedInWithSSO');
      if(isLoggedInWithSSO === 'true') {
        try {
          accessToken = await getAccessToken();
          // if refreshed access token is returned, new token will be replaced
          LocalStorageServices.setToken(accessToken);
        } catch (e) {
					localStorage.clear();
          window.location.assign('/authError');
        }
      } else {
        accessToken = LocalStorageServices.getAccessToken();
        refreshToken = LocalStorageServices.getRefreshToken();
      }
      Object.keys(queryObject).forEach(key => {
        try {
          if (!(key in requestData)) {
            requestData[key] = queryObject[key];
          }
        } catch (e) {
          console.error(e);
        }
      });
      // adding this condition as FormData object cannot be parsed
      if (!(request.data instanceof FormData)) {
        try {
          request.data = JSON.parse(JSON.stringify(request.data))
        } catch {
          request.data = requestData;
        }
      }

      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
      if (refreshToken) {
        request.headers.refreshToken = refreshToken;
      }
      if (retryState) {
        request.headers['X-Retry-Count'] = retryState.retryCount || 0;
      }
    }
    return request;
  },
  err => Promise.reject(err),
);

/**
 *  @description Axios Interceptor to fetch new access token via
 * refresh token (if the response code comes as unauthorized 401).
 */
axiosInstance.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;
    const uid = get(response, 'data.response.other_params.uid', '');

    // Perform retry operation if present in other_params
    if (originalRequest.url.includes('/questions')) {
      const retry = get(response, 'data.response.other_params.retry', 0);
      if (retry) {
        originalRequest._retry = true;
        originalRequest._retryInterval = retry;
      }
    }

    // Sets identity based on if session is valid or not for that uid.
    const identity = SessionStorageServices.getIdentity(uid);
    originalRequest._identity = identity === 'invalid' ? 'invalid' : 'valid';

    return response;
  }, (error) => {
    const originalRequest = error.config;

    // If the refresh token is not valid
    if (error.response &&
      (error.response.status === 400 || error.response.status === 401) &&
      originalRequest.url.includes('/auth/refresh_token'))
    {
      console.error(error);
      LocalStorageServices.clearToken();
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    if (response.data && response.data.response) {
      // SAML Login Redirection
      const loginRedirectUrl = get(response, 'data.response.loginRedirectUrl', '');
      if (loginRedirectUrl) {
        window.location.href = loginRedirectUrl;
      }
    }

    if (response.data && response.data.response) {
      const { tag_manager: tagManager } = get(
        response,
        'data.tag_manager.pages',
        [],
      );
      const { tag_manager_fields: tagManagerFields } = response.data;

      if (tagManager && tagManager.length > 0) {
        window.initialTagManager = tagManager;
      } else {
        window.initialTagManager = window.initialTagManager || [];
      }

      window.uniqueTransactionId =
        response.data.transactionId ||
        (response.data.tag_manager &&
          response.data.tag_manager.transaction_id) ||
        window.uniqueTransactionId;
      if (tagManagerFields) {
        if (
          tagManagerFields.gtmQuestionsList &&
          tagManagerFields.gtmQuestionsList.length > 0
        ) {
          window.gtmQuestionsList = tagManagerFields.gtmQuestionsList;
        } else {
          window.gtmQuestionsList = window.gtmQuestionsList || [];
        }

        if (
          tagManagerFields.virtualPageVisitsQuestionsList &&
          tagManagerFields.virtualPageVisitsQuestionsList.length > 0
        ) {
          window.virtualPageVisitsQuestionsList =
            tagManagerFields.virtualPageVisitsQuestionsList;
        } else {
          window.virtualPageVisitsQuestionsList =
            window.virtualPageVisitsQuestionsList || [];
        }

        if (
          tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered &&
          tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered.length >
            0
        ) {
          window.virtualPageVisitsQuestionsWithAnsConsidered =
            tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered;
        } else {
          window.virtualPageVisitsQuestionsWithAnsConsidered =
            window.virtualPageVisitsQuestionsWithAnsConsidered || [];
        }
      }
    } else {
      window.initialTagManager = window.initialTagManager || [];
      window.gtmQuestionsList = window.gtmQuestionsList || [];
      window.virtualPageVisitsQuestionsList =
        window.virtualPageVisitsQuestionsList || [];
      window.virtualPageVisitsQuestionsWithAnsConsidered =
        window.virtualPageVisitsQuestionsWithAnsConsidered || [];
    }
    return response;
  },
  err => Promise.reject(err),
);

export const TEST = 'THIS IS A FAKE EXPORT, NEED TO REFACTOR BEFORE MAKING axiosInstance DEFAULT EXPORT';
