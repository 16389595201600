export const mockAuthQuestions = {
  data: {
    code: 200,
    status: true,
    response: {
      other_params: {
        prev_button_state: 'questions',
        next_button_text: 'Login',
        prev_button_text: 'Back',
        is_prev_btn_hidden: true,
        is_next_btn_hidden: false,
        session_id: '',
        uid: '',
        type: 'plain',
        current_status: 'login',
      },
      breadcrumbs: [
        {
          breadcrumb_id: 'LoginPage',
          section_id: '21',
          title: 'Login Page',
          state: 'active',
          source_id: 1,
          img_url:
            'https://acqcdn.s3.amazonaws.com/allstate/breadcrumb/LandingPage.svg',
          show: false,
          clickable: false,
          section_progress: {
            total_sections: 1,
            current_section_number: 0,
          },
        },
      ],
      data: {
        questionnarie: {
          page_sequence_number: 1,
          questions: {
            question_id: 'LoginPage',
            question_type: 'group',
            display_type: 'breadcrumb',
            orientation: '',
            question_text: 'Login Page',
            index: 1,
            response: '',
            sequence_number: 1,
            is_reviewable: true,
            is_editable: true,
            is_edit_icon_visible: false,
            is_hidden: false,
            presentation_type: 'hide',
            validations: [],
            properties: [],
            response_options: [],
            parent_question_answer: [],
            question_description: '',
            child_questions: false,
            question_status: '',
            question_status_message: '',
            hint_text: '',
            sureify_meta: {
              source_id: 1,
            },
            questions: [
              {
                question_id: 'LoginPageGroup',
                question_type: 'group',
                display_type: 'questions_group',
                orientation: '',
                question_text: 'Login to Continue',
                index: 1.1,
                response: '',
                sequence_number: 1,
                is_reviewable: true,
                is_editable: true,
                is_edit_icon_visible: false,
                is_hidden: false,
                presentation_type: '',
                validations: [],
                properties: '',
                response_options: [],
                parent_question_answer: [],
                question_description: '',
                child_questions: false,
                question_status: '',
                question_status_message: '',
                hint_text: '',
                sureify_meta: {
                  source_id: 1,
                },
                questions: [
                  {
                    question_id: 'userName',
                    question_type: 'text',
                    display_type: 'text',
                    orientation: 'rows',
                    question_text: 'User Name',
                    sequence_number: 1,
                    is_reviewable: true,
                    is_editable: true,
                    is_edit_icon_visible: false,
                    is_hidden: false,
                    presentation_type: '',
                    properties: [],
                    response_options: [],
                    parent_question_answer: [],
                    question_description: '',
                    question_status: '',
                    question_status_message: '',
                    hint_text: '',
                    response: '',
                    validations: {
                      required: {
                        value: true,
                        error_message: 'This is a required field.',
                      },
                    },
                    sureify_meta: {
                      source_id: 1,
                    },
                    questions: [],
                  },
                  {
                    question_id: 'password',
                    question_type: 'password',
                    display_type: 'text',
                    orientation: 'rows',
                    question_text: 'Password',
                    response: '',
                    sequence_number: 1,
                    is_reviewable: true,
                    is_editable: true,
                    is_edit_icon_visible: false,
                    is_hidden: false,
                    presentation_type: '',
                    properties: [],
                    response_options: [],
                    parent_question_answer: [],
                    question_description: '',
                    question_status: '',
                    question_status_message: '',
                    hint_text: '',
                    validations: {
                      required: {
                        value: true,
                        error_message: 'This is a required field.',
                      },
                    },
                    sureify_meta: {
                      source_id: 1,
                    },
                    questions: [],
                  },
                ],
              },
            ],
            section_progress: {
              total_sections: 1,
              current_section_number: 0,
            },
          },
          completed: false,
        },
      },
      page_desc: {
        desc: null,
        title: null,
        heading: null,
      },
    },
  },
};

export const mockInactiveSessionResponse = {
  data: {
    code: 200,
    status: true,
    response: {
      other_params: {
        is_prev_btn_hidden: true,
        is_next_btn_hidden: true,
        type: 'plain',
        current_status: 'inactivesession',
      },
      breadcrumbs: [
        {
          breadcrumb_id: 'inactivesession',
          title: 'inactivesession',
          section_progress: {
            current_section_number: 0,
            total_sections: 1,
          },
          show: false,
          state: 'active',
        },
      ],
      data: {
        questionnarie: {
          page_sequence_number: 5,
          questions: {
            question_id: 'inactivesession',
            question_type: 'group',
            display_type: 'breadcrumb',
            orientation: '',
            question_text: 'Inactive Session',
            page_title: 'Inactive Session | Brighthouse Financial',
            index: 1,
            response: '',
            sequence_number: 1,
            is_reviewable: true,
            is_editable: true,
            is_edit_icon_visible: false,
            is_hidden: false,
            presentation_type: '',
            validations: [],
            properties: [],
            response_options: [],
            parent_question_answer: [],
            question_description: '',
            child_questions: false,
            question_status: '',
            question_status_message: '',
            hint_text: '',
            sureify_meta: {
              source_id: 1,
            },
            questions: [
              {
                question_id: 'inactivesession-group',
                question_type: 'group',
                display_type: 'questions_group',
                orientation: '',
                question_text: 'Your session is active in a different window',
                index: 1,
                response: '',
                sequence_number: 1,
                is_reviewable: true,
                is_editable: true,
                is_edit_icon_visible: false,
                is_hidden: false,
                presentation_type: '',
                validations: [],
                properties: [],
                response_options: [],
                parent_question_answer: [],
                question_description: '',
                child_questions: false,
                question_status: '',
                question_status_message: '',
                hint_text: '',
                sureify_meta: {
                  source_id: 1,
                },
                style: {
                  left_border: {
                    width: '8px',
                    color_code: 'rgba(185, 198, 211, 1)',
                  },
                },
                questions: [
                  {
                    question_id: 'InactiveSession',
                    question_text:
                      '<p>Looks like you already have a life insurance session in progress. The session in this tab has expired and you can close out of it now. Your information will be saved in your newest session.</p>',
                    question_status: 'valid',
                    question_type: 'label',
                    display_type: 'text',
                    validations: {
                      required: {
                        value: false,
                      },
                    },
                    presentation_type: '',
                  },
                ],
              },
            ],
          },
        },
      },
      page_desc: {
        desc: null,
        title: 'Inactive Session',
        heading: null,
      },
    },
  },
};

export const mockNotFoundResponse = {
  data: {
    code: 200,
    status: true,
    response: {
      status: true,
      data: {
        questionnarie: {
          questions: [],
        },
      },
      other_params: {
        is_prev_btn_hidden: true,
        is_next_btn_hidden: true,
        type: 'plain',
        current_status: 'notfound',
      },
    },
  },
};

export const mockQuestions = [];

export const mockAlertPopup = (popupMessage) => {
  return {
    index: 2,
    response: '',
    hint_text: '',
    is_hidden: false,
    footer_note: '',
    is_editable: false,
    orientation: '',
    question_id: 'alert',
    display_type: 'popup',
    sureify_meta: {
      source_id: 1,
    },
    is_reviewable: false,
    question_text: '',
    question_type: 'popup',
    footer_content: 'Footer content',
    showModalClose: true,
    child_questions: false,
    question_status: 'valid',
    sequence_number: 1,
    showCloseButton: true,
    response_options: [],
    presentation_type: '',
    is_edit_icon_visible: false,
    question_description: popupMessage,
    parent_question_answer: [],
    question_status_message: '',
  };
};
