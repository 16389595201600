import React from 'react';
import get from 'lodash/get';
import { at } from 'lodash';
import { withTheme } from 'styled-components';
import TextElement from '../TextElement';
import { 
    Row, 
    HeaderContentWrapper, 
    TopHeadingWrapper, 
    HeaderContent, 
    HorizontalRowBold, 
    HorizontalRowNormal, 
} from './styles';
import { getCSSVariables } from '../../../cruxComponents/utils';

const getHeading = (row) => {
    const rowWithHeading = row.filter((r) => r.heading);
    return get(rowWithHeading, '[0].heading', '');
}

/**
 * Formats table header -
 * [{ content: '', heading: '', metric: '' }, ...]
 * @param {*} header 
 */
const formatHeader = (header,headerFont,pFont) => {
    if (Array.isArray(header) && header.length) {
        return (
            <>
                <Row>
                    { header.map((h) => (
                        <div>
                            <TopHeadingWrapper>
                                <TextElement styleType='normal' text={ h.heading } pFont={ pFont } />
                            </TopHeadingWrapper>
                            <HeaderContentWrapper>
                                <HeaderContent font={ headerFont }>{ h.content }</HeaderContent>
                                <TextElement styleType='normal' text={ h.metric } pFont={ pFont } />
                            </HeaderContentWrapper>
                        </div>
                    )) }
                </Row>
                <HorizontalRowBold />
            </>
        )
    }
    return null;
}

/**
 * Formats table row data -
 * [{ content: '', heading: '', metric: '' }, ...]
 * @param {*} data 
 */
const formatData = (data,pFont) => {
    if (Array.isArray(data) && data.length) {
        return data.map((row, i) => {
            if (Array.isArray(row) && row.length) {
                const heading = getHeading(row);
                return (
                    <>
                        <Row style={ { justifyContent: 'space-around', textAlign: 'center' } }>
                            <TextElement styleType='normal' text={ heading } pFont= { pFont }/>
                        </Row>
                        <Row>
                            { row.map((r) => (
                                <TextElement 
                                    styleType={ i < 2 ? 'label' : 'normal' } 
                                    text={ r.content } 
                                    pFont= { pFont }
                                />
                            )) }
                        </Row>
                        <HorizontalRowNormal />
                    </>
                )
            }
            return null;
        })
    }
    return null;
}

const Table = ({ question ,theme }) => {
    const { response } = question;
    const {
        title,
        header,
        data,
    } = response;
    const element = '.table-heading';
    const tableElement= '.table-p';
	const cssVariables =  getCSSVariables(theme);
    const font = at(cssVariables, [`pageOverride.font${element}`, `global.font${element}`]);
    const headerElement='.table-header-content';
    const headerFont = at(cssVariables, [`pageOverride.font${headerElement}`, `global.font${headerElement}`]);
    const pFont = at(cssVariables, [`pageOverride.font${tableElement}`, `global.font${tableElement}`]);


    const Title = title && (
        <TextElement text={ title } styleType='questions_group' font={ font } />
    );

    const Header = formatHeader(header,headerFont,pFont);

    const Data = formatData(data,pFont);

    return (
        <>
            { Title }
            { Header }
            { Data }
        </>
    )
}

export default withTheme(Table);
