/* eslint-disable no-param-reassign */
import uniq from 'lodash/uniq';

function updateParentQuestions(questions, removedGridId) {
  const qId = Object.keys(removedGridId)[0];
  return questions.map(q => {
    if (q.question_id === qId) {
      q.answer.splice(removedGridId[qId], 1)
      return q;
    }
    return q;
  })
}

function findNode(list, pId) {
  let node = {};
  list.forEach(i => {
    if (typeof i === 'object') {
      if (i.question_id === pId) {
            node = i;
      }
    }
  })
  return node;
}

function findGroup(answer, code) {
  let index = -1;
  for(let i = 0; i < answer.length; i += 1) {
    if (answer[i][0].groupHash === code) {
      index = i;
      break;
    }
  }
  return index;
}

function getRemoveIndex(qId, removedGridId) {
  if (removedGridId[qId] !== undefined) {
    return [removedGridId[qId]];
  }
  return [];
}

function generateGroup (list, removedGridId) {
  if (!list.length) return list;
  const temp = [];
  list.forEach(q => {
    if (temp.length === 0 && q.parentListQuestionId) {
      temp.push({
        question_id: q.parentListQuestionId,
        answer: [
          [q],
        ],
        extra_params: {
          removed_indexes: getRemoveIndex(q.parentListQuestionId, removedGridId),
        },
        groupHash: q.groupHash,
      })
      delete q.status;
      delete q.parentListQuestionId;
      delete q.parentId;

    } else {
      const pId = q.parentListQuestionId;
      const node = findNode(temp, pId);
      if (node.question_id === undefined) {
        temp.push({
        question_id: q.parentListQuestionId,
        answer: [
            [q],
          ],
          extra_params: {
            removed_indexes: getRemoveIndex(q.parentListQuestionId, removedGridId),
          },
          groupHash: q.groupHash,
        });
        delete q.status;
        delete q.parentListQuestionId;
        delete q.parentId;
      } else {
        const i = findGroup(node.answer, q.groupHash)
        if (i < 0) {
          node.answer.push([q]);
        } else {
          node.answer[i] = [
            ...node.answer[i],
            q,
          ] 
        }
        node.extra_params = {
          removed_indexes: uniq([...node.extra_params.removed_indexes, ...getRemoveIndex(q.parentListQuestionId, removedGridId)]),
        }
        delete q.status;
        delete q.parentListQuestionId;
        delete q.parentId;
      }
    }
  })
  return temp;
}

function mapListParents (temp, listRelation) {
  let n = {};
  temp.forEach(q => {
    const qId = q.question_id;
    const parent = listRelation[qId];
    if (parent) {
      n = {}
      for (let i = 0; i < temp.length; i += 1) {
        if (temp[i].question_id === parent) {
          n = temp[i];
          break;
        }
      }
      const t = n.answer;
      for (let j = 0; j < t.length; j += 1) {
        const gh= t[j][0].immediateParent;
        for (let k = 0; k < q.answer.length; k += 1) {
          const answers = q.answer.filter(a => a[0].immediateParent === gh);
          q.answer = q.answer.filter(a => a[0].immediateParent !== gh);
          if (answers.length) {
            t[j].push({
              question_id: q.question_id,
              answer: answers,
              extra_params: q.extra_params,
            })
          }
        }
      }
      temp = temp.filter(ques => ques.question_id === parent);
    }
  });
  return temp;
}

export default function prepareQuestionsPayload (questionsArray, listRelation, removedGridId = {}) {

  // remove all questions with status false
  // rename the question id by removing # and suffix numbers
  const childQs = questionsArray
    .filter(q => q.question_id.indexOf('_$ureify_') > -1)
    .reduce((acc, q) => {
    const qSplit = q.question_id.split('_$ureify_');
    acc.push({
      ...q,
      question_id: qSplit[1],
      groupHash: qSplit[2],
    });
    return acc;
  }, []);
  const otherQs = questionsArray.filter(q => !q.parentListQuestionId);

  return [
    ...mapListParents(updateParentQuestions((generateGroup(childQs, removedGridId)), removedGridId), listRelation),
    ...otherQs,
  ]
}
