/* eslint-disable no-undef */
import React, { useLayoutEffect, useState } from 'react';

const ReadingIndicator = ({ store }) => {

    const [percentages, setPercentages] = useState(0);

    const handleScroll = (e) => {
        const { target } = e;
        if (target instanceof HTMLDivElement) {
            const p = Math.floor((100 * target.scrollTop) / (target.scrollHeight - target.clientHeight));
            setPercentages(Math.min(100, p));
        }
    };

    const handlePagesContainer = () => {
        const getPagesContainer = store.get('getPagesContainer');
        if (!getPagesContainer) {
            return;
        }

        const pagesEle = getPagesContainer();
        pagesEle.addEventListener('scroll', handleScroll);
    };

    useLayoutEffect(() => {
        store.subscribe('getPagesContainer', handlePagesContainer);

        return () => store.unsubscribe('getPagesContainer', handlePagesContainer);
    }, []);

    return (
        <div
            id="readingIndicator"
            className="readingIndicator"
            style={ {
                height: '5px',
            } }
        >
            <div
                style={ {
                    backgroundColor: 'rgb(53, 126, 221)',
                    height: '100%',
                    width: `${percentages}%`,
                } }
            />
        </div>
    );
};

export default ReadingIndicator;