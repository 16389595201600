/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
// import postQuestion from "../actions/questionAction";
import get from 'lodash/get';
import last from 'lodash/last';
import cloneDeep from 'lodash/cloneDeep';
import {
	GET_NEW_QUESTION,
	API_IN_PROGRESS,
	UPDATE_QUESTION_ANSWER,
	GET_NEW_QUESTION_START,
	GET_QUESTIONS_SUCCESS,
	SET_CURRENT_BREADCRUMB,
	GET_REVIEW_SUCCESS,
	GET_QUOTE_SUCCESS,
	GET_QUESTION_FAILURE,
	GET_DOCUMENT_SUCCESS,
	GET_PLAN_SUCCESS,
	GET_QUOTEREVIEW_SUCCESS,
	GET_SIGNATURE_SUCCESS,
	GET_PAYMENT_SUCCESS,
	GET_TIMEOUT_SUCCESS,
	GET_INACTIVESESSION_SUCCESS,
	GET_CHALLENGE_SUCCESS,
	GET_RIDERS_SUCCESS,
	GET_COMPLETED_SUCCESS,
	GET_LOGIN_SUCCESS,
	GET_KNOCKOUT_SUCCESS,
	GET_UNSUBSCRIBED_SUCCESS,
	GET_PRODUCTS_SUCCESS,
	GET_INTERVIEW_SUCCESS,
	GET_LAUNCH_SUCCESS,
	GET_REFERRAL_SUCCESS,
	GET_EHR_SUCCESS,
	GET_STATIC_SUCCESS,
	UPDATE_DATA_SELECTOR_OPTIONS,
	TOGGLE_ESIGN_BUTTON,
	UPDATE_LIST_SECTION,
	GET_PROCESSING_SUCCESS,
	GET_WELCOME_SUCCESS,
	GET_HEALTH_SUCCESS,
	SET_ACTION_MESSAGE,
	SET_PDF_CONFIGS,
	SET_POPUP_DETAILS,
	UPDATE_LAST_FOCUSED_ELEMENT_ID,
	SET_REFLEXIVE_LIST_INDEX,
	SET_LIST_INDEX,
	SET_LIST_MODE,
	UPDATE_QUESTION_ANSWER_TIME_STAMP,
	TOGGLE_SIGNATURE_CONTINUE,
} from '../types';
import { getUTCTimestamp } from '../../utils'
import HELPER, { getCurrentBreadcrumb } from './helper';
import {
	isListQuestion,
	getListDisplayMode,
	hasListItems,
	getAddButtonText,
	getQuestionStatus,
	isListHidden,
} from '../actions/listQuestionHelper';

const initialState = {
	breadcrumbs: {
		currentBreadcrumb: {},
		isBreadcrumbLoaded: true,
		breadcrumbList: [],
	},
	isLoading: true,
	lastFocusedElementId: '',
	routeChange: false,
	currentPageSeqNumber: 0,
	sectionIndex: 0,
	isListAddAction: false,
	activeSubsectionId: '',
	listMode: false,
	nestedListMode: false,
	rootListIndex: null,
	nestedListIndex: -1,
	rootListId: '',
	listQMap: {},
	actionMessage: {
		type: '',
		message: '',
	},
	eSign: {
		pdfBlobURL: '/',
		pdfNumPages: null,
		hasESign: false,
		eSignEnable: false,
	},
	retry: {
		interval: 0,
	},
	loader: {
		message: 'Just a moment...',
	},
	popupQuestions: [],
	popupDetails: { show: false, question: '' },
	lastSuccessResponse: {},
	reviewActiveIds: [], // Contains Id's those to be opened on review page.
	sections: {
		'testSections': [
			{
				id: '12345',
				answersArray: [],
				errorsArray: [],
				answeredListArray:[],
				otherParams: {},
				dropdownResponseOptions: {},
				errors: {},
				pageDesc: {},
				pageSeqNumber: 0,
				questionList: {},
				leftQuestionList: [],
				layoutRatio: {},
				requiredSchema: {},
				enableContinue: false,
				list: {
					isList: false,
					listDisplay: false,
					hasItems: false,
					addButtonText: 'Add',
					originalQuestions: [],
					questionObj: {},
				},
				audit: [],
				onload_timestamp: getUTCTimestamp(),
				listIndex: -1,
				listEditIndex: -1,
				listEditQId: '',
				questionObj: {},
			},
		],
	},
};

function getReviewActiveIds (action) {
	const isNetworkResponse =  get(action, 'params.isNetworkResponse');
	if (isNetworkResponse === false)
		return get(action, 'params.reviewActiveIds', []);

	let updatedCard = [get(action, 'payload.data.questionnarie.questions.updated_card')].filter(a => { return (!!a) });
	if (!updatedCard.length) {
		const allQuestions = get(action, 'payload.data.questionnarie.questions.questions', []);
		if (allQuestions && allQuestions.length)
			updatedCard = [allQuestions[0].question_id];
	}

	return updatedCard;
}

function updateBreadcrumbs(breadcrumbs = {}, payload) {
	const breadcrumbList = payload.reduce((acc, breadcrumb) => {
		acc.push({
			...breadcrumb,
			id:
				breadcrumb.breadcrumb_id.toLowerCase() ||
				breadcrumb.title.replace(/ /g, '').toLowerCase(),
			title: breadcrumb.title.replace('/', ' / '),
		});
		return acc;
	}, []);
	return {
		...breadcrumbs,
		breadcrumbList,
		currentBreadcrumb: getCurrentBreadcrumb(breadcrumbList),
		isBreadcrumbLoaded: true,
	}
}

function recursiveIterations(questionsAry, mrasParentQMap, curParentQ, recursiveParentQ){
	for(let i=0; i< questionsAry?.length; i++){
		const curQ = questionsAry[i];
		if(curQ?.display_type === 'label' || curQ?.question_type === 'label' || curQ?.is_hidden) continue;
		const isMrasParentQCheck = curQ?.is_mras_question && !curQ.parent_id && !curQ.disclosure_id
		if(isMrasParentQCheck){
			curParentQ = curQ.question_id ;
			mrasParentQMap = {
				...mrasParentQMap,
				[curQ.question_id]: []
			}
		}else{
			const parentIdChildQsCheck = curQ?.parent_id === curParentQ || curQ?.parent_id === recursiveParentQ
			const disclosureIdChildQsCheck = curQ?.disclosure_id === curParentQ || curQ?.disclosure_id === recursiveParentQ
			if(parentIdChildQsCheck || disclosureIdChildQsCheck){
				mrasParentQMap = {
					...mrasParentQMap,
					...(mrasParentQMap?.[curParentQ]?.length >=0 && {
						[curParentQ]: [
							...mrasParentQMap[curParentQ],
							curQ.question_id
						]
					})
				}
				recursiveParentQ = curQ.question_id
			}
		}

		if(curQ?.questions?.length > 0){
			recursiveParentQ = curQ.question_id
			mrasParentQMap = recursiveIterations(curQ.questions, mrasParentQMap, curParentQ, recursiveParentQ)
		}
	}
	return mrasParentQMap;
}

function getLeftAndRightData(questionItem, skip, payload = {}) {
	const hasModal = get(payload, 'other_params.has_modal', false);
	const layout = get(questionItem, 'properties.layout', undefined);
	let leftQuestionList;
	let rightQuestionList;
	/* MRAS Recursive Parent QAns Map  */
	// let mrasParentQMap = {};
	// let curParentQ, recursiveParentQ = questionItem.questions.question_id

	// mrasParentQMap = recursiveIterations(questionItem.questions, mrasParentQMap, curParentQ, recursiveParentQ)
	// console.log('getLeftAndRightData', questionItem, questionItem.questions, mrasParentQMap);
	/* MRAS Recursive Parent QAns Map  */

	if(!skip && layout && layout.value === 'two_columns') {
	const allQuestions = get(questionItem, 'questions', []);
		for (let i=0; i< allQuestions.length; i+=1) {
			const question = allQuestions[i];
			const displayPosition = get(question, 'properties.display_position.value', '');
			if(displayPosition === 'left') {
				leftQuestionList = HELPER.insertReflexiveQuestion(question, hasModal)
			} else if(displayPosition === 'right') {
				rightQuestionList = HELPER.insertReflexiveQuestion(question, hasModal);
			}
		}
		return {
			layoutRatio: layout.size,
			leftQuestionList,
			rightQuestionList,
		};
	}
	rightQuestionList = HELPER.insertReflexiveQuestion(questionItem, hasModal);
	return { layoutRatio: {}, leftQuestionList: [], rightQuestionList }
}

const getFormattedReviewResponse = (questionObj, reviewStatus = false, accordian = false, reviewActiveIds = []) => {
	if (reviewStatus === false) return questionObj;

	const questionsList = [];
	if (questionObj && questionObj.length) {
		questionObj.forEach((ques) => {
			if (ques.display_type === 'questions_group') {
				const breadcrumbQuestions = cloneDeep( ques.questions );

				if (!accordian) {
					// const breadcrumbGroup = { ...ques, questions: [] };
					// questionsList.push(breadcrumbGroup);
					questionsList.push(...[
						{
							...ques,
							isBreadcrumbCard: true,
							accordian: false,
							questions: [],
						},
						...breadcrumbQuestions,
					]);
				}
				else {
					for (let i = 1; i <= breadcrumbQuestions.length - 1; i += 1)
						breadcrumbQuestions[i].solidLine = true;
					questionsList.push({
							...ques,
							isBreadcrumbCard: true,
							accordian: true,
							questions: [],
					});
					if (reviewActiveIds.indexOf(ques.question_id) !== -1)
						questionsList.push({
								question_id: `review_page_card_${ques.question_id}`,
								questions: breadcrumbQuestions,
						});
				}
			}
			else questionsList.push(ques);
		})
	}
	return questionsList;
}

const isReviewSection = (questionsList) => {
	if (questionsList && questionsList.length) {
		for(let i = 0; i < questionsList.length; i += 1) {
			if(questionsList[i].presentation_type === 'review') return true;
		}
	}
	return false;
}

const isReviewSatus = (status) => status === 'review' || status === 'quotereview';

function resetBreadcrumbData(action) {
	const { payload, type } = action;
	const currentStatus = get(
		payload,
		'other_params.current_status',
		'',
	);
	const questionObj = get(
		payload,
		'data.questionnarie.questions',
		{},
	);

	let allQuestions = [];
	allQuestions = get(
		payload,
		'data.questionnarie.questions.questions',
		[],
	);

	const reviewStatus = isReviewSatus(currentStatus);

	const accordian = get(payload, 'data.questionnarie.questions.accordian');
	allQuestions = getFormattedReviewResponse(allQuestions, reviewStatus, accordian, getReviewActiveIds(action));

	return allQuestions.map((questionItem, index) => {
		const {
			layoutRatio,
			leftQuestionList,
			rightQuestionList,
		} = getLeftAndRightData(questionItem, type === GET_QUOTE_SUCCESS || reviewStatus, payload);

		/* MRAS Recursive Parent QAns Map Reset Breadcrumb */
		let mrasParentQMap = {};
		let curParentQ; const recursiveParentQ = questionItem.questions.question_id

		mrasParentQMap = recursiveIterations(questionItem.questions, mrasParentQMap, curParentQ, recursiveParentQ)
		/* MRAS Recursive Parent QAns Map Reset Breadcrumb */

		// console.log('BEFORE ---=== getLeftAndRightData resetBreadcrumbData', mrasParentQMap);
		const conditionalQs = rightQuestionList.filter((ques) => ques?.validations?.relative && ques.response !== '')
		let isRelative = false;
		if(conditionalQs.length)
			isRelative = true

		const requiredSchema = HELPER.getRequiredSchema(
			rightQuestionList,
			isRelative,
		);
		const isList = isListQuestion(
			rightQuestionList,
			reviewStatus,
		);

		const originalQuestions = isList ? HELPER.getOriginalQuestions(questionItem) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);

		// Review Page Related Conditions
		const isReview = isReviewSection(rightQuestionList)
		const showReviewContinueButton = false;
		const listDisplay = getListDisplayMode(rightQuestionList);
		const questionStatus = getQuestionStatus(rightQuestionList);
		const isListQHidden = isListHidden(rightQuestionList);
		let enableContinue;
		if (reviewStatus) {
			enableContinue = true;
		} else {
			enableContinue = HELPER.shouldEnableContinue(
				requiredSchema,
				answersArray,
				[],
				isList,
				listDisplay,
				questionStatus,
				undefined,
				isListQHidden,
			);
		}
		const pageId = window.location.pathname.split('/')[1] || '';
		return {
			id: pageId === 'review' ? questionItem.question_id : HELPER.getRandomId(),
			questionList: rightQuestionList,
			answeredListQuestion: HELPER.getAnsweredLisQuestionIds(rightQuestionList),
			leftQuestionList,
			layoutRatio,
			requiredSchema,
			answersArray,
			errorsArray: [],
			enableContinue,
			dataSelector: new Map(),
			otherParams: {
				...payload.other_params,
			},
			...(payload?.response_json && {dropdownResponseOptions: [...payload.response_json]}),
			errors: {
				...payload.errors,
			},
			pageDesc: {
				...payload.page_desc,
			},
			review: {
				isReview,
				showReviewContinueButton,
			},
			...(Object.keys(mrasParentQMap)?.length > 0 && { mrasParentQMap }),
			/**
			 * Completed would be false for last section so last section
			 * avoid sending index _modify_flag=1 this issue is caused
			 * when resetBreadcrumbData called when answering reflexive
			 * question in the last section in the
			 * page NAT-1162
			 */
			completed: allQuestions.length - 1 !== index,
			list: {
				isList,
				listDisplay,
				hasItems: hasListItems(rightQuestionList),
				addButtonText: getAddButtonText(rightQuestionList),
				questionObj: questionItem,
				originalQuestions,
			},
			questionObj,
			audit: [],
			onload_timestamp: getUTCTimestamp(),
		};
	});
}

/**
 * Function to compare the masked question's response (e.g SSN) from the
 * previous request and the upcoming response object.
 */
function compareMaskedResponse(breadcrumbQs = [], action) {
	let diff = false;
	let allQuestions = breadcrumbQs;
	const { payload, params, type } = action;
	const currentStatus =  get(payload, 'other_params.current_status', '');
	const maskedQArray = get(params, 'maskedQArray', []);

	if (allQuestions.length === 0 || maskedQArray.length === 0) return false;

	const reviewStatus = isReviewSatus(currentStatus);
	allQuestions = getFormattedReviewResponse(allQuestions, reviewStatus);

	allQuestions.forEach(qItem => {
		const {
			rightQuestionList,
		} = getLeftAndRightData(qItem, type === GET_QUOTE_SUCCESS || reviewStatus, payload);
		const isList = isListQuestion(
			rightQuestionList,
			reviewStatus,
		);
		const originalQuestions = isList ? HELPER.getOriginalQuestions(qItem) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);
		if (answersArray.length > 1) {
			maskedQArray.forEach(arr => {
				const ansArray = rightQuestionList.filter(q => q.question_id === arr.question_id);
				if (ansArray.length) {
					if(ansArray[0].response !== arr.answer) diff = true;
				}
			})
		}
	})
	return diff;
}

/**
 * @description Compares current status with review status. Accepts either
 * current status or the payload.
 * @param {String} currentStatus
 * @param {Object} payload
 */
function isReviewPage(currentStatus, payload) {
	if (currentStatus) {
		return currentStatus === 'review' || currentStatus === 'quotereview';
	}
	const _currentStatus =  get(payload, 'other_params.current_status', '');
	return _currentStatus === 'review' || _currentStatus === 'quotereview';

}

/**
 * @description function validates different conditions to determine
 * if breadcrumb data should be reset.
 * @param {Array} currentBreadcrumbData
 * @param {Object} action
 */
function shouldReset(currentBreadcrumbData = [], action = {}, routeChange = false) {
	try {
		const { payload, params } = action;
		const currentStatus =  get(payload, 'other_params.current_status', '');

		// Resets Breadcrumb Data for the following current_status routes
		const resetBreadcrumbRoutes = ['quote', 'signature', 'completed'];

		const isUpdateMode = get(payload, 'other_params.show_edit_page_flag', false);

	/**
	 * If the maskVariation is true then perform breadcrumb reset operation
	 * else perform normal breadcrumb updation.
	 */
	let maskVariation = false;
	if (params.maskedQArray) {
		const breadcrumbQs = get(payload, 'data.questionnarie.questions.questions', null);
		maskVariation = compareMaskedResponse(breadcrumbQs, action);
	}

	const breadcrumbQLength = get(payload, 'data.questionnarie.questions.questions', []).length

	const hasModal = get(payload, 'other_params.has_modal', false);

	/**
	 * Rerender - Excluding state updates in entirity on reflexive calls thus
	 * preventing re-render of other components/document reload.
	 */
	const resetSectionData = !(params.isReflexive && currentStatus.match(/signature/i));

	if (isReviewPage(currentStatus, payload) ||
		resetBreadcrumbRoutes.includes(currentStatus) ||
		currentBreadcrumbData.length === 0 ||
		params.isReflexive ||
		params.validate ||
		params.breadcrumb_nav_flag ||
		params.prev_page_flag ||
		isUpdateMode ||
		maskVariation ||
		breadcrumbQLength < currentBreadcrumbData.length ||
		routeChange ||
		hasModal) {
			if(!resetSectionData) return false
			return true;
		}
		return false;
	} catch (e) {
		return false;
	}
}
function updatedBreadcrumbData(currentBreadcrumbData = [], pageSeqNumber, action, routeChange = false, eSign) {
	const { payload, params, type } = action;
	const questionObj = get(
		payload,
		'data.questionnarie.questions',
		{},
	);

	const breadcrumbQuestions = last(
		get(payload, 'data.questionnarie.questions.questions', null),
	);

	const isUpdatePrevSectionData = get(payload, 'data.questionnarie.questions.update_prev_section_data', false)

	if (shouldReset(currentBreadcrumbData, action, routeChange)) {
		return resetBreadcrumbData(action);
	}

	const hasModal = get(payload, 'other_params.has_modal', false);
	const currentRoute = get(payload, 'other_params.current_status', '');

	let questionList = [];
	if (breadcrumbQuestions) {
		let newBreadcrumbData = currentBreadcrumbData;
		const { isReflexive, sectionIndex } = params;
		if (isReflexive) {
			questionList = HELPER.insertReflexiveQuestion(
				breadcrumbQuestions,
				hasModal,
			);
			newBreadcrumbData = HELPER.replaceSectionQuestion(
				currentBreadcrumbData,
				questionList,
				sectionIndex,
				eSign,
				currentRoute,
			);
			return newBreadcrumbData;
		}
		newBreadcrumbData = HELPER.removeDuplicate(
			currentBreadcrumbData,
			breadcrumbQuestions,
		);
		/* BH-726 update previous section data conditionally on clicking the section continue button */
		if(isUpdatePrevSectionData){
			const prevSectionData = get(payload, 'data.questionnarie.questions.questions', null)
			let eachQuestionList = []
			for(const [index, eachSection] of prevSectionData.entries()){
				if(index !== prevSectionData.length - 1){
					eachQuestionList = HELPER.insertReflexiveQuestion(eachSection);
					newBreadcrumbData = HELPER.replaceSectionQuestion(
						newBreadcrumbData,
						eachQuestionList,
						index,
					);
				}
			}
		}

		/* MRAS Recursive Parent QAns Map Update Breadcrumb */
		let mrasParentQMap = {};
		let curParentQ; const recursiveParentQ = breadcrumbQuestions.questions.question_id
		mrasParentQMap = recursiveIterations(breadcrumbQuestions.questions, mrasParentQMap, curParentQ, recursiveParentQ)
		/* MRAS Recursive Parent QAns Map Update Breadcrumb */

		const {
			layoutRatio,
			leftQuestionList,
			rightQuestionList,
		} = getLeftAndRightData(
			breadcrumbQuestions,
			type === 'GET_QUOTE_SUCCESS',
			payload,
		);
		const requiredSchema = HELPER.getRequiredSchema(
			rightQuestionList,
		);
		const listDisplay = getListDisplayMode(rightQuestionList);
		const listError =  getQuestionStatus(rightQuestionList)
		const isListQHidden = isListHidden(rightQuestionList)

		let isList = false;
		if (Object.keys(layoutRatio).length === 0) {
			isList = isListQuestion(
				rightQuestionList,
				isReviewPage(null, payload),
			);
		}

		const originalQuestions = isList ? HELPER.getOriginalQuestions(breadcrumbQuestions) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);
		return [
			...newBreadcrumbData,
			{
				id: HELPER.getRandomId(),// questionObj.question_id,
				questionList: rightQuestionList,
				answeredListQuestion: HELPER.getAnsweredLisQuestionIds(rightQuestionList),
				leftQuestionList,
				layoutRatio,
				requiredSchema,
				answersArray,
				errorsArray: [],
				enableContinue: isReviewPage(null, payload) ? true : HELPER.shouldEnableContinue(requiredSchema, [], [], isList, listDisplay, listError, eSign , isListQHidden),
				otherParams: {
					...payload.other_params,
				},
				...(payload?.response_json && {dropdownResponseOptions: [...payload.response_json]}),
				errors: {
					...payload.errors,
				},
				pageDesc: {
					...payload.page_desc,
				},
				list: {
					isList,
					listDisplay,
					hasItems: hasListItems(rightQuestionList),
					addButtonText: getAddButtonText(rightQuestionList),
					questionObj: breadcrumbQuestions,
					originalQuestions,
				},
				questionObj,
				...(Object.keys(mrasParentQMap)?.length > 0 && { mrasParentQMap }),
				audit: [],
				onload_timestamp: getUTCTimestamp(),
			},
		]
	}
	return currentBreadcrumbData;
}

function listSectionUpdates(state = {}, listQIds) {
	const { errorsArray, answersArray, questionList } = state;
	let _errorsArray = [];
	let _answersArray = [];
	const listError =  getQuestionStatus(questionList);
	_errorsArray = errorsArray.filter((item) =>
		!listQIds.includes(item.question_id),
	);
	_answersArray = answersArray.filter((item) =>
		!listQIds.includes(item.question_id),
	);
	return {
		...state,
		errorsArray: _errorsArray,
		answersArray: _answersArray,
		enableContinue: !listError && _errorsArray.length === 0,
	}
}

function hasInvalidQuestion(questionList) {
	let invalidQuestionStatus = false
	questionList.forEach(question => {
		const questionStatusMessage = get(question, 'question_status_message', '');
		const questionStatus = get(question, 'question_status', '');
		const displayType = get(question, 'display_type', '');
		const invalidQuestion = questionStatus === 'invalid' && displayType !== 'list';
		if(invalidQuestion || questionStatusMessage!==''){
			invalidQuestionStatus = true;
		}
	})
	return invalidQuestionStatus;
}

const timeStampUpdates = (state={}, action) => {
	const {
		questionId,
		timeStampData,
	} = action.payload
	const { transactionLog, lastSubmittedAt } = timeStampData
	const answersArray = HELPER.addOrReplace(state.answersArray, { question_id: questionId, transactionLog, lastSubmittedAt }, 'transaction_log' );
	const questionList = HELPER.findAndUpdateTimeStamp(state.questionList, questionId, transactionLog, lastSubmittedAt)
	return {
		...state,
		answersArray,
		questionList,
	}
}

function questionUpdates(state = {}, currentPageSeqNumber, action) {
	const {
		questionId,
		response,
		questionResponse,
		isError,
		isHidden,
		propQuestion,
		reset,
		isControlledInput,
		listParentId,
		listIndex,
		eSign,
		hasEdit,
		hasMask,
		auditRequired,
		isDateCleared,
		questionType,
		isSelectiveFieldValidate,
		relativeQId,
	} = action.payload;
	const answersArray = HELPER.addOrReplace(
		state.answersArray,
		{
			question_id: questionId,
			answer: response,
			immediateParent: propQuestion ? propQuestion.immediateParent : '',
			parentId: propQuestion ? propQuestion.parentId : '',
			parentListQuestionId: get(action, 'payload.propQuestion.properties.parent_list_id', ''),
			reset,
			listParentId,
			listIndex,
			hasEdit,
			hasMask,
			isSelectiveFieldValidate,
			relativeQId,
		},
		'answer',
	);

	const nList = questionId.match(/_\$ureify_/g);
	const skipUpdate = nList && nList.length === 2;
	const questionList = skipUpdate ? state.questionList : HELPER.addOrReplace(state.questionList, {
		question_id: questionId,
		is_hidden: isHidden || false,
	}, 'is_hidden');
	const errorsArray = HELPER.addOrReplace(
		state.errorsArray,
		{
			question_id: questionId,
			isError,
		},
		'isError',
	).filter((errorElement) => errorElement && errorElement.isError);
	let enableContinue = true;
	/**
	 * isError is undefined when the page is reloaded
	 * disable continue if we there's any invalid question
	 */
	if(isError === undefined && questionList){
		enableContinue = !hasInvalidQuestion(questionList)
	}
	const currentStatus = get(
		action,
		'payload.other_params.current_status',
		'',
	);
	const isList = isListQuestion(
		state.questionList,
		currentStatus === 'review',
	);
	const listDisplay = getListDisplayMode(state.questionList);
	const listError = getQuestionStatus(state.questionList);
	const isListQHidden = isListHidden(state.questionList)
	switch (action.type) {
		case UPDATE_QUESTION_ANSWER:
			return {
				...state,
				questionList: HELPER.findAndUpdateResponse(questionList, questionId, response, questionResponse, isControlledInput, questionType, isDateCleared),
				enableContinue: enableContinue && HELPER.shouldEnableContinue(state.requiredSchema, answersArray, errorsArray, isList, listDisplay, listError, eSign, isListQHidden),
				enableAdd: HELPER.shouldEnableContinue(state.requiredSchema, answersArray, errorsArray),
				audit: HELPER.insertAndUpdateTimestamp(state.audit, questionId, auditRequired),
				answersArray,
				errorsArray,
			};
		default:
			return state;
	}
}

function getSelectorOptions( parentQuestion, currentSelectedOptions, currentOption) {
	const responseOptions = get(parentQuestion.questions[0], 'response_options', []);
	const currentQuestionId = get(parentQuestion.questions[0], 'question_id', '');
	const sortedOption = [...currentOption].sort(HELPER.getSortOrder('target_question_id'));
	let isPresent = false;
	let dataSelectorOptions = currentSelectedOptions.get(currentQuestionId);
	let selectorIndex = -1;
	let count = 0;
	responseOptions.forEach(response => {
		count = 0;
		const sortedResponse = [...response].sort(HELPER.getSortOrder('target_question_id'));
		for(let index = 0; index < sortedResponse.length; index+=1) {
			if(sortedResponse[index].response.value === sortedOption[index].response.value ){
				count +=1;
			}
		}
		if(count === sortedOption.length){
			isPresent = true;
		}
	})
	if(Array.isArray(dataSelectorOptions)) {
	 dataSelectorOptions.forEach((dataSelector, index) => {
		count = 0;
		const sortedResponse = [...dataSelector].sort(HELPER.getSortOrder('target_question_id'));;
		if(sortedResponse.length === currentOption.length) {
			for(let i = 0; i < sortedResponse.length; i+=1) {
				if(sortedResponse[i].target_question_id === sortedOption[i].target_question_id ){
					count +=1;
				}
			}
			if(count === sortedOption.length){
				selectorIndex = index;
			}
		}
	});
   }
   else {
	   dataSelectorOptions = [];
   }
	if(selectorIndex > -1){
		dataSelectorOptions.splice(selectorIndex, 1);
	}
	if(!isPresent) {
		dataSelectorOptions.push(currentOption);
		currentSelectedOptions.set(currentQuestionId, dataSelectorOptions);
	}
	return currentSelectedOptions;
}

function updateDataSelectorOptions( currentSection, action ) {
   let currentDataSelector = currentSection.dataSelector;
   const currentOption = [];
   const { parentQuestion } = action.payload;
   const selectorOptions = get(parentQuestion.questions[0], 'selector_options', []);
   selectorOptions.forEach(option => {
	 const answersArray = HELPER.getAnswersArray(currentSection.answersArray, option.id);
	 if(answersArray.length > 0){
		currentOption.push({ target_question_id: option.id, response: { value : answersArray[0].answer } });
	 }
   });
   currentDataSelector = getSelectorOptions(parentQuestion, currentDataSelector, currentOption);
   const updatedSection = { ... currentSection };
   updatedSection.dataSelector = currentDataSelector;
   return updatedSection;
}

function getContinueStatus(currentSection) {
	const { questionList, requiredSchema, answersArray, errorsArray } = currentSection;
	const enableContinue = !hasInvalidQuestion(questionList) && HELPER.shouldEnableContinue(requiredSchema, answersArray, errorsArray)
	return enableContinue;
}

function updateRequiredSchema(currentSection, action){
	let { requiredSchema } = currentSection;
	const { questionIds } = action.payload;
	const { relativeQIds } = action.payload;
	requiredSchema = requiredSchema.filter(qId => !relativeQIds.includes(qId))
	requiredSchema = [...requiredSchema, ...questionIds]
	return {
		...currentSection,
		requiredSchema,
	}
}

function toggleSectionButtonState(currentSection, action){
	return {
		...currentSection,
		enableContinue: action.btnState,
	}
}

function updateIllustrationPDF(state = {}, currentPageSeqNumber, action){
	let illustrationPdf = get(state, 'fetched_illustrations', {})
	illustrationPdf = {
		...illustrationPdf,
		[action.payload.caseId]: action.payload.dataBuffer,
	}

	return {
		...state,
		fetched_illustrations: illustrationPdf,
		show_illustration_pdf: {
			caseId: action.payload.caseId,
			dataBuffer: action.payload.dataBuffer,
			is_clicked: true,
		},
	}
}

export default function questions(state = initialState, action) {
	let routeChange = false
	let currentBreadcrumb = null;
	let breadcrumbs = {};
	let currentPageSeqNumber = -1;
	let prevSections = [];
	let updatedSectionQuestions;
	let subSectionId;
	let actionMessage = {};
	let bannerInfo='';
	let custCareTel='';
	let policyExpiry='';
	let reviseButtonLabel='';
	let footerBanner=null;
	let footerNotes = [];
	let haveQuestions = {};
	let pageType = '';
	let { lastFocusedElementId } = state;
	let isNetworkResponse = true;
	let lastSuccessResponse = {};
	let reviewActiveIds = [];
	let rootListIndx = -1;
	let rootListId = '';
	let nestedListId = '';

	switch (action.type) {
		case GET_NEW_QUESTION_START:

			return {
				...state,
				isLoading: true,
				reload:action.params.reload,
				actionMessage: action.params.actionMessage,
				loadingSection: action.params.sectionIndex,
				isReflexive: action.params.isReflexive,
				validate: action.params.validate,
				isListAddAction: action.params.isListAddAction,
				breadcrumbNavigation: action.params.breadcrumbNavigation,
				maskedQArray: action.params.maskedQArray,
			}
		case GET_NEW_QUESTION:
		case GET_QUESTIONS_SUCCESS:
		case GET_DOCUMENT_SUCCESS:

			breadcrumbs = updateBreadcrumbs(
				state.breadcrumbs,
				get(action, 'payload.breadcrumbs'),
			);
			currentBreadcrumb = breadcrumbs.currentBreadcrumb;
			currentPageSeqNumber = get(action, 'payload.data.questionnarie.page_sequence_number', -1);
			footerNotes = get(action, 'payload.data.questionnarie.questions.footer_notes', []);
			haveQuestions = get(action, 'payload.data.questionnarie.questions.have_questions', {});
			routeChange = state.activeSubsectionId !== currentBreadcrumb.id;
			if (routeChange) {
				lastFocusedElementId = ''
			}
			return {
				...state,
				lastFocusedElementId,
				routeChange,
				currentPageSeqNumber,
				popupQuestions: get(action, 'payload.data.popup_questions', []),
				popupDetails: initialState.popupDetails,
				breadcrumbs,
				activeSubsectionId: currentBreadcrumb.id,
				layout: get(action, 'payload.data.questionnarie.questions.properties.layout.value', 'double') === 'one_column' ? 'single' : '',
				sections: {
					...state.sections,
					[currentBreadcrumb.id]: updatedBreadcrumbData(
						state.sections[currentBreadcrumb.id],
						currentPageSeqNumber,
						action,
						routeChange,
						state.eSign,
					),
				},
				footerNotes,
				haveQuestions,
				isLoading: false,
				retry: {
					...state.retry,
					interval: get(action, 'payload.other_params.retry', 0),
				},
				loader: {
					...state.loader,
					message: get(action, 'payload.other_params.message', 'Just a moment...'),
				},
			};

		case GET_QUOTE_SUCCESS:
		case GET_WELCOME_SUCCESS:
		case GET_HEALTH_SUCCESS:
		case GET_PLAN_SUCCESS:
		case GET_REVIEW_SUCCESS:
		case GET_QUOTEREVIEW_SUCCESS:
		case GET_SIGNATURE_SUCCESS:
		case GET_PAYMENT_SUCCESS:
		case GET_TIMEOUT_SUCCESS:
		case GET_INACTIVESESSION_SUCCESS:
		case GET_CHALLENGE_SUCCESS:
		case GET_RIDERS_SUCCESS:
		case GET_PROCESSING_SUCCESS:
		case GET_COMPLETED_SUCCESS:
		case GET_LOGIN_SUCCESS:
		case GET_KNOCKOUT_SUCCESS:
		case GET_UNSUBSCRIBED_SUCCESS:
		case GET_PRODUCTS_SUCCESS:
		case GET_INTERVIEW_SUCCESS:
		case GET_LAUNCH_SUCCESS:
		case GET_REFERRAL_SUCCESS:
		case GET_EHR_SUCCESS:
		case GET_STATIC_SUCCESS:
			bannerInfo=get(action,'payload.data.questionnarie.questions.header','');
			footerNotes=get(action,'payload.data.questionnarie.questions.footer_notes',[]);
			custCareTel=get(action,'payload.data.questionnarie.questions.cust_care_tel','');
			policyExpiry=get(action,'payload.data.questionnarie.questions.policy_expiry_date','');
			reviseButtonLabel=get(action,'payload.data.questionnarie.questions.revise_button_text','');
			footerBanner=get(action,'payload.data.questionnarie.questions.footer_banner',null);
		    pageType = get(action, 'payload.data.questionnarie.questions.question_id', '');
			haveQuestions = get(action, 'payload.data.questionnarie.questions.have_questions', {});
			subSectionId = get(action,'payload.other_params.current_status', '');
			currentPageSeqNumber = get(action, 'payload.data.questionnarie.page_sequence_number', -1);
			routeChange = state.activeSubsectionId !== subSectionId;
			isNetworkResponse =  get(action, 'params.isNetworkResponse');
			lastSuccessResponse = (isNetworkResponse === false) ? state.lastSuccessResponse : {
					data: {
						response: cloneDeep(action.payload),
					},
				};
			reviewActiveIds = getReviewActiveIds(action);
			if (routeChange) {
				lastFocusedElementId = ''
			}
			return {
				...state,
				bannerInfo,
				footerNotes,
				custCareTel,
				policyExpiry,
				reviseButtonLabel,
				footerBanner,
				lastFocusedElementId,
				routeChange,
				activeSubsectionId: subSectionId,
				currentPageSeqNumber,
				breadcrumbs,
				popupQuestions: get(action, 'payload.data.popup_questions', []),
				popupDetails: initialState.popupDetails,
				lastSuccessResponse,
				reviewActiveIds,
				sections: {
					...state.sections,
					[subSectionId]: updatedBreadcrumbData(
						state.sections[subSectionId],
						currentPageSeqNumber,
						action,
						routeChange,
						state.eSign,
					),
				},
				isLoading: false,
				haveQuestions,
				pageType,
				retry: {
					...state.retry,
					interval: get(action, 'payload.other_params.retry', 0),
				},
				loader: {
					...state.loader,
					message: get(action, 'payload.other_params.message', 'Just a moment...'),
				},
			};

		case UPDATE_QUESTION_ANSWER:
			prevSections = state.sections;
			updatedSectionQuestions = questionUpdates(
				state.sections[action.activeSubsectionId][
					action.payload.sectionIndex
				],
				state.currentPageSeqNumber,
				action,
			);
			// 'index' is used for updating the
			// last question and answer in a section
			prevSections[action.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;
			rootListIndx = action.payload.rootListIndex
			rootListId = action.payload.rootListId
			if(rootListIndx!==0 && !rootListIndx && !rootListId){
				// rootListIndx = null
				return {
					...state,
					sections: prevSections,
				};
			}
			return {
				...state,
				sections: prevSections,
				rootListIndex: rootListIndx,
				rootListId,
			};
		case UPDATE_QUESTION_ANSWER_TIME_STAMP:
			prevSections = state.sections;
			updatedSectionQuestions = timeStampUpdates(
				state.sections[action.payload.activeSubsectionId][
					action.payload.sectionIndex
				],
				action,
			);
			// 'index' is used for updating the
			// last question and answer in a section
			prevSections[action.payload.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			};
		case UPDATE_LIST_SECTION:
			prevSections = state.sections;
			updatedSectionQuestions = listSectionUpdates(
				state.sections[action.activeSubsectionId][
					action.payload.sectionIndex
				],
				action.payload.listQIds,
			)
			prevSections[action.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			}
		case UPDATE_DATA_SELECTOR_OPTIONS:
            prevSections = state.sections;
			updatedSectionQuestions = updateDataSelectorOptions(state.sections[action.activeSubsectionId][
				action.payload.sectionIndex
			], action)
			return {
				...state,
				sections: prevSections,
			}
		case API_IN_PROGRESS:
			return {
				...state,
				...action.payload,
			}
		case SET_CURRENT_BREADCRUMB:
			return {
				...state,
				breadcrumbs: {
					...state.breadcrumbs,
					currentBreadcrumb: getCurrentBreadcrumb(
						state.breadcrumbs.breadcrumbList,
						action.id,
					),
					breadcrumbList: state.breadcrumbs.breadcrumbList.map(
						(breadcrumb) => {
							if (breadcrumb.id === action.id) {
								return {
									...breadcrumb,
									active: true,
								};
							}
							return {
								...breadcrumb,
								active: false,
							};
						},
					),
				},
			};
		case SET_LIST_INDEX:
			return {
				...state,
				listIndex: action.index,
			}
		case SET_REFLEXIVE_LIST_INDEX:
			return {
				...state,
				listEditIndex: action.index,
				listEditQId: action.id,
			}
		case 'SET_ROOT_LIST_INDEX':
			return {
				...state,
				rootListIndex: action.index,
			}
		case 'SET_NESTED_LIST_INDEX':
			return {
				...state,
				nestedListIndex: action.index,
			}
		case SET_LIST_MODE:
			return {
				...state,
				listMode: action.index,
			}
		case 'SET_NESTED_LIST_MODE':
			return {
				...state,
				nestedListMode: action.index,
			}
		case 'UPDATE_LIST_RELATION':
			nestedListId = get(action, 'payload.listId', '');
			rootListId = get(action, 'payload.rootListId', '')
			return {
				...state,
				listQMap: {
					...state.listQMap,
					[nestedListId] : rootListId,
				},
			}
		case 'SET_ROOT_LIST_ID':
			return {
				...state,
				rootListId: action.id,
			}
		case 'ILLUSTRATION_PDF_FETCH':
			prevSections = state.sections;
			updatedSectionQuestions = updateIllustrationPDF(
				state.sections[action.activeSubsectionId][
					action.payload.sectionIndex
				],
				state.currentPageSeqNumber,
				action,
			);
			prevSections[action.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			}
		case GET_QUESTION_FAILURE:
		case SET_ACTION_MESSAGE:
			actionMessage = get(action, 'payload.actionMessage', {
				type: '',
				message: '',
			});
			return {
				...state,
				isLoading: false,
				actionMessage: {
					type: actionMessage.type,
					message: actionMessage.message,
				},
			};
		case TOGGLE_ESIGN_BUTTON:
			prevSections = state.sections
			updatedSectionQuestions = prevSections[state.activeSubsectionId][state.sectionIndex];
			updatedSectionQuestions.enableContinue = getContinueStatus(updatedSectionQuestions);
			return {
				...state,
				sections: prevSections,
				eSign: {
					...state.eSign,
					hasESign: true,
					eSignEnable: action.enable,
				},
			}
		case TOGGLE_SIGNATURE_CONTINUE:
			prevSections = state.sections;
			updatedSectionQuestions = toggleSectionButtonState(state.sections[state.activeSubsectionId][action.sectionIndex], action);
			prevSections[state.activeSubsectionId][
				action.sectionIndex
			] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			}
		case SET_PDF_CONFIGS:
			return {
				...state,
				eSign: {
					...state.eSign,
					pdfBlobURL: action.url,
					pdfNumPages: action.pages,
				},
			}
		case SET_POPUP_DETAILS:
			return {
				...state,
				popupDetails: action.payload,
			}
		case UPDATE_LAST_FOCUSED_ELEMENT_ID:
			return {
				...state,
				lastFocusedElementId: get(action, 'payload.id', ''),
			}
		case 'UPDATE_REQUIRED_SCHEMA':
			prevSections = state.sections;
			updatedSectionQuestions = updateRequiredSchema(
				state.sections[action.activeSubsectionId][
					action.payload.sectionIndex
				],
				action,
			)

			prevSections[action.activeSubsectionId][
				action.payload.sectionIndex
			] = updatedSectionQuestions;

			return {
				...state,
				sections: prevSections,
			}
		default:
			return state;
	}
}