/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect,useRef } from 'react';
import at from 'lodash/at';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RadioGroup, Tooltip } from 'crux';
import { withTheme } from 'styled-components';

import get from 'lodash/get';
// import isPlainObject from 'lodash/isPlainObject';
import TextElement from '../Components/UIElements/TextElement';
import { questionType } from '../types';

import {
  getShape,
  getWidth,
  getTooltipProps,
  getLabelProps,
  getCSSVariables,
  getTimeStampData,
} from './utils';
import { QuestionDescription } from './utils/styles';
import { updateAnswersArray, updateQuestionAnswerTimeStamp } from '../redux/actions';
import SelectWrapper, { TooltipWrapper } from './SelectWrapper';
import { validateInnerHtml, parseHtml, getUTCTimestamp } from '../utils';
import { SET_LIST_MODE } from '../redux/types';

const SingleSelect = ({
  question,
  optionalText,
  theme,
  sectionIndex,
  handleChange,
  isEditMode,
  currentSectionAnswerList,
  lastFocusedElementId,
  updateTimeStamp,
  meta,
  listParentId,
  changeMode,
	isListMode,
	updateListMode,
  isDisabled,
}) => {
  const {
    display_type: displayType,
    question_id: questionId,
    response_options: options,
    question_text: questionText,
    question_description: questionDescription,
    hint_title: tooltipTitle,
    hint_text: tooltipBody,
    hint_html: tooltipHTML,
    is_readonly: isReadonly,
    response,
    orientation,
    tooltip,
    is_editable: isEditable,
    is_hidden: isHidden,
    transaction_log: transactionLog,
		last_submitted_at: lastSubmittedAt,
  } = question;
  const {
    pageId,
    config: {
      theme: {
        pageOverrides,
        components,
        global: { colorScheme, colorOverride },
      },
    },
  } = theme;

  const inputRef = useRef();
  const timeStampRef = useRef(null);
  // Tab Navigation
  useEffect(() => {
    const shouldFocus = lastFocusedElementId === questionId;
    if (shouldFocus) {
      const _options = document.getElementsByName(lastFocusedElementId);
      _options.forEach((element) => {
        if (element.checked) {
          element.focus();
        }
      });
    } else if (inputRef && inputRef.current && (shouldFocus || lastFocusedElementId==='btn-continue')) {
      if (typeof inputRef.current.focus === 'function') {
        if(lastFocusedElementId==='btn-continue'){
          if(questionId === currentSectionAnswerList[0]?.question_id && currentSectionAnswerList[0]?.answer === ''){
            inputRef.current.focus();
          }
        }
      }
    }
  }, []);

  // const checked = isPlainObject(response) ? response.id : response;

  useEffect(() => {
    if (isEditMode || question.response) {
      handleChange(question.response, true);
      updateTimeStamp({ transactionLog, lastSubmittedAt }, true);
    }
    if(isListMode && !listParentId){
			changeMode('')
			updateListMode(false)
		}
  }, []);

  const handleFocus = () => {
    timeStampRef.current = {};
    timeStampRef.current.timeStart = getUTCTimestamp();
		updateTimeStamp(getTimeStampData(timeStampRef, transactionLog, meta))
  }

  const handleOnBlur = () => {
    updateTimeStamp(getTimeStampData(timeStampRef, transactionLog, meta))
	}

  const { singleSelect } = components;
  const { shape, overlap, labelWidth } = getShape({
    questionId,
    displayType,
    singleSelect,
  });

  const tooltipProps = getTooltipProps(
    tooltipTitle,
    tooltipBody,
    tooltipHTML || tooltip,
  );

  const labelProps = getLabelProps(
    'singleSelect',
    components,
    pageId,
    pageOverrides,
  );

  const customRadio = get(theme, 'config.theme.components.radio', {});
  let customProps = {};
  if (customRadio) {
    customProps = customRadio[shape];
  }
  const themeConfiguration = colorOverride
    ? {
        ...colorOverride,
      }
    : get(customProps, 'themeConfiguration', '');

  const selectWrapperTheme = get(customRadio, 'selectWrapper', '');
  const hideTooltipWrapper = get(customProps, 'hideTooltipWrapper', false);
  const { questionDescriptionColor } = get(theme, 'config.theme.global.text');
  let isTextOverflow = false;
  for(let i=0; i < options.length; i+=1){
    if(options[i].label.length > 168){
      isTextOverflow = true
      break;
    }
  }

  // CSS Variables
  const element = '.singleSelect-description';
  const radioElement = `.singleSelect-${shape}`;
  const radioDesElement = '.singleSelect-label';
  const cssVariables = getCSSVariables(theme);
  const font = at(cssVariables, [
    `pageOverride.font${element}`,
    `global.font${element}`,
  ]);
  const radioDesFont = at(cssVariables, [
    `pageOverride.font${radioDesElement}`,
    `global.font${radioDesElement}`,
  ]);

  const radioFont = at(cssVariables, [
    `pageOverride.font${radioElement}`,
    `global.font${radioElement}`,
  ]);
  const labelTheme = get(labelProps, 'labelText.themeConfiguration', {});
  const marginCollapse = get(question, 'properties.margin.margin_collapse', false);
  const mql = window.matchMedia('(forced-colors: active)');
  if(mql.matches) {
    customProps.themeConfiguration = {
      ...customProps.themeConfiguration,
      checkedBg: 'Highlight',
      border: '1px solid',
    } }
  const themeConfig = {
    ...themeConfiguration,
    font: radioFont[0] || radioFont[1],
  };
  const disabled = displayType==='product_card' ? isReadonly :isReadonly || !isEditable;
  const isAsteriskConfig = get(
    theme,
    'config.theme.components.inputAsteriskRequired',
  );
  let isAsterisk;
  if(isAsteriskConfig && question.validations.required && question.validations.required.value === true){
    isAsterisk = true;
  }
  if(isHidden) return null;
  // const isRequiredValid = get(question, 'validations.required.value', false);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <fieldset aria-label={ parseHtml(questionText) } ref={ (e)=> { inputRef.current = e } } style={ { width: 'fit-content' } } inputRef={ inputRef }>
      {/* <legend style={ { fontSize: 0, margin: 0, display: 'none'  } }>
        {parseHtml(questionText)}
      </legend> */}
      <SelectWrapper
        ref={ timeStampRef }
        width={ getWidth('singleSelect', pageId, pageOverrides) }
        themeConfiguration={ selectWrapperTheme }
        optionsLength = { options?.length }
        displayType = { displayType }
        marginCollapse = { marginCollapse }
        isTextOverflow = { isTextOverflow }
        onBlur={ handleOnBlur }
      >
        <TooltipWrapper id={ `radio-${shape}-label-${questionId}` } hideTooltipWrapper={ hideTooltipWrapper } hasTooltip = { tooltipProps?.hasTooltip }>
          <TextElement
            styleType="label"
            text={ `${questionText} ${optionalText}` }
            sectionIndex={ sectionIndex }
            elementTheme={ labelTheme }
            baseFont={ radioDesFont[0] || radioDesFont[1] }
            isAsterisk={ isAsterisk }
            inputRef={ inputRef }
            id={ `${questionId}-questionText` }
            radioGroupLabel
          />
          {tooltipProps.hasTooltip && (
            <Tooltip ariaLabel={ `${questionText} tooltip` } { ...tooltipProps } />
          )}
        </TooltipWrapper>

        {questionDescription && (
          <QuestionDescription color={ questionDescriptionColor } font={ font }>
            <span
              dangerouslySetInnerHTML={ {
                __html: validateInnerHtml(questionDescription),
              } }
            />
          </QuestionDescription>
        )}

        <RadioGroup
          onChange={ (e, option) => {
            handleChange(option, false, true);
          } }
          options={ options }
          onFocus={ handleFocus }
          name={ questionId }
          id={ questionId }
          shape={ shape }
          overlap={ overlap }
          checked={ response ? response.id : '' }
          color={ colorScheme }
          labelWidth={ labelWidth }
          themeConfiguration={ themeConfig }
          orientation={ orientation || 'rows' }
          { ...({ childOption: { marginLeft : '35px' }  }) }
          { ...labelProps }
          { ...customProps }
          disabled={ isDisabled || disabled }
        />
      </SelectWrapper>
    </fieldset>
  );
};

SingleSelect.propTypes = {
  question: questionType.isRequired,
  sectionIndex: PropTypes.number.isRequired,
};
const mapStateToProps = (state,props) => {
  const { questions } = state;
  const { activeSubsectionId, sections, lastFocusedElementId } = questions;
  let currentSectionAnswerList = [];
  if (sections) {
    let currentSection = [];
    const { sectionIndex } = props;
    if (activeSubsectionId && sectionIndex) {
      currentSection = sections[activeSubsectionId][sectionIndex];
    }
    if (currentSection && currentSection.answersArray) {
      currentSectionAnswerList = currentSection.answersArray;
    }
  }
  return {
    lastFocusedElementId,
    currentSectionAnswerList,
    meta: state.meta,
    isListMode: state?.questions?.listMode,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const questionId = get(props, 'question.question_id', '');
	const transactionLog = get(props, 'question.transaction_log', []);
  return {
    handleChange: (response, preventReflexiveCall = false, hasEdit = false) => {
      const auditRequired = get(props, 'question.audit_required', false);
      const displayType = get(props, 'question.display_type', '');
      const currentStatus = get(props, 'theme.pageId', '');
      dispatch(
        updateAnswersArray({
          questionId,
          hasEdit,
          response: response?.id || '',
          questionResponse: response,
          sectionIndex: props.sectionIndex,
          propQuestion: props.question,
          preventReflexiveCall,
          listParentId: props.listParentId,
          listIndex: props.listIndex,
          auditRequired,
          displayType,
          currentStatus,
          transactionLog,
        }),
      );
    },
    updateTimeStamp: (
			timeStampData,
			isPrefilled=false,
		  ) => {
        dispatch(updateQuestionAnswerTimeStamp(questionId, timeStampData, props.sectionIndex, isPrefilled))
    },
    updateListMode: (mode) => {
			dispatch({
			  type: SET_LIST_MODE,
			  index: mode,
			})
		},
  };
};
export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(SingleSelect),
);
