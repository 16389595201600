/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
/* eslint-disable consistent-return */
import React from 'react';
import { Tooltip } from 'crux';
import styled from 'styled-components';
import isObject from 'lodash/isObject';
import { get } from 'lodash';
import { insertReflexiveQuestions, validateInnerHtml } from '../../../utils';
// eslint-disable-next-line import/no-cycle
import GetElementFunc from '../../../util/GetElement';
import { getTooltipProps } from '../../../cruxComponents/utils';
import ListQuestions from '../../List';
import { DisplayInlineQuestions } from '../../QuestionRenderer';

const Row = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }
`;

const Label = styled.div`
  color: rgba(93, 93, 93, 1);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const Value = styled.div`
  color: rgba(93, 93, 93, 1);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-left: 75px;
  text-align: right;
  @media only screen and (max-width: 767px) {
    padding-left: 0;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
`;

const TooltipIcon = styled.div`
  margin: 1px 10px;
`

export default function FieldSet({
  fields,
  isEditMode,
  sectionIndex,
  index: listIndex,
  listParentId,
  changeMode,
  rootListIndex,
  isReview,
  isNestedList,
  listDisplay,
  rootListId,
  activeSubsectionId,
  screenViewport,
  pageOverrides,
  pageId,
}) {
  let inlineQs= { isDisplayInline: false }

  if (isEditMode) {
    return (
      <>
        {
          fields.map(question => {

            if (typeof question.response === 'string') question.response = question.response.trim();

            if(!question?.properties?.inline?.value)
                inlineQs= { isDisplayInline: false, dislayInlineIdx: -1 }

            if(inlineQs.isDisplayInline) return ;

            if (question.display_type === 'list') {
              return (
                <ListQuestions
                  key={ question.question_id }
                  sectionIndex={ sectionIndex }
                  questionObj={ question }
                  reflexLevel={ 0 }
                  listId={ question.question_id }
                  listAddButtonLabel={ question.list_add_button_label }
                  changeMode={ changeMode }
                  originalQuestions={ question.child_questions_completed_flag ? question.questions : question.original_questions }
                  listDisplay={ question.child_questions_completed_flag }
                  isReview = { isReview }
                  isNestedList
                  rootListIndex = { listIndex }
                  rListDisplay = { (isNestedList ? listDisplay :  question.child_questions_completed_flag) }
                  rootListId = { rootListId }
                />
              );
            }

            if(question?.display_type === 'questions_group' && question?.properties?.orientation?.value){

              inlineQs= { isDisplayInline: true }

              const updatedQuestionList = insertReflexiveQuestions([question]).filter(ques => ques.display_type!=='list' && (ques?.properties?.inline?.value || ques?.properties?.orientation?.value));

              const index = 0;
              const elements = [];
              const orientationType = get(question, 'properties.orientation.type', '');
              const orientationValue = get(question, 'properties.orientation.value', 1);
              const overrideReflexLevel = isReview;
              const displayInlineQuestionsProps = {
                updatedQuestionList,
                orientationType,
                orientationValue,
                elements,
                index,
                screenViewport,
                pageOverrides,
                pageId,
                activeSubsectionId,
                sectionIndex,
                overrideReflexLevel,
                changeMode,
              }

              return <DisplayInlineQuestions { ...displayInlineQuestionsProps }/>
            }

            return (
              <GetElementFunc
                questionObj={ question }
                sectionIndex={ sectionIndex }
                required
                isEditMode
                listParentId={ listParentId }
                listIndex={ listIndex }
                changeMode = { changeMode }
                rootListIndex = { rootListIndex }
            />
            );
          })
        }
      </>
    )
  }
  return (
    <>
      {
        fields.map(question => {
          const {
            hint_title: tooltipTitle,
            hint_text: tooltipBody,
            hint_html: tooltipHTML,
            tooltip,
            is_hidden: isHidden,
          } = question;
          const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);

          let value = isObject(question.response) && !question.response.length ? question.response.label : question.response;
          if(value?.length && typeof value !== 'string' && value[0].label) {
            value = value[0].label
          }
          const label = question?.question_text || question?.question_label;

          if(isHidden) return ;

          if (question.display_type === 'list') {
            return (
              <ListQuestions
                key={ question.question_id }
                sectionIndex={ sectionIndex }
                questionObj={ question }
                reflexLevel={ 0 }
                listId={ question.question_id }
                listAddButtonLabel={ question.list_add_button_label }
                changeMode={ changeMode }
                originalQuestions={ question.child_questions_completed_flag ? question.questions : question.original_questions }
                listDisplay={ question.child_questions_completed_flag }
                isReview = { isReview }
                isNestedList
                rootListIndex = { listIndex }
                rListDisplay = { (isNestedList ? listDisplay :  question.child_questions_completed_flag) }
                rootListId = { rootListId }
              />
            );
          }
          return (
            value && (
              <Row name='row' key={ question.question_id }>
                <TooltipWrapper>
                  <Label name='label'
                    htmlFor={ question.question_id }
                    dangerouslySetInnerHTML={ { __html: validateInnerHtml(label) } }
                  />
                  {tooltipProps.hasTooltip && (
                    <TooltipIcon>
                      <Tooltip
                        ariaLabel={ `${question.question_text} tooltip` }
                        name='tool-tip'
                        { ...tooltipProps }
                      />
                    </TooltipIcon>
                  )}
                </TooltipWrapper>
                <Value data-clarity-mask="True" label='value'>{value}</Value>
              </Row>
            )
          )
        })
      }
    </>
  )
}
