import React, { useState,useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import at from 'lodash/at';
import { withTheme } from 'styled-components';
import { FocusTrap, Button, Alert } from 'crux';
import {
  submitSectionQuestion,
  updateAnswersArray,
  updateQuestionAnswerTimeStamp,
} from '../../../redux/actions/questions';
import Cover from '../../StyledComponents/MaskCover/Index';
import { getLoaders } from '../../../redux/selector/validations';
import { formatAddress } from '../../../util';
import { 
  ModalWrapper, ModalHeading, ModalBody, QuestionBlock, 
  QuestionWrapper, QuestionHeading, QuestionContent,
  Address, ButtonWrapper, Error, OuterCircle, InnerCircle,
  QuestionDescription,
} from './ModalStyles';
import { getCurrentQuestionSet } from '../../../redux/selector/questions';
import { getCSSVariables } from '../../../cruxComponents/utils';
// eslint-disable-next-line import/no-cycle
import QuestionRenderer from '../../QuestionRenderer';
import { getUTCTimestamp } from '../../../utils';

const Modal = (props) => {
  const {
    errors,
    questionObj,
    handleModalButtonClick,
    enableContinue,
    isLoading,
    theme,
    showEditPageFlag,
    modalQuesitionList,
    sectionIndex,
    handleConfirmClick,
    updateTimeStamp,
  } = props;

  const timeStampRef = useRef(null);

  const [response, setResponse] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [error, setError] = useState(false);
  const { errorMessage, errorTitle, hasError } = errors;
  const {
    question_text: questionText,
    question_description: QuestionDesc,
    response_options: resOptions,
    send_individual_questions: sendIndividualQuestions,
    popup_button_text: popupButtonText,
    transaction_log: transactionLog,
  } = questionObj;

  const {
    config: {
      theme: {
        components: {
          card: { themeConfiguration:cardThemeConfiguration },
        },
      },
    },
  } = theme;
  const handleClick = () => {
    const answeredTime = getUTCTimestamp();
    const prevTransactionLog = transactionLog && transactionLog.length ? transactionLog : [];
    updateTimeStamp(
      { 
        transactionLog: [
          ...prevTransactionLog,
          { 
            time_start: answeredTime,   
            time_end: answeredTime,
          },
        ], 
        lastSubmittedAt: answeredTime,
      },
    );
    if(response === '') {
      setError(true);
    } else {
      setError(false);
      handleModalButtonClick(response, true, selectedId,showEditPageFlag);
    }
  };

  const handleModalFocus = () => {
    timeStampRef.current.timeStart = getUTCTimestamp();
  }

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () =>{
      document.body.style.overflowY = 'auto';
    }
  }, [])

  const getSelectedOption = (options, id) => {
    const selected = options.find(option => option.id === id);
    const res = [];
    // this creates an array of selected address, city, zip code, state
    Object.values(selected).forEach(val => {
      if(typeof val === 'object') {
        res.push(val);
      }
    })
    return res[0];
  }

  const selectedAddress = (id) => {
    setSelectedId(id);
    if(id !== '') {
      setError(false);
    }
    // Back end sends 'sendIndividualQuestions' param as true if
    // we need to send entire selected address, city, zip code rather than just 
    // selected option's id which is the case with AllState, 
    // other wise both the 'response' and the 'id' will be same
    if(!sendIndividualQuestions) { 
      setResponse(id); 
    }
     else {
      const selectedOption = getSelectedOption(questionObj.response_options, id);
      const { question_id: questionId } = questionObj;
      const updatedResponse = {
        ...selectedOption,
        [questionId]: {
          question_id: questionId,
          value: id,
        },
      }
      setResponse(updatedResponse);
    }
  }

  useEffect(() => {
    const prePopulatedResponseId = get(questionObj, 'response.id', '');
    if(prePopulatedResponseId) {
      selectedAddress(prePopulatedResponseId);
    }
  }, []);

  const colorOverride = get(props, 'theme.config.theme.global.colorOverride', null );
  const modalButtonTheme = get(props, 'theme.config.theme.global.modalButton.themeConfiguration', {});

   // eslint-disable-next-line react/destructuring-assignment
   const cssVariables =  getCSSVariables(props.theme);
   const font = at(cssVariables, ['pageOverride.font', 'global.font']);
   modalButtonTheme.font = font?.[0]?.['button-modal'] || font?.[1]?.['button-modal']
  const themeConfiguration = colorOverride ? {
    selectedBgColor: colorOverride.selectedBgColor,
    bgColor: '#ffffff',
    border: `1px solid ${colorOverride.selectedBgColor}`,
    fontColor: '#333333',
  } : modalButtonTheme;

  const getResponseOptions = () => {
    return resOptions.map(({ id, label, msg, suggested, actual }) => {
      return (
        <QuestionBlock 
          id={ id } 
          tabIndex='0' 
          name='questions-block' 
          selectedQuestion={ id === selectedId } 
          onKeyDown={ (e) => e.key === 'Enter' && selectedAddress(id) } 
          onClick={ () => selectedAddress(id) } 
        >
          <OuterCircle selected={ id === selectedId } tabIndex='-1'> 
            <InnerCircle tabIndex='-1'/>
          </OuterCircle> 
          <QuestionWrapper name='question-wrapper'>
            <QuestionHeading name='questions-heading'>{label}</QuestionHeading>
            <QuestionContent name='questions-content'>
              <Address name='address'>
                {(id.toString().match(/[0-9]/g) || id.toString().includes('suggested')) ? msg || formatAddress(suggested, sendIndividualQuestions) : formatAddress(actual, sendIndividualQuestions)}
              </Address>
            </QuestionContent>
          </QuestionWrapper>
        </QuestionBlock>
      );
      })
  }

  const getModalBody = () => {
    return isEmpty(resOptions) ? <>
    <QuestionRenderer
    questionList={ modalQuesitionList }
    sectionIndex={ sectionIndex }
    showModalQuestions
  />
  <ButtonWrapper name="button-wrapper">
    <Button
      name="confirm-button"
      label={ popupButtonText || 'Confirm' }
      type="filled"
      shape="rounded"
      handleButtonClick={ (e) => {
        e.preventDefault();
        handleConfirmClick();
      } }
      width="90%"
      disabled={ !enableContinue }
      color="blue"
      themeConfiguration={ themeConfiguration }
      alignment="center"
      tabIndex="0"
    />
  </ButtonWrapper>
    </> :
    <>
    {getResponseOptions()}
    <ButtonWrapper name='button-wrapper' onClick={ handleClick }>
      <Button
          name='confirm-button'
          label={ popupButtonText || 'Confirm Address' }
          type="filled"
          shape="rounded"
          handleButtonClick={ (e) => {
            e.preventDefault();
            handleClick();
          } }
          width="90%"
          disabled={ false }
          color="blue"
          themeConfiguration={ themeConfiguration }
          alignment="center"
          tabIndex='0'
      />
    </ButtonWrapper>
    </>
  }

  return (
    <FocusTrap>
      <Cover name='cover'/>
      <ModalWrapper name='modal-wrapper' ref={ timeStampRef } isLoading={ isLoading } size={ isEmpty(resOptions) } cardThemeConfiguration={ cardThemeConfiguration } onFocus={ handleModalFocus }>
        {error ? <Error>Please select atleast one option!</Error> : null}
        {hasError && (
          <Alert message={ errorMessage } type="error" title={ errorTitle } />
        )}
        <ModalHeading name='modal-heading'>{questionText}</ModalHeading>
        <QuestionDescription error={ error }>{QuestionDesc}</QuestionDescription>
        <ModalBody name='modal-body'>
         {getModalBody()}
        </ModalBody>
        </ModalWrapper>
    </FocusTrap>
  );
};

function mapStateToProps(state, ownProps) {
  const { isLoading } = getLoaders(state);
  const { otherParams  } = getCurrentQuestionSet(state);
  const showEditPageFlag = get(otherParams, 'show_edit_page_flag', false)
  const activeSubsectionId = get(state, 'questions.activeSubsectionId');
  const { sectionIndex } = ownProps;
  const { enableContinue, questionList } = get(
    state,
    `questions.sections.${activeSubsectionId}[${sectionIndex}]`,
    {
      enableContinue: true,
      questionList: [],
    },
  );
  const modalQuesitionList = questionList.filter((q) => q.isModalQuestion);
  const { actionMessage } = state.questions;
  let errors = { hasError: false }
	if (actionMessage && actionMessage.message && actionMessage.type !== 'success') {
		errors = {
			hasError: true,
			errorMessage: actionMessage.message,
		}
	}
  return {
    errors,
    isLoading,
    showEditPageFlag,
    enableContinue,
    modalQuesitionList,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const questionId = get(ownProps, 'questionObj.question_id', '');
  return {
    handleConfirmClick: (preventReflexiveCall = true) =>{
      const isHidden = !get(ownProps, 'questionObj.is_hidden', false);
      const auditRequired = get(ownProps, 'question.audit_required', false);
      dispatch(
        updateAnswersArray({
          questionId,
          response: '',
          sectionIndex: ownProps.sectionIndex,
          preventReflexiveCall,
          isHidden,
          auditRequired,
        }),
      );
      dispatch(submitSectionQuestion(ownProps.sectionIndex));
    },
    handleModalButtonClick: (response, preventReflexiveCall, id,showEditPageFlag) => {
      const isHidden = !get(ownProps, 'questionObj.is_hidden', false);
      const auditRequired = get(ownProps, 'question.audit_required', false);
      const sendIndividualQuestions = get(ownProps, 'questionObj.send_individual_questions', false)
      if(sendIndividualQuestions) {
        Object.values(response).forEach(res => {
          dispatch(
            updateAnswersArray({
              questionId: res.question_id,
              response: res.value,
              sectionIndex: ownProps.sectionIndex,
              preventReflexiveCall,
              isControlledInput: true,
              isHidden: false,
              auditRequired,
            }),
          );
        })
      }
      dispatch(
        updateAnswersArray({
          questionId,
          response: id,
          sectionIndex: ownProps.sectionIndex,
          preventReflexiveCall,
          isHidden,
          auditRequired,
        }),
      );
      if(showEditPageFlag){
      dispatch(submitSectionQuestion(ownProps.sectionIndex,{},'CONFIRM_UPDATE'));
      }
      else{
      dispatch(submitSectionQuestion(ownProps.sectionIndex));
      }
    },
    updateTimeStamp: (
      timeStampData,
    ) => {
      dispatch(updateQuestionAnswerTimeStamp(questionId, timeStampData, ownProps.sectionIndex))
    },
  };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Modal));
