import React from 'react';
import styled from 'styled-components';
import { RotatedArrows } from '../../assets/Images/svg/RotatedArrows';

const AccordionSummaryWrapper = styled.div`
  border-bottom: 1px solid var(--global-bh-light-gray, #e9e9e9);
  padding: 16px 16px 8px;

  cursor: ${({ isAccordionCardsDisabled }) =>
    !isAccordionCardsDisabled ? 'grab' : 'no-drop'};

  ${({ isAccordionSelected, isAccordionCardsDisabled }) =>
    isAccordionSelected
      ? `
    & {
      --accordion-text-color: white;
      background-color: #0B465A;
    }
  `
      : !isAccordionCardsDisabled &&
        `
      &:hover {
        background-image: linear-gradient(90deg,rgba(0,0,0,0.04),rgba(0,0,0,0.04));
      }
    `}
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PolicyHeaderContainer = styled.div``;

const PolicyTitle = styled.div`
  color: var(--accordion-text-color, #333);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.016px;
`;

const PolicySubTitles = styled.div`
  color: var(--accordion-text-color, #333);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  display: flex;
  flex-direction: row;
  align-items: center;

  span:nth-last-child(n + 2)::after {
    content: ' | ';
    margin-right: 5px;
  }

  span > svg {
    margin: 0 5px;
  }

  .policy-subtitle-icon {
    display: flex;
    align-items: center;
  }
`;

const AccordionToggle = styled.div`
  color: var(--accordion-text-color, #00465a);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.46px;
  padding: 8px 11px;
  cursor: pointer;
  transition: transform 750ms;
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const AccordionSummary = ({
  index,
  onToggle,
  expanded,
  onAccordionClick,
  isAccordionSelected,
  accordionSummaryData,
  isAccordionCardsDisabled,
}) => {
  const getAccBriefQ = (qIdTextMatch) => {
    const qIdRegex = new RegExp(qIdTextMatch, 'i');
    const accQBrief = accordionSummaryData.filter((eachAccBrief) => {
      return (
        (eachAccBrief.question_text.match(qIdRegex) &&
          eachAccBrief.question_id.match(qIdRegex)) ||
        eachAccBrief.question_id.match(qIdRegex)
      );
    });
    return accQBrief?.length === 1 ? accQBrief[0] : accQBrief;
  };

  const premiumAmountQ = getAccBriefQ('amount');
  let premiumAmount = premiumAmountQ?.response;
  premiumAmount = !premiumAmount ? '' : premiumAmount.trim();

  const riderNameQ = getAccBriefQ('RiderName');
  let riderName = riderNameQ?.response;
  riderName = riderName?.split(',').join(' ');
  riderName = !riderName ? '' : riderName.trim();

  const riderOptionQ = getAccBriefQ('RiderOption');
  let riderOption = riderOptionQ?.response;
  riderOption = riderOption?.split(',').join(' ');
  riderOption = !riderOption ? '' : riderOption.trim();

  const advidorIdQ = getAccBriefQ('AID');
  const advidorId = advidorIdQ?.response;

  const revenueServiceCodeQ = getAccBriefQ('Ten35');
  let revenueServiceCode = revenueServiceCodeQ?.response?.trim();
  revenueServiceCode = revenueServiceCode === 'true';

  return (
    <AccordionSummaryWrapper
      className="accordion-summary"
      onClick={ () => onAccordionClick(index) }
      isAccordionSelected={ isAccordionSelected }
      isAccordionCardsDisabled={ isAccordionCardsDisabled }
    >
      <AccordionHeader className="accordion-header">
        <PolicyHeaderContainer className="policy-header-container">
          <PolicyTitle>
            Premium Amount: {formatter.format(premiumAmount)}{' '}
          </PolicyTitle>
          <PolicySubTitles>
            <span>{riderName}</span>
            <span>{riderOption}</span>
            {advidorId && (
              <span className="policy-subtitle-icon">
                <p> {advidorId} </p>
              </span>
            )}
            {revenueServiceCode && (
              <span className="policy-subtitle-icon">
                <RotatedArrows isLightTheme={ isAccordionSelected } />
                <p> 1035 </p>
              </span>
            )}
          </PolicySubTitles>
        </PolicyHeaderContainer>
        <AccordionToggle
          className="accordion-toggle"
          id="accordion-toggle"
          onClick={ (e) => {
            e.stopPropagation();
            onToggle();
          } }
        >
          {expanded ? 'Hide' : 'Details'}
        </AccordionToggle>
      </AccordionHeader>
    </AccordionSummaryWrapper>
  );
};

export default AccordionSummary;
