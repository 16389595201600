import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadcrumbWrapper from './wrapper';
import { Size } from './index';

const splitBreadcrumbs = (list) => {
  return list.reduce((acc, breadcrumb) => {
    let build;
    if (breadcrumb.state === 'incomplete') {
      build = {
        ...acc,
        incomplete: [
          ...acc.incomplete,
          breadcrumb,
        ],
      };
    } else {
      build = {
        ...acc,
          complete: [
          ...acc.complete,
          breadcrumb,
        ],
      };
    }
    return build;
  }, {
    incomplete: [],
    complete: [],
  })
}

const MobileBreadCrumbs = ({ breadcrumbList, children }) => {
  const { incomplete, complete } = splitBreadcrumbs(breadcrumbList);
  return (
    <>
      <BreadcrumbWrapper
        size={ Size.S }
        overrideBreadcrumbs={ complete }
        complete
      />
      { children }
      <BreadcrumbWrapper size={ Size.S } overrideBreadcrumbs={ incomplete } />
    </>
  )
};

MobileBreadCrumbs.defaultProps = {};

MobileBreadCrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ questions }) => {
  return {
    breadcrumbList: questions.breadcrumbs.breadcrumbList,
  }
}

export default connect(mapStateToProps, {})(MobileBreadCrumbs);
