/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { TextInput, NumberInput, RangeSlider } from 'crux';
import at from 'lodash/at';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { updateAnswersArray, updateQuestionAnswerTimeStamp } from '../redux/actions';
import {
  getLabelProps,
  getValidators,
  getWidth,
  getTooltipProps,
  getCSSVariables,
  validateQuestionInput,
  getTimeStampData,
} from './utils';
import {
  QuestionDescription,
  QuestionText,
  InputWrapper,
  NumberInputWrapper,
  Wrapper,
} from './utils/styles';
import ActionButtons from '../Components/QuestionsLib/ActionButtons';
import { getUTCTimestamp, validateInnerHtml } from '../utils';
import { SET_LIST_MODE } from '../redux/types';
import { ListContext } from '../Components/List/ListContext';
import TextElement from '../Components/UIElements/TextElement';

const FakeInput = styled.div.attrs({
  'tabIndex':'0',
})`
  position: absolute;
  left: -9999px;
`;

const LabelText = styled.div``;

const LabelTextAryResp = styled.div`
  >:first-child{
    font-size: 14px;
  }
`

const Input = ({
  question,
  optionalText,
  theme,
  handleChange,
  isEditMode,
  sectionIndex,
  currentSectionAnswerList,
  lastFocusedElementId,
  screenViewport,
  currentSectionQuestionsList,
  listParentId,
  rootListIndex=null,
  rootListId = '',
  isLoading,
  updateTimeStamp,
  meta,
  changeMode,
	isListMode,
	updateListMode,
  isDisabled,
}) => {

  const mask = !!get(question, 'validations.mask.mask_char', false);
  const relativeQuestionId = get(question, 'validations.relative.questionId', '');

  const {
    question_id: questionId,
    question_type: questionType,
    question_text: questionText,
    display_type: displayType,
    question_description: questionDescription,
    child_questions: hasChildQuestions,
    hint_title: tooltipTitle,
    hint_text: tooltipBody,
    hint_html: tooltipHTML,
    is_mras_question: isMrasQ,
    question_status_message: qStatusMsg,
    question_status: qStatus,
    response,
    action,
    tooltip,
    isControlledInput,
    validations,
    questions: childQuestionsArray,
    is_editable: isEditable,
    reflexive_index: reflexiveIndex,
    label,
    is_hidden: isHidden,
    transaction_log: transactionLog,
    last_submitted_at: lastSubmittedAt,
    properties,
  } = question;

  // Setting the initial response for Input Component  field
  const { listState, handleListAction } = useContext(ListContext)

  // Setting the initial response for the Input Component Update sample
  useEffect(() => {
    if (isEditMode || question.response) {
      handleChange(question.response, false, mask, {}, true, false, currentSectionQuestionsList,currentSectionAnswerList, rootListIndex, rootListId, relativeQuestionId, question?.validations?.is_selective_field_validate);
      /** Prefill TimeStamp */
      updateTimeStamp(questionId, { transactionLog, lastSubmittedAt }, true);
    }
    if(isListMode && !listParentId){
			changeMode('')
			updateListMode(false)
		}
  }, []);
  const {
    pageId,
    config: {
      theme: {
        pageOverrides,
        components,
        global: { colorScheme, errorMessageColor },
      },
    },
  } = theme;

  const labelProps = getLabelProps('input', components, pageId, pageOverrides);
  const { questionTextColor, questionDescriptionColor } = get(
    theme,
    'config.theme.global.text',
  );
  // const themeConfig = pageOverrides[pageId] && pageOverrides[pageId].themeConfiguration;
  const sliderLabelStyles =
    screenViewport === 'mobile'
      ? pageOverrides[pageId] && pageOverrides[pageId].sliderLabelStyles
      : [];
  const buttonLabelProps = getLabelProps(
    'button',
    components,
    pageId,
    pageOverrides,
  );

  // Tab Navigation
  const inputRef = useRef();
  const fakeInputRef = useRef();
  const timeStampRef = useRef(null);
  const getAgentDataButton = document.querySelector('button[id*=\'get-agent-data-button\']');

  useEffect(() => {
    const shouldFocus = lastFocusedElementId === questionId;
    Array.from(document.querySelectorAll('div[id*=\'text-wrapper\'] > div')).forEach((element) => {
      if(element)
        element?.setAttribute('data-clarity-mask', 'True');
    })
    if (hasChildQuestions && childQuestionsArray?.length>0) {
      if (fakeInputRef && fakeInputRef.current && shouldFocus) {
        if (typeof fakeInputRef.current.focus === 'function') {
          fakeInputRef.current.focus();
        }
      }
    } else if (inputRef && inputRef.current && (shouldFocus || lastFocusedElementId==='btn-continue')) {
      if (typeof inputRef.current.focus === 'function') {
        if(lastFocusedElementId==='btn-continue'){
          if(questionId === currentSectionAnswerList[0]?.question_id && currentSectionAnswerList[0]?.answer === ''){
            inputRef.current.focus();
          }
        } else{
          inputRef.current.focus();
        }
      }
    }
  }, []);

  const [errorObj, setErrorObj] = useState({});
  const [inputValue, setInputValue] = useState('')

  const tooltipProps = getTooltipProps(
    tooltipTitle,
    tooltipBody,
    tooltipHTML || tooltip,
  );

  const { min_length, max_length, required, metric, min_value: minValue, max_value: maxValue, auto_format: autoFormat = {} } = validations;
  const minLength = { ...min_length, value: parseInt(min_length?.value, 10), is_exact_message: min_length?.error_message }
  const maxLength = { ...max_length, value: parseInt(max_length?.value, 10), is_exact_message: max_length?.error_message }
  let minValueCustomValidation = { ...minValue, value: parseInt(minValue?.value, 10), is_exact_message: minValue?.error_message }
  let maxValueCustomValidation = { ...maxValue, value: parseInt(maxValue?.value, 10), is_exact_message: maxValue?.error_message }

  if(Object.keys(autoFormat).length !== 0 && autoFormat?.decimalAllowed){
    minValueCustomValidation = {
      ...minValueCustomValidation,
      value: minValue?.value,
    }
    maxValueCustomValidation = {
      ...maxValueCustomValidation,
      value: maxValue?.value,
    }
  }

  const relativeQId = validations?.relative?.questionId

  const isSelectiveFieldValidate = get(validations, 'is_selective_field_validate', false);
  const forceTriggerValidation = get(validations, 'force_trigger_validation', false);

  const validators = getValidators(question, currentSectionAnswerList);
  const getMinMaxValues = () => {
    if (minValue && maxValue) {
      return { min: minValue.value, max: maxValue.value };
    }
    return null;
  };


  const size = get(question, 'properties.size.value', '');
  const inline = get(question, 'properties.inline.value', false);
  const marginSpacing = get(question, 'properties.margin.margin_spacing', false);
  let inlineWithLabel;
  if(inline)
    inlineWithLabel = get(question, 'properties.inline.inline_with_label', false);


  // CSS Variables
  const cssVariables = getCSSVariables(theme);
  const _font = at(cssVariables, ['pageOverride.font', 'global.font']);

  // const getThemeConfiguration = () => {
  //   const labelConfigs = get(labelProps, 'themeConfiguration', {});
  //   const font = _font?.[0]?.['input-labelText'] || _font?.[1]?.['input-labelText'];
  //   return {
  //     ...labelConfigs,
  //     ...themeConfig,
  //     font,
  //   };
  // };
  const sliderTextFont =
    _font?.[0]?.['input-slider-text'] || _font?.[1]?.['input-slider-text'];
  const descriptionFont =
    _font?.[0]?.['input-slider-description'] ||
    _font?.[1]?.['input-slider-description'];
  let themeC = get(labelProps, 'themeConfiguration', {});
  const inputFont = _font?.[0]?.['input-label'] || _font?.[1]?.['input-label'];
  const placeholderFont =
    _font?.[0]?.['input-placeholder'] || _font?.[1]?.['input-placeholder'];
  const actualThreshold = labelProps?.actualThreshold;

  let rowNum = 5;
  if(questionType === 'text_area'){
    if(properties?.border)
      rowNum = Math.ceil((response?.length / 120) * 2)

    themeC = {
      ...themeC,
      border: '1px solid #ccc',
      errorBorderWidth: '1',
      floatingLabel: false,
      ...(properties?.border && { border: properties?.border }),
      ...(properties?.cursor && { disabledPointer: properties?.cursor }),
    }
    delete themeC.borderBottom;
  }

  themeC = {
    ...themeC,
    ...(properties?.border && { borderBottom: properties?.border }),
    ...(properties?.cursor && { disabledPointer: properties?.cursor }),
  }

  let relativeQIdAnswer = '';
  if(currentSectionAnswerList.length > 1){
    relativeQIdAnswer = currentSectionAnswerList.filter(ans => ans?.question_id === relativeQId)[0]?.answer
  }

  const inputWrapperProps = {
    questionId,
    metric,
    questionText,
    actualThreshold,
    reflexiveIndex: reflexiveIndex >= 1 && reflexiveIndex - 1,
    listParentId,
    inlineWithLabel,
    errorObj,
    relativeQId,
    inputValue,
    relativeQIdAnswer,
    label,
    questionType,
    tooltipBody,
    marginSpacing,
    border: properties?.border,
  }

  const isForceTriggerValidation =
  (isEditable && response && !hasChildQuestions && !!qStatusMsg && !getAgentDataButton) ||
  (qStatusMsg !== '' && forceTriggerValidation) ||
  (!!question?.dependant_question_id)

  const validateOnBlur = questionType === 'text_area' && isMrasQ && qStatus === 'invalid'

    const validateInput = (value,questionIdToBeValidated = questionId, inputErValidation, isMrasQ) => {
     return validateQuestionInput(value,questionIdToBeValidated,currentSectionQuestionsList,validators, inputErValidation, isMrasQ);
    };

    useEffect(() => {
      if (question.response) {
        const value = question.response;
        if(value === response && hasChildQuestions) return;
        const hasError=!(validateInput(value));   // bh-307 to make api call only if all the questions in that section are valid
        handleChange(
          value,
          true,
          mask,
          {
            error: hasError,
          },
          hasError,
          true,
          currentSectionQuestionsList,
          currentSectionAnswerList,
          rootListIndex,
          rootListId,
          undefined,
          isSelectiveFieldValidate,
        );
      }
    }, [validators.matchQuestionAnswer])

    const handleBlur = (e) => {
      const { value, dataset } = e.target;
      let hasError = dataset.error === 'true';

      if(response && (isSelectiveFieldValidate || questionId.match(/nipr/i)))
        if(response !== value && listState?.subListAction?.match(/sub_list_submit/i)) handleListAction({
          ...listState,
          subListAction: '',
        })

      if(isMrasQ)
       hasError=!(validateInput(value, undefined, hasError, isMrasQ));   // bh-307 to make api call only if all the questions in that section are valid
      handleChange(
        value,
        true,
        mask,
        {
          error: hasError,
        },
        hasError,
        true,
        currentSectionQuestionsList,
        currentSectionAnswerList,
        rootListIndex,
        rootListId,
        relativeQId,
        isSelectiveFieldValidate,
      );
      updateTimeStamp(questionId, getTimeStampData(timeStampRef, transactionLog, meta));
    };

    const handleOnFocus = () => {
      timeStampRef.current.timeStart = getUTCTimestamp();
    }

const _handleChangeDebounce = debounce(handleChange, 100);

const onChangeHandler = (
  value,
  hasEdit,
  hasMask,
  hasError,
  preventReflexiveCall = true,
  ignoreChildQuestionsOn,
  shouldDebounce = true,
  rootListIndex,
  rootListId,
) => {
  setErrorObj(hasError);
  setInputValue(value)
  const args = [
    value,
    hasEdit,
    hasMask,
    hasError,
    preventReflexiveCall,
    ignoreChildQuestionsOn,
    undefined,
    undefined,
    rootListIndex,
    rootListId,
    relativeQId,
  ]
  if (shouldDebounce && !isControlledInput) {
    _handleChangeDebounce(...args)
  } else {
    handleChange(...args)
  }
}

  let isAsterisk;
  const isAsteriskConfig = get(
    theme,
    'config.theme.components.inputAsteriskRequired',
  );
  if(isAsteriskConfig && question.validations.required && question.validations.required.value === true){
    isAsterisk = true;
  }

  if(Array.isArray(response)){
    let formattedTxtResponse = response.map(eachR => {
      return {
        ...eachR,
        toString(){return this.label}
      }
    });
    formattedTxtResponse = formattedTxtResponse.join('<br>');
    return(
      <LabelTextAryResp>
        <TextElement
          key={ `${questionId}-qText` }
          id={ `${questionId}-${questionType}-${displayType}-questionText` }
          styleType="label"
          questionObj={ question }
          text={ questionText }
          sectionIndex={ sectionIndex }
          errorMessage = { (question?.validations?.error_message_percentage) || null }
        />
        <TextElement
          key={ questionId }
          id={ `${questionId}-${questionType}-${displayType}-label` }
          styleType={
            question?.presentationType || displayType
          }
          questionObj={ question }
          text={ formattedTxtResponse }
          sectionIndex={ sectionIndex }
          errorMessage = { (question?.validations?.error_message_percentage) || null }
        />
      </LabelTextAryResp>
    )
  }
  if(isHidden) return null;
  if (
    questionType === 'text' ||
    questionType === 'password' ||
    questionType === 'text_area'
  ) {
    return (
      <InputWrapper name="input-wrapper" id={ `text-wrapper-${questionId}` } className='text-input-wrapper' ref={ timeStampRef } { ...inputWrapperProps }>
        {
          label?.label_text && <LabelText>{label?.label_text}</LabelText>
        }
        <TextInput
          inputRef={ inputRef }
          labelText={ `${questionText} ${optionalText}` }
          id={ questionId }
          type={ questionType }
          onChange={ (value, err) => {
            onChangeHandler(value, true, mask, err, true, undefined, undefined, rootListIndex, rootListId);
          } }
          onBlur = { handleBlur }
          onFocus={ handleOnFocus }
          color={ colorScheme }
          value={ response }
          displayType={ displayType }
          isControlledInput={ isControlledInput }
          { ...labelProps }
          width={ (screenViewport === 'mobile'|| (screenViewport === 'tablet' && pageId==='quote')) ? 'M' : getWidth('input', pageId, pageOverrides, size) }
          { ...validators }
          { ...tooltipProps }
          errorMessageColor={ errorMessageColor }
          themeConfiguration={ { ...themeC,font:inputFont,placeholderFont } }
          labelStyles={ { style: { fontWeight: 400 } } }
          isAsterisk={ isAsterisk }
          disabled={ isDisabled || isLoading || !isEditable }
          minLength= { minLength }
          maxLength= { maxLength }
          minValue = { minValueCustomValidation }
          maxValue = { maxValueCustomValidation }
          required = { required }
          forceTriggerValidation={ isForceTriggerValidation }
          isSyncStateUpdate = { !!question?.dependant_question_id }
          { ...(questionType === 'text_area' && { floatingLabel: false }) }
          { ...(questionType !== 'text_area' && { hidePlaceholder: true }) }
          { ...(questionType === 'text_area' && { rows: rowNum }) }
          { ...(questionType === 'text_area' && { validateOnBlur }) }
        />
        {action && (
          <ActionButtons
            label={ action.label }
            actionProps={ action }
            sectionIndex={ sectionIndex }
            pageId={ pageId }
            value= { inputRef.current?.value }
            { ...buttonLabelProps }
          />
        )}
        {hasChildQuestions && childQuestionsArray?.length>0 && <FakeInput ref={ fakeInputRef } />}
      </InputWrapper>
    );
  }

  if (displayType === 'slider') {
    return (
      <>
        {questionText && (
          <QuestionText id={ `slider-label-${questionId}` } color={ questionTextColor } font={ sliderTextFont }>
            <span
              dangerouslySetInnerHTML={ {
                __html: validateInnerHtml(`${questionText} ${optionalText}`),
              } }
            />
          </QuestionText>
        )}
        {questionDescription && (
          <QuestionDescription
            id={ `slider-desc-${questionId}` }
            color={ questionDescriptionColor }
            font={ descriptionFont }
          >
            <span
              dangerouslySetInnerHTML={ {
                __html: validateInnerHtml(questionDescription),
              } }
            />
          </QuestionDescription>
        )}
        <Wrapper id={ `slider-field-${questionId}` } name='range-slider-wrapper' { ...labelProps.rangeSlider }>
          <RangeSlider
            onChange={ (v) => {
              onChangeHandler(v, true, mask, {}, false, true, undefined, rootListIndex, rootListId);
            } }
            defaultSelected={ parseInt(response, 10) }
            prefix="$"
            showOnlyStepMultiples={ labelProps.rangeSlider ? labelProps.rangeSlider.showOnlyStepMultiples : false }
            { ...labelProps.rangeSlider }
            { ...validators }
            customLabelStyles={ sliderLabelStyles }
            hasCurrencyAbbrivation
            showValueToLeft
            haveCenterValueItem
          />
        </Wrapper>
        {hasChildQuestions && <FakeInput ref={ fakeInputRef } />}
      </>
    );
  }
  return (
    <NumberInputWrapper ref={ timeStampRef } questionText= { questionText } actualThreshold={ actualThreshold } marginSpacing={ marginSpacing }>
      <NumberInput
        inputRef={ inputRef }
        labelText={ `${questionText} ${optionalText}` }
        id={ questionId }
        onChange={ (value, err) => {
          onChangeHandler(value, true, mask, err, true, undefined, undefined, rootListIndex, rootListId);
        } }
        onBlur = { handleBlur }
        onFocus={ handleOnFocus }
        value={ response }
        color={ colorScheme }
        { ...labelProps }
        width={ getWidth('input', pageId, pageOverrides, size) }
        { ...validators }
        { ...tooltipProps }
        errorMessageColor={ errorMessageColor }
        { ...getMinMaxValues() }
        // themeConfiguration={ getThemeConfiguration() }
        themeConfiguration={ { ...themeC,font:inputFont,placeholderFont } }
        disabled={ isDisabled || isLoading || !isEditable }
        forceTriggerValidation={ isForceTriggerValidation }
        isAsterisk={ isAsterisk }
      />
      {hasChildQuestions && childQuestionsArray?.length>0 && <FakeInput ref={ fakeInputRef } />}
    </NumberInputWrapper>
  );
};

Input.defaultProps = {
  sectionIndex: -1,
};
Input.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  question: PropTypes.object.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  sectionIndex: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

const _handleChange = (
  props,
  response,
  hasEdit,
  hasMask,
  hasError,
  preventReflexiveCall,
  ignoreChildQuestionsOn,
  currentSectionQuestionsList,
  currentSectionAnswerList,
  dispatch,
  rootListIndex,
  rootListId,
  relativeQId,
  isSelectiveFieldValidate,
) => {
  const questionId = get(props, 'question.question_id', '');
  const transactionLog = get(props, 'question.transaction_log', []);
  const auditRequired = get(props, 'question.audit_required', false);
  const question = get(props, 'question', {});
  let forceUpdateQuestionsArray = false;
  let forceUpdateQuestionData = {};

  if (question.units_root_question_id) {
    const originalUnitsQues =
      currentSectionQuestionsList?.filter(
        (q) => q.question_id === question.units_root_question_id,
      ) || [];

    if (originalUnitsQues?.length) {
      const validators = getValidators(originalUnitsQues[0], currentSectionAnswerList);
      const initialUnits = originalUnitsQues[0]?.unit_conversion_data || {};
      const units = question.question_id?.includes('Feet')
        ? {
            ...initialUnits,
            feet: response,
          }
        : {
            ...initialUnits,
            inches: response,
          };
      originalUnitsQues[0].unit_conversion_data = units;
      if (units.feet && units.inches) {
        const totalInches = +units.feet * 12 + Number(units.inches);
        if ( !originalUnitsQues[0]?.response ||
          (originalUnitsQues[0]?.response &&
          originalUnitsQues[0].response !== totalInches)
        ) {
          originalUnitsQues[0].response = totalInches;
          const {
            question_id: labelQuestionId,
            response: labelResponse,
          } = originalUnitsQues[0];
          forceUpdateQuestionsArray = true;
          const isError = !validateQuestionInput(labelResponse,labelQuestionId,currentSectionQuestionsList,validators);
          forceUpdateQuestionData = {
            labelQuestionId,
            labelResponse,
            isError,
          };
          originalUnitsQues[0].validations.error_message_percentage = isError? ' - Max height is 8ft' : '';
          // hasError.error = !validateQuestionInput(labelResponse,labelQuestionId,currentSectionQuestionsList,validators)
        }
      }
    }
  }

  if (question.dependant_question_id && currentSectionQuestionsList) {
    const dependantQuestion = currentSectionQuestionsList?.filter(
      (q) => q.question_id === question.dependant_question_id,
    );
    // to avoid repeated response updates on multiple renders
    const isRepeatedTrigger = () => {
      return dependantQuestion[0]?.responseList && dependantQuestion[0]?.responseList[question.question_id] === response;

    }
    if(isRepeatedTrigger(question,dependantQuestion)) return;
    if(dependantQuestion?.length){
    dependantQuestion[0].responseList = {
      ...dependantQuestion[0]?.responseList,
      [questionId]: response,
    };
    const validationQuestionIds = get(dependantQuestion[0],'validations.custom_validations[0].question_ids',[]);

    // to populate back responseList with all questions responses after an api call(with 100%).This handles editing value again after 100% fulfilment

    if(dependantQuestion[0].response==='100' && dependantQuestion[0].responseList?.length!==validationQuestionIds.length){
    validationQuestionIds.forEach(q => {
      dependantQuestion[0].responseList[q]= currentSectionQuestionsList?.filter((listq) => listq.question_id === q)[0]?.response;
    });
  }

  // setting response after responseList Mapping - to track previous 100% individual answers before updating with latest edit.

    dependantQuestion[0].response =`${  validationQuestionIds?.reduce(
      (acc, q_id) => {
        if(dependantQuestion[0].responseList[q_id])
        acc+= +dependantQuestion[0].responseList[q_id];
        return acc;
      },
      0,
    )}`
    }
    const customValidations = get(dependantQuestion[0],'validations.custom_validations[0]','');
    const isValidSum = (+(dependantQuestion[0].response) === customValidations?.equalTo);

    // decide whether to trigger api call and error message handling
    if((Object.keys(dependantQuestion[0].responseList)?.length === customValidations.question_ids?.length) || isValidSum ){
    const { response:labelResponse,question_id:labelQuestionId }=dependantQuestion[0];
    if (
      customValidations?.error_message &&
      +(labelResponse) !== customValidations?.equalTo
    ) {
      dependantQuestion[0].validations.error_message_percentage = get(
        customValidations,
        'error_message',
        '',
      );
    } else {
      dependantQuestion[0].validations.error_message_percentage = '';
    }
    forceUpdateQuestionsArray = true;
    forceUpdateQuestionData = {
      labelQuestionId,
      labelResponse,
      isError: !!(hasError.error || (dependantQuestion[0].validations.error_message_percentage)),
    }
  }
  }
  if(forceUpdateQuestionsArray){
    dispatch(
      updateAnswersArray({
        questionId:forceUpdateQuestionData.labelQuestionId,
        response:forceUpdateQuestionData.labelResponse,
        hasEdit,
        hasMask,
        sectionIndex: props.sectionIndex,
        preventReflexiveCall,
        isError: forceUpdateQuestionData.isError,
        ignoreChildQuestionsOn,
        propQuestion: props.question,
        listParentId: props.listParentId,
        listIndex: props.listIndex,
        auditRequired,
        rootListIndex,
        rootListId,
        relativeQId,
        transactionLog,
      }),
    );
  }

  dispatch(
    updateAnswersArray({
      questionId,
      response,
      hasEdit,
      hasMask,
      sectionIndex: props.sectionIndex,
      preventReflexiveCall,
      isError: hasError.error,
      ignoreChildQuestionsOn,
      propQuestion: props.question,
      listParentId: props.listParentId,
      listIndex: props.listIndex,
      auditRequired,
      rootListIndex,
      rootListId,
      relativeQId,
      isSelectiveFieldValidate,
      transactionLog,
    }),
  );
};

const mapStateToProps = (state, props) => {
  const { questions, meta } = state;
  const { activeSubsectionId, sections, lastFocusedElementId, isLoading } = questions;
  const { screenViewport } = meta;
  let currentSectionAnswerList = [];
  let currentSectionQuestionsList = [];
  if (sections) {
    let currentSection = [];
    const { sectionIndex } = props;
    if (activeSubsectionId && sectionIndex!==undefined) {
      currentSection = sections[activeSubsectionId][sectionIndex];
    }
      currentSectionAnswerList = currentSection?.answersArray || [];
      currentSectionQuestionsList = currentSection?.questionList || [];
  }
  return {
    currentSectionAnswerList,
    screenViewport,
    lastFocusedElementId,
    currentSectionQuestionsList,
    isLoading,
    meta: state.meta,
		isListMode: state?.questions?.listMode,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleChange: (
      response,
      hasEdit,
      hasMask,
      hasError,
      preventReflexiveCall,
      ignoreChildQuestionsOn,
      currentSectionQuestionsList=undefined,
      currentSectionAnswerList = undefined,
      rootListIndex = null,
      rootListId = '',
      relativeQId,
      isSelectiveFieldValidate,
    ) => {
      _handleChange(
        props,
        response,
        hasEdit,
        hasMask,
        hasError,
        preventReflexiveCall,
        ignoreChildQuestionsOn,
        currentSectionQuestionsList,
        currentSectionAnswerList,
        dispatch,
        rootListIndex,
        rootListId,
        relativeQId,
        isSelectiveFieldValidate,
      );
    },
    updateTimeStamp: (
      questionId,
      timeStampData,
      isPrefilled,
    ) => {
        dispatch(updateQuestionAnswerTimeStamp(questionId, timeStampData, props.sectionIndex, isPrefilled))
    },
    updateListMode: (mode) => {
			dispatch({
			  type: SET_LIST_MODE,
			  index: mode,
			})
		},
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Input));