import React, { useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import at  from 'lodash/at';
import { isArray } from 'lodash';
import ActionButtons from '../../QuestionsLib/ActionButtons';
import {
	getEnableAdd,
	getContinueState,
} from '../../../redux/selector/validations';
import { closeAddListForm, deleteBeneficiary } from '../../../redux/actions/listQuestion';
import { getCSSVariables } from '../../../cruxComponents/utils';
import { SET_LIST_INDEX, SET_LIST_MODE, SET_REFLEXIVE_LIST_INDEX } from '../../../redux/types';
import { ListContext } from '../../List/ListContext';

const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 20px;
	align-items: center;
	@media only screen and (max-width: 767px) {
		justify-content: center;
		margin-right: 0;
	}
`;

const CloseButton = styled.button`
	background: none;
	display: flex;
	align-items: center;
	border: 0;
	outline: none;
	font-size: 18px;
	padding: 5px 20px;
	margin-right: 0px;
	color: rgb(0, 70, 90);
	z-index: 123123;
	transition: all 0.2s ease-out;
	i { margin-left: 10px; }
	&:focus {
		outline: 2px solid #333; 
		outline-offset: '2px',
		@media (max-width: 767px){
			outline-offset: '1px',
		}
	}
`;

function ListQuestionAction({
	enableContinue,
	sectionIndex,
	closeButton,
	handleClose,
	enableAdd,
	listQIds,
	listId,
	listAddButtonLabel,
	isNestedList,
	listFormQuestions,
	rootListId,
	unsetListEditMode,
	...otherProps
}) {
	const handleClick = (e, listActionId)=>{
		handleClose(sectionIndex, listActionId)
		e.preventDefault();
		e.stopPropagation();
	}

	const { listState, handleListAction } = useContext(ListContext)

	let isEnableListAdd = enableAdd
	const getAgentDataButton = document.querySelector('button[id*=\'get-agent-data-button\']');

	if(getAgentDataButton)
		isEnableListAdd = enableAdd && listState?.subListAction?.match(/sub_list_submit/i)

	const theme = get(otherProps, 'theme.config.theme.global');
	const { listButtonsTheme } = theme;
	const cssVariables = getCSSVariables(otherProps.theme);
	const font = at(cssVariables, ['pageOverride.font', 'global.font']);
	if (listButtonsTheme) {
		listButtonsTheme.closeButtonTheme.themeConfiguration.font = font?.[0]?.['button-list-close'] || font?.[1]?.['button-list-close']
	}
	const getDeleteButton = () => {
		// if(listButtonsTheme && listButtonsTheme.closeButtonTheme){
		//   return (
		// 	<Button
		// 	  color={ colorScheme }
		// 	  label='Delete'
		// 	  handleButtonClick={ handleClick }
		// 	  { ...listButtonsTheme.closeButtonTheme }
		// 	/>
		//   )
		// }
		  const listActionId = 'list-cancel-btn';
		  return (
			<CloseButton
				onClick={ (e) => {
					handleListAction('');
					handleClick(e, listActionId)
				} }
				name="close-button"
			>
				<span>Cancel</span>
				<i className="fa fa-times" /> 
			</CloseButton>
		  )   
	  }


	// if (closeButton) {
	// 	return	getDeleteButton()
	// }
	return (
		<ActionWrapper name="action-wrapper">		
		{closeButton? getDeleteButton(): 
			<ActionButtons
				sectionIndex={ sectionIndex }
				isList
				isListAddButton
				enableContinue={ enableContinue }
				enableAdd={ isEnableListAdd }
				questionIds={ listQIds }
				isNestedList = { isNestedList }
				listId={ listId }
				rootListId = { rootListId }
				listAddButtonLabel={ listAddButtonLabel }
				unsetListEditMode = { unsetListEditMode }
			/>
		}
		</ActionWrapper>
	);
}

const mapStateToProps = (state, props) => {
	const id = get(props, 'match.params.id', '');
	const { requiredListQIds, listError } = props
	const { enableContinue } = getContinueState(
		state,
		props.sectionIndex,
		id,
	);
	return {
		enableContinue,
		enableAdd: !listError && getEnableAdd(state, props.sectionIndex, id, requiredListQIds),
	};
};

const mapDispatchToProps = (dispatch, props) => {
	const { listFormQuestions = [], closeButton, listId } = props;
	const reflexiveQExist = closeButton && listFormQuestions.some(q => q.child_questions && isArray(q.child_questions_on) && q.child_questions_on.length > 0)
	return {
		handleClose: (sectionIndex, listActionId = undefined) => {
			if (reflexiveQExist) {
				dispatch(deleteBeneficiary(undefined, sectionIndex, listId, listActionId))
			} else {
				dispatch(closeAddListForm(sectionIndex, listId));
			}
		},
		unsetListEditMode: () => {
			dispatch({
			  type: SET_LIST_MODE,
			  index: false,
			})
			dispatch({
			  type: SET_LIST_INDEX,
			  index: -1,
			})
			dispatch({
			  type: SET_REFLEXIVE_LIST_INDEX,
			  index: -1,
			  id: '',
			})
		},
	};
};

export default withTheme(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ListQuestionAction),
));
