/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import at from 'lodash/at';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import { validateInnerHtml } from '../../utils';
import { FooterNote,FooterWrapper } from './styles';
import { getCSSVariables } from '../../cruxComponents/utils';

function FooterNotes(props) {
  const { footerNotes, layout,theme } = props;

  // CSS Variables
  const cssVariables = getCSSVariables(theme);
  const width = at(cssVariables, ['pageOverride.width', 'global.width']);
  const maxWidth = at(cssVariables, ['pageOverride.maxWidth', 'global.maxWidth']);

  const renderFooterNotes = () => {
    return footerNotes.map((footerNote) => (
      <FooterNote>
        <p
          dangerouslySetInnerHTML={ { __html: validateInnerHtml(footerNote) } }
        />
      </FooterNote>
    ));
  };
  return (
    footerNotes.length > 0 && (
      <FooterWrapper layout={ layout } width={ width } maxWidth={ maxWidth }>{renderFooterNotes()}</FooterWrapper>
    )
  );
}

const mapStateToProps = (state) => {
  const footerNotes = get(state, 'questions.footerNotes', []);
  return {
    footerNotes,
  };
};
export default withTheme(connect(mapStateToProps)(FooterNotes));
