/* eslint-disable no-unused-vars */
/* eslint consistent-return: off */
import React, { useEffect } from 'react';
import at from 'lodash/at';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { getCSSVariables } from '../../../cruxComponents/utils';

const Level1Wrapper = styled.div`
  background: rgba(226, 226, 226, 1);
  margin: 0 -100px;
  margin-bottom: 30px;

  ${({ margin, padding })=>
  `
    margin:${margin?.[0]?.['reflexiveCard-level1Wrapper'] || margin?.[1]?.['reflexiveCard-level1Wrapper']};
    padding:${padding?.[0]?.['reflexiveCard-level1Wrapper'] || padding?.[1]?.['reflexiveCard-level1Wrapper']};
  `}

  ${({ listQ, listEditQId }) => ((!listEditQId && (listQ && listQ.child_questions_completed_flag)) || (listQ && listEditQId !== listQ?.question_id)) && 'position: relative;'}
`;

const ReflexiveQuestion = styled.div`
  background: rgba(255, 255, 255, 1);
  ${({ listQ, listEditQId }) => ((!listEditQId && (listQ && listQ.child_questions_completed_flag)) || (listQ && listEditQId !== listQ?.question_id)) && 'margin-bottom: 50px;'}
  border-radius: ${({ themeConfiguration }) =>
    themeConfiguration ?.borderRadius ? themeConfiguration ?.borderRadius : '8px'};
  ${({ view, activeSubsectionId }) =>
    view === 'compact'
      ? `
    @media (min-width: 1280px) {
      padding: 15px 220px;
    }`
      : `
    @media (min-width: 1280px) {
      padding: 15px ${activeSubsectionId === 'bh_existinginsurancebreadcrumbagent' ? '90px' : '120px'};
    }`};
  @media (max-width: 1280px) {
    padding: 15px ${({ activeSubsectionId }) => activeSubsectionId === 'bh_existinginsurancebreadcrumbagent' ? '90px' : '120px'};
  }
  @media (max-width: 1024px) {
    padding: 15px 80px;
  }
  @media (max-width: 768px) {
    padding: 30px;
  }
  @media (max-width: 420px) {
    padding: 10px;
  }
`;

const Level2Wrapper = styled.div`
  // border-top: 1px solid #999999;
  padding-left: 20px;
  padding-top: 12px;
  // margin-left: -80px;
  // padding-left: 80px;
  padding-left: ${({ questionTextLength, activeSubsectionId }) => (questionTextLength > 45 || activeSubsectionId?.match(/primaryphysician/i)) ? '0px' : '80px'};

  @media only screen and (max-width: 1025px) {
    margin: 0 -15px;
    padding: 0 15px;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 -10px;
    padding-left: 10px;
  }
`;

const CloseAction = styled.div`
  @media only screen and (min-width: 768px) {
    position: absolute;
    right: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ReflexiveWrapper = styled.div`
  ${({ displayType, isRequired }) => (displayType==='label' && isRequired) &&
    ` > div > div > div {
        color: rgb(201, 68, 54);
        margin-top: -20px;
    }`
  }
`;

/* The below function supports multiple entries { i.e. can be object/array } in the questions response. */
// function recursiveQuestionArrayElements(questionArray, index, arrayLength){
//   if(index === arrayLength){
//     return 0;
//   }
//   if(!isArray(questionArray[index])){
//     checkElementContentThreshold(questionArray[index])
//   }else {
//     recursiveQuestionArrayElements(questionArray[index], 0, questionArray[index].length)
//   }
//   recursiveQuestionArrayElements(questionArray, index+1, arrayLength)
// }

/* The following function supports only for two entries in the questions response. */
// function recursiveQuestionArrayElements(questionArray, index, arrayLength){
//   console.log('questionArray', questionArray);
//   if(questionArray.length > 1){
//     questionArray.forEach(element => {
//       if(!isArray(element)){
//         checkElementContentThreshold(element)
//       }
//     });
//     recursiveQuestionArrayElements(questionArray[1])
//   }else if(questionArray[0]) {
//      checkElementContentThreshold(questionArray[0])
//     }
// }

function ReflexiveCard({ children, reflexLevel, view, hideWrapper, theme, parentQuestionId, questionArray, activeSubsectionId, listEditQId }) {
  const {
    pageId,
    config: {
      theme: {
        components: {
          card: { themeConfiguration },
        },
      },
    },
  } = theme;

  // CSS Variables
  const cssVariables = getCSSVariables(theme);
  const margin = at(cssVariables, ['pageOverride.margin', 'global.margin']);
  const padding = at(cssVariables, ['pageOverride.padding', 'global.padding']);
  const reflexiveIndex = get(questionArray[0], 'reflexive_index', '');
  const displayType = get(questionArray[0], 'display_type', '');
  const isRequired = get(questionArray[0], 'validations.required.value', false);
  const listQ = questionArray.filter(question => question.display_type === 'list')[0]
  const dataListIdAttribute = listQ && { 'data-list-id': listQ?.question_id }
  let questionText = '';

  const _margin = margin?.[0]?.['reflexiveCard-level1Wrapper'] || margin?.[1]?.['reflexiveCard-level1Wrapper'];
  if(margin?.[0]?.['reflexiveCard-level1Wrapper']){
    const shortHandMargin = ['0px', '0px'];
    if(_margin) shortHandMargin.push(_margin.split(' ')[2])
    margin[0]['reflexiveCard-level1Wrapper'] = shortHandMargin.join(' ');
  }
  if(margin?.[1]?.['reflexiveCard-level1Wrapper']){
    const shortHandMargin = ['0px', '0px'];
    if(_margin) shortHandMargin.push(_margin.split(' ')[2])
    margin[1]['reflexiveCard-level1Wrapper'] = shortHandMargin.join(' ');
  }

  let listWrapperElement; let listWrapperPEl;
  const handleResize = () => {
    listWrapperElement = document.getElementById(`reflexive-card-container-${parentQuestionId}-${reflexLevel}`);
    listWrapperPEl = listWrapperElement?.parentElement
    if(listWrapperPEl && reflexLevel <= 1){
      const paddingL = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-left');
      const paddingR = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-right');
      listWrapperElement.style.marginLeft = `-${paddingL}`;
      listWrapperElement.style.marginRight = `-${paddingR}`;
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize, false);
  }, [listWrapperPEl?.clientWidth])

  if (isArray(children) && !children.filter(a => a).length) {
    return null;
  }

  if(reflexiveIndex === 2)
    questionText = get(questionArray[0], 'question_text', '');
  if (hideWrapper) {
    return <ReflexiveWrapper displayType={ displayType } isRequired={ isRequired }>{children}</ReflexiveWrapper>;
  }
  if (reflexLevel === 1) {
    if (hideWrapper) return <>{ children }</>
    return (
      <Level1Wrapper id={ `reflexive-card-container-${parentQuestionId}-${reflexLevel}` } name={ `reflexive-card-wrapper-${reflexLevel}` } padding={ padding } margin = { margin } listQ = { listQ } listEditQId = { listEditQId }>
        <ReflexiveQuestion view={ view } themeConfiguration={ themeConfiguration } activeSubsectionId = { activeSubsectionId } listQ = { listQ } listEditQId = { listEditQId } { ...dataListIdAttribute }>
          {children}
        </ReflexiveQuestion>
      </Level1Wrapper>
    );
  }
  if (reflexLevel === 2) {
    return(
      <Level2Wrapper id={ `reflexive-card-container-${parentQuestionId}-${reflexLevel}` } data-name={ `reflex-wrapper-${reflexLevel}` } activeSubsectionId = { activeSubsectionId } questionTextLength = { questionText.length } >
        {children}
      </Level2Wrapper>
    );
  }
  return children;
}

export default withTheme(ReflexiveCard);
