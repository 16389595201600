import React, { useState, useContext, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { Button, FocusTrap } from 'crux';
import get from 'lodash/get';
import at from 'lodash/at';
// eslint-disable-next-line import/no-cycle
import FieldSet from '../FieldSet';
import Accordion from '../Accordion';
import { getListTitle } from '../../../redux/selector/listQuestion';
import { getCSSVariables } from '../../../cruxComponents/utils';
import { ChevronIcon } from '../Accordion/ChevronIcon';
import { SET_LIST_MODE } from '../../../redux/types';
import { ListContext } from '../../List/ListContext';

const Body = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: ${( { reflexLevel, isReview } ) =>
    (reflexLevel > 0 && !isReview) ? '0px' : '55px 40px'};
  border-radius: ${({ themeConfiguration }) => themeConfiguration ?.borderRadius ? themeConfiguration ?.borderRadius : '8px'};
  margin-bottom: 30px;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 30px 25px;
  }
  // clearfix for floating children
  // overflow: auto;
`;

const Header = styled.div`
  position: relative;
  @media only screen and (min-width: 768px){
    padding-top: 5px;
  }
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-left: -10px;
  }
`;

const Text = styled.span`
  text-align: left;
  font: 500 14px Roboto-Bold;
  display: inline-block;
  margin-top: 6px;
`;

const ButtonContainer = styled.span`
  float: right;
  > button:first-child{
    padding-left: 5px;
  }
  > button:last-child{
    margin-right: 25px;
    margin-left: 0;
    padding-left: 5px;
    padding-right: 10px;
  }
  // & >button {
  //   font: 500 14px Roboto-Bold;
  // }
  ${(listButtonsTheme) =>
    listButtonsTheme &&
    `
    display:flex;
  `}
  @media only screen and (max-width: 767px) {
    // margin-top: 10px;
    margin-left: -10px;
  }
`;

const Line = styled.hr`
  border-width: 2px;
  margin: 20px 0;
`;

const ButtonLink = styled.button`
  border: 0;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  &:focus {
    outline: 3px solid black;
  }
`;

const EditLink = styled(ButtonLink)`
  color: ${({ disabled }) => (disabled ? '#767676' : '#0B465A')};
  @media only screen and (max-width: 767px) {
    padding-left: 0px;
  }
`;

const DeleteLink = styled(ButtonLink)`
  color: rgba(255, 0, 0, 1);
`;

const DeleteCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Message = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgb(92, 92, 92);
`;

const ViewMode = styled.div`
  display: ${(props) => (props.isEditMode ? 'none' : 'inherit')};
`;

const EditMode = styled.div`
  display: ${(props) => (props.isEditMode ? 'inherit' : 'none')};
`;

const Chevron = styled.span`
  position: absolute;
  right: 0%;
  top: 20%;
  ${({ expanded }) => expanded ? '> svg {transform: rotate(90deg)}' : '> svg {transform: rotate(0deg)}'};
`
const ConfirmButtons = styled.div``;

function ListItemCard({
  listItem,
  sectionIndex,
  onEditClick,
  onDeleteClick,
  setEditIndex,
  len,
  index,
  title,
  changeMode,
  isEditMode,
  reflexLevel,
  updateEditIndex,
  isReview,
  listParentId,
  enableUpdate,
  handleCancel,
  setReflexiveListIndex,
  currentSection,
  isNestedList,
  listMode,
  listIndex,
  listDisplay,
  rootListId,
  rootListIndex,
  rListDisplay,
  updateListMode,
  updateNestedListMode,
  editIndex,
  activeSubsectionId,
  screenViewport,
  isEditable,
  listId,
  listParentQId,
  curListParentQs,
  listProperties,
  childQsCompletedFlag,
  ...otherProps
}) {

  const { listState, handleListAction } = useContext(ListContext)
  const theme = get(otherProps, 'theme.config.theme.global');
  const pageOverrides = get(otherProps, 'theme.config.theme.pageOverrides');
  const pageId = get(otherProps, 'theme.pageId');

  const { listButtonsTheme, colorScheme } = theme;
  const [showDeleteCover, setDeleteCover] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const card = get(otherProps, 'theme.config.theme.components.card');
  const { themeConfiguration } = card;
  const cssVariables =  getCSSVariables(otherProps.theme);
  const displayEditButton = get(listItem[0], 'is_mras_question', false);
  const displayDeleteButton = get(listItem[0], 'is_delete_btn_hidden', false);
  const { questionList } = currentSection;
  const listQuestion = questionList.filter(question => question.display_type === 'list')[0];
  let listTitle = getListTitle(listItem, isReview) || (listQuestion?.questions?.[index]?.[0]?.response?.label)
  let listTitlePrefix = '';
  const questionTypes = ['text', 'number', 'text_area', 'date', 'singleselection', 'multiselection'];
  const questionTypeRegex = new RegExp(`^(${questionTypes.join('|')})`, 'i');
  const firstAnsweredListQ = listItem.filter(q => questionTypeRegex.test(q.question_type))[0];
  if(isReview && listTitle.match(/undefined/i)){
    listTitle = (listQuestion?.questions?.[index]?.[0]?.response?.label) || (typeof(firstAnsweredListQ.response) === 'string' && firstAnsweredListQ.response || (typeof(firstAnsweredListQ.response) === 'object' && firstAnsweredListQ.response.label))
  }
  if(listProperties){
    const listNumbering = get(listProperties, 'list_numbering', false);
    if(listNumbering?.number_prefix)
      listTitlePrefix = listNumbering.number_prefix
    if(listNumbering?.number_sign && listTitlePrefix)
      listTitlePrefix = `${listTitlePrefix} ${listNumbering.number_sign}`
  }
  const font = at(cssVariables, ['pageOverride.font', 'global.font']);
  if (listButtonsTheme) {
    listButtonsTheme.editButtonTheme.themeConfiguration.font = font?.[0]?.['button-list-edit'] || font?.[1]?.['button-list-edit']
    listButtonsTheme.deleteButtonTheme.themeConfiguration.font = font?.[0]?.['button-list-delete'] || font?.[1]?.['button-list-delete']
  }

  useEffect(() => {
    if(isEditMode){
      handleListAction({
        ...listState,
      })
    }
    return () => {
      const getAgentDataButton = document.querySelector('button[id*=\'get-agent-data-button\']');
      if(!getAgentDataButton && childQsCompletedFlag) handleListAction('')
    };
  }, [])

  const onClickEditButton = () => {
    handleListAction({
      listId,
      listParentQId,
      curListParentQs,
      action: 'edit',
      subListAction: 'sub_list_submit',
    });
    if(!isNestedList){
      /** Setting List Index on edit click */
      // setEditIndex();
      changeMode('listEdit');
      updateListMode(true);
    }
    if(isNestedList){
      changeMode('nestedListEdit');
      updateNestedListMode(true)
    }
    updateEditIndex(index);
    setReflexiveListIndex(index, listId);
  };

  const getEditButton = () => {
    let disableListEdit = false;
    if(listState?.listId) disableListEdit = (listId !== listState?.listId)
    if (listButtonsTheme && listButtonsTheme.editButtonTheme) {
      return (
        <Button
          color={ colorScheme }
          label="Edit"
          handleButtonClick={ onClickEditButton }
          disabled={ disableListEdit }
          { ...listButtonsTheme.editButtonTheme }
        />
      );
    }
    return <EditLink onClick={ onClickEditButton }>Edit</EditLink>;
  };

  const onClickUpdateButton = () => {
    handleListAction('');
    if(!isNestedList){
      updateListMode(false);
      changeMode('');
    }
    if(isNestedList){
      updateNestedListMode(false)
    }
    onEditClick();
    setReflexiveListIndex(-1, '');
  };

  const getUpdateButton = () => {
    if (listButtonsTheme && listButtonsTheme.editButtonTheme) {
      return (
        <Button
          color={ colorScheme }
          label="Update"
          handleButtonClick={ onClickUpdateButton }
          disabled={ !enableUpdate }
          { ...listButtonsTheme.editButtonTheme }
        />
      );
    }
    return (
      <EditLink onClick={ onClickUpdateButton } disabled={ !enableUpdate }>
        Update
      </EditLink>
    );
  };

  const onClickDeleteButton = () => {
    setDeleteCover(true);
  };

  const getDeleteButton = () => {
    if (listButtonsTheme && listButtonsTheme.deleteButtonTheme) {
      return (
        <Button
          // ref={ modalDescRef }
          color={ colorScheme }
          label="Delete"
          handleButtonClick={ onClickDeleteButton }
          { ...listButtonsTheme.deleteButtonTheme }
        />
      );
    }
    return <DeleteLink onClick={ onClickDeleteButton }>Delete</DeleteLink>;
  };

  const onClickCancelButton = () => {
    handleListAction('');
    if(!isNestedList){
      changeMode('');
      updateListMode(false);
    }
    if(isNestedList){
      updateNestedListMode(false)
    }
    updateEditIndex(-1);
    setReflexiveListIndex(-1, '');
    const isCancelUpdate = true;
    handleCancel(isCancelUpdate);
  };

  const getCancelButton = () => {
    if (listButtonsTheme && listButtonsTheme.deleteButtonTheme) {
      return (
        <Button
          color={ colorScheme }
          label="Cancel"
          handleButtonClick={ onClickCancelButton }
          disabled = { otherProps.isCancelBtnDisabled }
          { ...listButtonsTheme.deleteButtonTheme }
        />
      );
    }
    return <DeleteLink onClick={ onClickCancelButton }>Cancel</DeleteLink>;
  };

  const fieldSetProps = {
    isReview,
    listDisplay,
    isNestedList,
    rListDisplay,
    changeMode,
    sectionIndex,
    index,
    rootListIndex,
    rootListId,
    activeSubsectionId,
    screenViewport,
    pageOverrides,
    pageId,
  }

  return (
    <Body
      name="list-item-card"
      reflexLevel={ reflexLevel }
      isReview={ isReview }
      listButtonsTheme={ listButtonsTheme }
      themeConfiguration={ themeConfiguration }
    >
      {showDeleteCover && (
        <FocusTrap modalType="bootstrap">
        <DeleteCover role="dialog" aria-labelledby="list-dialog-title" aria-describedby="list-dialog-desc" name="delete-cover">
          <Message id="list-dialog-title">Are you sure you want to delete this?</Message>
          <ConfirmButtons id="list-dialog-desc" name="confirm-buttons">
            <DeleteLink onClick={ onDeleteClick }>Yes</DeleteLink>
            <EditLink onClick={ () => setDeleteCover(false) }>No</EditLink>
          </ConfirmButtons>
        </DeleteCover>
        </FocusTrap>
      )}
      <ViewMode name="viewmode" isEditMode={ isEditMode }>
        <Header name="header">
          <Text>{`${listTitlePrefix}${index + 1 }. ${listTitle}`}</Text>
          {!isReview && ((isNestedList && !rListDisplay && !listMode) ||
          (isNestedList && listMode && (rootListIndex === listIndex)) ||
          (!isNestedList)) && (
            <ButtonContainer listButtonsTheme={ listButtonsTheme }>
              {!displayEditButton && isEditable && getEditButton()}
              {!displayDeleteButton && isEditable && getDeleteButton()}
            </ButtonContainer>
          )}
          <Chevron tabIndex={ 0 } onKeyPress={ (event) => {  if (event.key === 'Enter') setExpanded(!expanded) } } onClick={ () => { setExpanded(!expanded) } } expanded={ expanded }>
            <ChevronIcon />
          </Chevron>
          <Line />
        </Header>
        <Accordion
          index={ index }
          len={ len }
          // title={ getListTitle(listItem) }
          isReview={ isReview }
          expanded = { expanded }
          setExpanded = { setExpanded }
        >
          <FieldSet fields={ listItem } { ...fieldSetProps }/>
        </Accordion>
      </ViewMode>
      <EditMode name="editmode" isEditMode={ isEditMode }>
        <FieldSet
          fields={ listItem }
          isEditMode={ isEditMode }
          listParentId={ listParentId }
          listIndex= { listIndex }
          { ...fieldSetProps }
        />
        <ButtonContainer listButtonsTheme={ listButtonsTheme }>
          {getUpdateButton()}
          {getCancelButton()}
        </ButtonContainer>
      </EditMode>
    </Body>
  );
}

const mapStateToProps = (state) => {
	const { activeSubsectionId, sectionIndex, listIndex } = state.questions;
	return {
    currentSection: state.questions.sections[activeSubsectionId][sectionIndex],
    listIndex,
    activeSubsectionId: state?.questions?.activeSubsectionId,
    screenViewport: state.meta.screenViewport,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateListMode: (mode) => {
        dispatch({
          type: SET_LIST_MODE,
          index: mode,
        })
    },
    updateNestedListMode: (mode) => {
        dispatch({
          type: 'SET_NESTED_LIST_MODE',
          index: mode,
        })
    },
	};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(ListItemCard));

