/* eslint-disable react/no-danger */
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Card } from 'crux';
import at from 'lodash/at';
import ScrollToTop from '../ScrollToTop';
import { getCSSVariables } from './utils';
import { validateInnerHtml } from '../utils';

const LoaderWrapper = styled.div`
	margin-top: 120px;
	margin-bottom: 40px;
	div {
		min-height: 560px;
	}
	img {
		max-width: 425px;
		margin: auto;
		display: block;
	}
	p {
		margin: 0;
		padding: 50px;
		font-size: 36px;
		font-weight: 500;
		color: rgba(71, 100, 126, 1);
		text-align: center;
	}
	@media only screen and (max-width: 768px) {
		img {
			max-width: 100%;
			height: auto;
		}
	}
`;

const Loader = (props) => {
	const { 
		theme,
		message, 
	} = props;

	const loader = get(theme, 'config.global.loader');
	const themeConfiguration = get(theme, 'config.theme.components.card.themeConfiguration', {});

	// CSS Variables
	const cssVariables = getCSSVariables(theme);
	const padding = at(cssVariables, ['pageOverride.padding.card', 'global.padding.card']);

	return (
		<ScrollToTop>
			<LoaderWrapper>
				<Card padding={ padding[0] || padding[1] } themeConfiguration={ themeConfiguration }>
					<img src={ loader } alt="Page Loading" />
					<p>
						<span dangerouslySetInnerHTML={ {
                			__html: validateInnerHtml(message),
              			} } />
			  		</p>
				</Card>
			</LoaderWrapper>
		</ScrollToTop>
	);
};

const mapStateToProps = (state) => {
	const message = get(state, 'questions.loader.message', 'Just a moment...');

    return {
		message,
    }
};

export default withTheme(connect(mapStateToProps, {})(Loader));
